import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Image, Card, Select } from 'semantic-ui-react';
import { withCookies, Cookies } from 'react-cookie';
import { fetchChallengesList } from './challengesSlice';
import helpers from '../../app/helpers';
import controlledFetch from '../../app/controlledFetch';
import Table from '../../features/table/Table';
import Button from '../../features/button/Button';
import ContextMenu from '../../features/contextMenu/ContextMenu';
import styles from './ChallengesList.module.css';
import AppStyles from './../../App.module.css';
import TableStyles from '../../features/table/Table.module.css';
import DateField from '../../features/dateField/DateField';
import moment from 'moment';
import IconUser from '../../features/iconUser/IconUser';

class ChallengesList extends Component {
  constructor(props) {
    super(props);
    if (props.total < 0) {
      props.fetchChallengesList(props.userRole);
    }
    props.setShowSidebar(true);
    props.setHeaderContent(props.defaultHeader);
    this.state = {
      loading: props.total === -1,
      cookies: props.cookies.getAll(),
      timelog: [],
      countVotes: 0,
      countSolutions: 0,
      countComments: 0,
      countChallenges: 0,
    };
    controlledFetch(
      `${
        process.env.REACT_APP_API_URL
      }/companies/${helpers.getCompanyId()}/counts`,
      {
        method: 'GET',
      },
    ).then((data) => {
      if (!data.status || data.status !== 'ERROR') {
        this.setState({
          countVotes: data.countVotes,
          countSolutions: data.countSolutions,
          countComments: data.countComments,
          countChallenges: data.countChallenges,
        });
      }
    });
    controlledFetch(`${process.env.REACT_APP_API_URL}/user/timelog`, {
      method: 'GET',
    }).then((data) => {
      if (!data.status || data.status !== 'ERROR') {
        const timelog = data?.length ? data.sort((a, b) => b.id - a.id) : [];
        const result = timelog.reduce((r, a) => {
          const key = moment(a.createdAt).format('YYYY-MM-DD');
          r[key] = r[key] || [];
          r[key].push(a);
          return r;
        }, Object.create(null));

        this.setState({
          timelog: result,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.total !== prevProps.total) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { list, total, userRole } = this.props;
    const isMember = userRole === 'ROLE_MEMBER';
    if (!list || total === -1) {
      const logoUrl = this.props.settings.logoUrl;

      return (
        <Segment className={AppStyles.segmentGhost}>
          <div className={`container ${styles.challengesList}`}>
            {this.props.userRole !== 'ROLE_MEMBER' && (
              <div className={styles.top}>
                <div>
                  <div className="header"></div>
                </div>
                <div>
                  <div className={styles.switchTileListView}>
                    <div className="icons"></div>
                  </div>
                  {!isMember && (
                    <Button
                      to="/challenge/add/1"
                      text="Create challenge"
                      primary={true}
                    />
                  )}
                </div>
              </div>
            )}
            <div>
              <div
                className={[
                  styles.tilesContainer,
                  this.state.cookies.tableViewMode !== 'list'
                    ? styles.active
                    : '',
                ].join(' ')}
              >
                <div className={['ui two cards', TableStyles.tiles].join(' ')}>
                  <div className="ui card">
                    <Image
                      size="tiny"
                      wrapped
                      centered
                      src={logoUrl}
                      className="placeholderImage"
                    />
                    <div className="content">
                      <div className="meta"></div>
                      <div className="header"></div>
                      <div className="description"></div>
                    </div>
                  </div>
                  <div className="ui card">
                    <Image
                      size="tiny"
                      wrapped
                      centered
                      src={logoUrl}
                      className="placeholderImage"
                    />
                    <div className="content">
                      <div className="meta"></div>
                      <div className="header"></div>
                      <div className="description"></div>
                    </div>
                  </div>
                  <div className="ui card">
                    <Image
                      size="tiny"
                      wrapped
                      centered
                      src={logoUrl}
                      className="placeholderImage"
                    />
                    <div className="content">
                      <div className="meta"></div>
                      <div className="header"></div>
                      <div className="description"></div>
                    </div>
                  </div>
                  <div className="ui card">
                    <Image
                      size="tiny"
                      wrapped
                      centered
                      src={logoUrl}
                      className="placeholderImage"
                    />
                    <div className="content">
                      <div className="meta"></div>
                      <div className="header"></div>
                      <div className="description"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Segment>
      );
    }
    const listNoDraft = list.filter((element) => element.status !== 'DRAFT');
    const listDraft = list.filter((element) => element.status === 'DRAFT');

    return (
      <div className={`container ${styles.challengesList}`}>
        {this.props.userRole !== 'ROLE_MEMBER' && (
          <div className={styles.top}>
            <div>
              {/*<div>*/}
              {/*  <Select*/}
              {/*    defaultValue='last'*/}
              {/*    options={[*/}
              {/*      {*/}
              {/*        text: 'Last updated',*/}
              {/*        value: 'last'*/}
              {/*      },*/}
              {/*    ]} />*/}
              {/*</div>*/}
              <div className={styles.switchTileListView}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    this.state.cookies.tableViewMode !== 'list'
                      ? styles.active
                      : ''
                  }
                  onClick={() => {
                    this.props.cookies.set('tableViewMode', 'tile');
                    this.setState({
                      cookies: this.props.cookies.getAll(),
                    });
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25 17C25.5523 17 26 17.4477 26 18V25C26 25.5523 25.5523 26 25 26H18C17.4477 26 17 25.5523 17 25V18C17 17.4477 17.4477 17 18 17H25ZM14 17C14.5523 17 15 17.4477 15 18V25C15 25.5523 14.5523 26 14 26H7C6.44772 26 6 25.5523 6 25V18C6 17.4477 6.44772 17 7 17H14ZM24 19H19V24H24V19ZM13 19H8V24H13V19ZM14 6C14.5523 6 15 6.44772 15 7V14C15 14.5523 14.5523 15 14 15H7C6.44772 15 6 14.5523 6 14V7C6 6.44772 6.44772 6 7 6H14ZM25 6C25.5523 6 26 6.44772 26 7V14C26 14.5523 25.5523 15 25 15H18C17.4477 15 17 14.5523 17 14V7C17 6.44772 17.4477 6 18 6H25ZM13 8H8V13H13V8ZM24 8H19V13H24V8Z"
                    fill="black"
                  />
                </svg>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  className={
                    this.state.cookies.tableViewMode === 'list'
                      ? styles.active
                      : ''
                  }
                  onClick={() => {
                    this.props.cookies.set('tableViewMode', 'list');
                    this.setState({
                      cookies: this.props.cookies.getAll(),
                    });
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="6"
                    y="9"
                    width="20"
                    height="5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="6"
                    y="18"
                    width="20"
                    height="5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div>
              {!isMember && (
                <Button
                  to="/challenge/add/1"
                  text="Create challenge"
                  primary={true}
                />
              )}
            </div>
          </div>
        )}
        <div>
          <div
            className={[
              styles.tilesContainer,
              this.state.cookies.tableViewMode !== 'list' ? styles.active : '',
            ].join(' ')}
          >
            <Table
              editPath="/challenge/view/:id"
              buttonToAction={null}
              rows={listNoDraft}
              loading={this.state.loading}
              defaultTileView={true}
            />
            {!isMember &&
              listDraft.length > 0 && [
                <h3
                  key="draftTitle"
                  className={[
                    'divider',
                    'draftTitle',
                    this.state.cookies.tableViewMode === 'list'
                      ? styles.draftList
                      : '',
                  ].join(' ')}
                >
                  Drafts
                </h3>,
                <Table
                  key="draftTable"
                  editPath="/challenge/view/:id"
                  buttonToAction={null}
                  rows={listDraft}
                  loading={this.state.loading}
                  defaultTileView={true}
                />,
              ]}
          </div>
          {this.state.cookies.tableViewMode !== 'list' && (
            <Card className={styles.rightSidebar}>
              <div className={['content', styles.content].join(' ')}>
                <div
                  className={[
                    'ui',
                    'two',
                    'column',
                    'grid',
                    styles.challengesSummary,
                  ].join(' ')}
                >
                  <div className="column">
                    <h5>Challenges</h5>
                    <span>{this.state.countChallenges}</span>
                  </div>
                  <div className="column">
                    <h5>Upvotes</h5>
                    <span>{this.state.countVotes}</span>
                  </div>
                  <div className="column">
                    <h5>Comments</h5>
                    <span>{this.state.countComments}</span>
                  </div>
                  <div className="column">
                    <h5>Solutions</h5>
                    <span>{this.state.countSolutions}</span>
                  </div>
                </div>
              </div>
              <div className={styles.challengesLog}>
                {Object.keys(this.state.timelog).map((day) => (
                  <div key={day} className={styles.challengesLogGroup}>
                    <h3>
                      <DateField
                        datetime={`${day}T00:00:00+00:00`}
                        calendar={{
                          lastDay: '[Yesterday]',
                          sameDay: '[Today]',
                          lastWeek: '[Last week]',
                          sameElse: 'L',
                        }}
                      />
                    </h3>
                    <div className={styles.challengesLogWrap}>
                      {this.state.timelog[day].map((log) => (
                        <div key={log.id} className={styles.challengesLogItem}>
                          <div>
                            <IconUser
                              className={styles.smallerIcon}
                              firstName={log.user.firstName}
                              lastName={log.user.lastName}
                              avatarUrl={log.user.avatarUrl}
                            />
                          </div>
                          <div>
                            <h4>
                              {log.user.firstName} {log.user.lastName}
                            </h4>
                            <p>{log.details}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          )}
          <ContextMenu
            callback={() => {
              this.props.fetchChallengesList(this.props.userRole);
            }}
            pathPrefix="challenge"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ challenges, userProfile, settings }) => {
  return {
    settings,
    list: challenges.list,
    total: challenges.total,
    userRole: userProfile.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChallengesList: (userRole) => {
      dispatch(fetchChallengesList(userRole));
    },
  };
};

ChallengesList.propTypes = {
  list: PropTypes.array.isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fetchChallengesList: PropTypes.func.isRequired,
  defaultHeader: PropTypes.array,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(ChallengesList));
