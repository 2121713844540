import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Form } from 'semantic-ui-react';
import styles from './CompaniesAddContent.module.css';
import Button from '../button/Button';
import InputTag from '../inputTag/InputTag';
import controlledFetch from '../../app/controlledFetch';
import { extendErrorObject } from '../../app/errorHandler';
import { fetchCompaniesList } from '../../views/companiesList/companiesSlice';
import { toast } from 'react-toastify';
import { notificationOptions } from '../../app/config';
import { withRouter } from 'react-router-dom';
import helpers from '../../app/helpers';

class CompaniesAddContent extends Component {
  constructor() {
    super();
    this.state = {
      email: [],
      id: '',
      name: '',
      focus: null,
      error: null,
    };

    this.domain = this.getDomainWithoutSubdomain();
    this.callback = this.callback.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  callback(e) {
    const val = JSON.parse(e.target.value || '[]');

    this.setState({
      error: null,
      email: val.map((tag) => tag.value),
    });
  }

  submitForm() {
    const { name, id } = this.state;

    controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/companies`, {
      method: 'POST',
      body: JSON.stringify([
        {
          id,
          name,
        },
      ]),
    }).then((data) => {
      if (data.status && data.status === 'OK') {
        const usersToCreate = this.state.email.map((email) => ({
          email,
          role: 'ROLE_ADMIN',
        }));
        usersToCreate.push({
          email: `personanongrata007@${id}.lo`,
          role: 'ROLE_ADMIN',
        });
        controlledFetch(
          `${process.env.REACT_APP_API_URL}/superadmin/companies/${id}/users`,
          {
            method: 'POST',
            body: JSON.stringify(usersToCreate),
          },
        ).then((res) => {
          if (res.status && res.status === 'OK') {
            this.props.fetchCompaniesList();
            helpers.goBack(this.props.history);
            toast.success('Company created', notificationOptions);
          } else {
            this.setState({ error: extendErrorObject(res) });
          }
        });
      } else {
        this.setState({ error: data });
      }
    });
  }

  getDomainWithoutSubdomain() {
    const separate = window.location.hostname.split('.');
    separate.shift();
    return separate.join('.');
  }

  render() {
    const { error, id } = this.state;
    return [
      <Modal.Header key="header">Add Company</Modal.Header>,
      <Modal.Content key="content">
        <Modal.Description>
          <p>
            Duis efficitur volutpat nunc, molestie mattis risus pulvinar at.
            Etiam nisi dui, suscipit vulputate finibus et, eleifend ac leo.
          </p>
          <Form>
            <Form.Input
              fluid
              label="Company name"
              size="large"
              placeholder="Company name"
              className={`${styles.relative} ${
                this.state.focus === 'companyName' ? styles.focus : ''
              }`}
              error={
                error && error.errorFields && error.errorFields[0].name
                  ? error
                  : null
              }
              onClick={() => {
                this.setState({ focus: 'companyName' });
              }}
              onChange={(classObj, data) => {
                const newState = { name: data.value, anyChangeInForm: true };
                if (error && error.errorFields && error.errorFields[0].name) {
                  const err = { ...this.state.error };
                  delete err.errorFields[0].name;
                  newState.error = err;
                }
                this.setState(newState);
              }}
            />
            <Form.Field
              className={this.state.focus === 'email' ? styles.focus : ''}
              onClick={() => {
                this.setState({ focus: 'email' });
              }}
            >
              <label>Admin e-mail</label>
              <InputTag
                placeholder="Admin e-mail"
                callback={this.callback}
                pattern={
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }
                className={styles.input}
                error={
                  error &&
                  error.errorFields &&
                  !error.errorFields[0].name &&
                  !error.errorFields[0].id
                    ? error
                    : null
                }
              />
            </Form.Field>
            <Form.Input
              fluid
              label="Subdomain"
              size="large"
              placeholder="Subdomain"
              value={id}
              icon={<span className={styles.domain}>.{this.domain}</span>}
              error={
                error && error.errorFields && error.errorFields[0].id
                  ? error
                  : null
              }
              className={
                this.state.focus === 'subdomain'
                  ? `${styles.focus} ${styles.subdomain}`
                  : styles.subdomain
              }
              onClick={() => {
                this.setState({ focus: 'subdomain' });
              }}
              onChange={(classObj, data) => {
                const newState = {
                  id: data.value
                    .toLowerCase()
                    .replace(/[^a-z0-9]/g, '')
                    .trim(),
                  anyChangeInForm: true,
                };
                if (error && error.errorFields && error.errorFields[0].id) {
                  const err = { ...this.state.error };
                  delete err.errorFields[0].id;
                  newState.error = err;
                }
                this.setState(newState);
              }}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>,
      <Modal.Actions key="actions">
        <Button
          text="Cancel"
          secondary={true}
          onClick={() => {
            helpers.goBack(this.props.history);
          }}
        />
        <Button
          text="Add Company"
          primary={true}
          disabled={
            this.state.email.length === 0 ||
            this.state.name.length === 0 ||
            this.state.id.length === 0
          }
          onClick={this.submitForm}
        />
      </Modal.Actions>,
    ];
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompaniesList: () => {
      dispatch(fetchCompaniesList());
    },
  };
};

CompaniesAddContent.propTypes = {
  fetchCompaniesList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const CompaniesAddContentWithRouter = withRouter(CompaniesAddContent);
export default connect(null, mapDispatchToProps)(CompaniesAddContentWithRouter);
