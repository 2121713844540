import React from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionStatic.module.css';
import { Label } from 'semantic-ui-react';

const QuestionStatic = ({ id, question, answerInfo }) => (
  <div id={id}>
    <span className={styles.title}>Question title</span>
    <p className={styles.question}>{question}</p>
    <Label
      size="big"
      className={`${styles.answerInfo} ${styles.ui} ${styles.big} ${styles.label}`}
    >
      {answerInfo}
    </Label>
  </div>
);

QuestionStatic.defaultProps = {
  question: '',
  answerInfo: '',
};

QuestionStatic.propTypes = {
  id: PropTypes.number,
  question: PropTypes.string,
  answerInfo: PropTypes.string,
};

export default QuestionStatic;
