import { createSlice } from '@reduxjs/toolkit';
import controlledFetch from '../../app/controlledFetch';

const initialState = {
  id: -1,
  email: '',
  firstName: '',
  lastName: '',
  phone: null,
  loggedAt: null,
  fullName: '',
  avatarUrl: null,
  cookieUrl: null,
  role: null,
  notification1: false,
  notification2: false,
  notification3: false,
  emailNotifications: true,
  countVotes: 0,
  countChallenges: 0,
  countComments: 0,
  countSolutions: 0,
};
export const userProfile = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const payload = action.payload ? action.payload : initialState;
      state.id = payload.id;
      state.email = payload.email;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.phone = payload.phone;
      state.loggedAt = payload.loggedAt;
      state.fullName = payload.fullName;
      state.avatarUrl = payload.avatarUrl;
      state.cookieUrl = payload.cookieUrl;
      state.role = payload.role;
      state.notification1 = payload.notification1;
      state.notification2 = payload.notification2;
      state.notification3 = payload.notification3;
      state.emailNotifications = payload.emailNotifications;
      state.emailNotification = payload.emailNotification;
      state.hasUnreadNotifications = payload.hasUnreadNotifications;
      state.countChallenges = payload.countChallenges;
      state.countComments = payload.countComments;
      state.countVotes = payload.countVotes;
      state.countSolutions = payload.countSolutions;
    },
  },
});

export const { setUser } = userProfile.actions;

export const fetchUserProfile = (isSuperAdmin = false) => (dispatch) => {
  controlledFetch(
    `${process.env.REACT_APP_API_URL}/${
      isSuperAdmin ? 'superadmin' : 'user'
    }/profile`,
    {
      method: 'GET',
    },
  ).then((data) => {
    if (!data || (data.status && data.status > 399)) {
      window.location.href = '/logout';
      dispatch(
        setUser({
          ...initialState,
          id: 0,
        }),
      );
    }

    if (data.avatarUrl) {
      const img = new Image();
      img.src = data.avatarUrl;
      img.onload = () => {
        dispatch(setUser(data));
      };
      img.onerror = () => {
        setTimeout(() => {
          dispatch(setUser(data));
        }, 200);
      };
    } else {
      dispatch(setUser(data));
    }
  });
};

export default userProfile.reducer;
