module.exports = {
  REQUEST_TIMEOUT: 10000,
  KANBAN_MIN_COLUMNS: 3,
  KANBAN_MAX_COLUMNS: 5,
  MAX_UPLOAD_FILE_SIZE: 10000000, // 10mb
  notificationOptions: {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  }
}