import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionLong.module.css';
import { Form, TextArea, Message } from 'semantic-ui-react';

const QuestionLong = ({ id, question, answer, isEdit, saveAnswer, error }) => {
  const [value, setValue] = useState(answer);
  const [dirty, setDirty] = useState(false);
  const isEmpty = (dirty || error) && value.length === 0;

  return (
    <div id={id}>
      <p className={styles.question}>{question}</p>
      {isEdit ? (
        <Form className={styles.answerContainer}>
          <TextArea
            className={[styles.textarea, isEmpty ? styles.error : ''].join(' ')}
            onChange={(obj, data) => {
              setValue(data.value);
              saveAnswer(data.value);
              setDirty(true);
            }}
            placeholder="Type in long answer"
            value={value}
            size="large"
          />
          {(isEmpty || error) && (
            <Message
              error
              content={error || 'Answer can’t be empty'}
              className={styles.minimalError}
            />
          )}
        </Form>
      ) : (
        <p className={styles.answer}>{answer}</p>
      )}
    </div>
  );
};

QuestionLong.defaultProps = {
  question: '',
  answer: '',
  isEdit: true,
};

QuestionLong.propTypes = {
  id: PropTypes.number,
  error: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
  isEdit: PropTypes.bool,
  saveAnswer: PropTypes.func,
};

export default QuestionLong;
