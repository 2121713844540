import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import {
  showConfirmationModal,
} from '../contextMenu/contextMenuSlice'
import Button from '../button/Button'
import PropTypes from 'prop-types'
 
const ConfirmationPopup = ({ header, content, actionText, confirmedAction, open = false, className = 'red' }) => {
  const dispatch = useDispatch()
  const hide = useCallback(() => {
    dispatch(showConfirmationModal(false))
  }, [dispatch])

  return <Modal size="tiny"
    open={open}
    centered={true}
    onClose={hide}>
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content>{content}</Modal.Content>
    <Modal.Actions>
      <Button text="Cancel"
        secondary={true}
        onClick={hide} />
      <Button text={actionText}
        className={className}
        onClick={confirmedAction} />
    </Modal.Actions>
  </Modal>
}

ConfirmationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  className: PropTypes.string,
  actionText: PropTypes.string.isRequired,
  confirmedAction: PropTypes.func.isRequired
}

export default ConfirmationPopup