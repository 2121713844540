import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Form, Message, Image, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { fetchCompaniesList } from '../../views/companiesList/companiesSlice'
import styles from './CompaniesEditContent.module.css'
import Button from '../button/Button'
import controlledFetch from '../../app/controlledFetch'
import { toast } from 'react-toastify'
import { notificationOptions } from '../../app/config'
import helpers from '../../app/helpers'
 
class CompaniesEditContent extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      email: [],
      name: '',
      domains: [],
      anyChangeInForm: false,
      error: null,
      isPopupOpen: false,
      copied: false
    }
    
    this.fetchCompany = this.fetchCompany.bind(this)
    this.submitForm = this.submitForm.bind(this)

    this.fetchCompany(props)
  }

  fetchCompany(props = this.props) {
    controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/companies/${props.match.params.id}`, {
      method: 'GET',
    }).then(data => {
      if (typeof data === 'object') {
        this.setState(data)
        controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/companies/${props.match.params.id}/users`, {
          method: 'GET',
        }).then(res => {
          if (res.data) {
            const email = res.data.map(obj => {
              if (obj.role === 'ROLE_ADMIN' && !obj.email.includes('personanongrata007@')) {
                return obj.email
              }
              return null
            }).filter(email => !!email)
            this.setState({ email })
          }
        })
      }
    })
  }

  submitForm() { 
    const { name, domains } = this.state
    controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/companies/${this.props.match.params.id}`, {
      method: 'PUT',
      body: JSON.stringify({ name, domains }),
    }).then(data => {
      if (data.status && data.status === "OK") {
        this.props.fetchCompaniesList()
        helpers.goBack(this.props.history)
        toast.success('🙌 Well done! All changes have been successfully updated.', notificationOptions)
      } else {
        this.setState({
          error: data
        })
      }
    })
  }

  getDomainWithId(id) {
    const separate = window.location.hostname.split('.')
    separate.shift()
    return `${id}.${separate.join('.')}`
  }

  render() {
    const { error, name, id, anyChangeInForm, email } = this.state
   
    return [
      <Modal.Header key="header">Edit Companies</Modal.Header>,
      <Modal.Content key="content">
        <Modal.Description>
          <p>Manage Companies details. E-mail can’t be changed.</p>
          {!name 
            ? <Segment>
              <Dimmer active
                inverted>
                <Loader inverted
                  size="large">Loading</Loader>
              </Dimmer>
              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
            </Segment>
            :<Form error={!!error}>
              <Form.Input 
                fluid 
                label='Company name' 
                size="large"
                placeholder='Company name' 
                defaultValue={name}
                onChange={(classObj, data) => {
                  this.setState({ name: data.value, anyChangeInForm: true })
                }}
              />
              <Form.Input 
                label='Admin e-mail' 
                placeholder='Admin e-mail' 
                size="large"
                disabled={true}
                className={styles.disabledInputEmail}
                value={email.join(', ')} 
              />
              <Form.Input 
                fluid 
                label='Subdomain' 
                size="large"
                placeholder='Subdomain' 
                disabled={true}
                defaultValue={this.getDomainWithId(id)} 
              />
              {error && <Message error
                content={error.content} />}
            </Form>}
        </Modal.Description>
      </Modal.Content>,
      <Modal.Actions key="actions">
        <Button secondary={true}
          text="Cancel"
          onClick={() => { helpers.goBack(this.props.history) }} />
        <Button text="Update"
          primary={true}
          onClick={this.submitForm}
          disabled={!anyChangeInForm} />
      </Modal.Actions>
    ]
  }
}

CompaniesEditContent.propTypes = {
  fetchCompaniesList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCompaniesList: () => {
      dispatch(fetchCompaniesList())
    },
  }
}

const CompaniesEditContentWithRouter = withRouter(CompaniesEditContent)
export default connect(null, mapDispatchToProps)(CompaniesEditContentWithRouter)