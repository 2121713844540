import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Image, Label } from 'semantic-ui-react';
import IconUser from '../iconUser/IconUser';
import styles from './Tile.module.css';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const Tile = ({
  id,
  thumbnailUrl,
  title,
  tags = [],
  description,
  countViews,
  countVotes,
  countSolutions,
  countComments,
  user,
  openEdit,
  updatedAt,
}) => {
  const logoUrl = useSelector(({ settings }) => settings.logoUrl);

  return (
    <Card>
      <Link to={`/challenge/view/${id}`}>
        {thumbnailUrl ? (
          <Image src={thumbnailUrl} wrapped ui={false} />
        ) : (
          <Image
            size="tiny"
            wrapped
            centered
            src={logoUrl}
            className="placeholderImage"
          />
        )}
        <Label.Group circular>
          <Label>
            <Image src="/assets/icon-bulb-black.svg" ui={false} />
            <span>{countSolutions}</span>
          </Label>
          <Label>
            <Image src="/assets/icon-comment.svg" ui={false} />
            <span>{countComments}</span>
          </Label>
          <Label>
            <Image src="/assets/icon-like.svg" ui={false} />
            <span>{countVotes}</span>
          </Label>
        </Label.Group>
        <Card.Content>
          <Card.Meta>
            <span>{tags.join(', ')}</span>
          </Card.Meta>
          <Card.Header>{title}</Card.Header>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
      </Link>
      <Card.Content extra>
        <div style={{ float: 'left' }}>
          <IconUser
            firstName={user ? user.firstName : '-'}
            lastName={user ? user.lastName : '-'}
            avatarUrl={user ? user.avatarUrl : ''}
            className={styles.smallerAvatar}
          />
          <span>
            {user ? user.firstName : '-'} {user ? user.lastName : '-'}
          </span>
        </div>
        <div style={{ float: 'right' }}>
          <Moment format="MM/DD/YYYY">{updatedAt}</Moment>
          <div
            className={styles.dots}
            onClick={(event) => {
              openEdit(event);
            }}
          >
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 4C0.9 4 0 3.1 0 2C0 0.9 0.9 0 2 0C3.1 0 4 0.9 4 2C4 3.1 3.1 4 2 4ZM2 6C3.1 6 4 6.9 4 8C4 9.1 3.1 10 2 10C0.9 10 0 9.1 0 8C0 6.9 0.9 6 2 6ZM2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12Z"
                fill="#0000FF"
              />
            </svg>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

Tile.propTypes = {
  id: PropTypes.number,
  thumbnailUrl: PropTypes.string,
  tags: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Tile;
