export default {
  includesOneOf: (target, pattern) => {
    if (target === null || !Array.isArray(pattern)) {
      return false;
    }
    if (Array.isArray(pattern) && pattern.length === 0) {
      return true;
    }

    let value = 0;
    pattern.forEach((word) => {
      const start = target.indexOf(word.toLowerCase());
      const end = start + word.length - 1;

      if (
        end < target.length &&
        start > -1
        // &&
        // (target[start - 1] === ' ' || start === 0) &&
        // (target[end + 1] === ' ' || end === target.length - 1)
      ) {
        value++;
      }
    });
    return value > 0;
  },
  firstCharUpper: (str) => str.charAt(0).toUpperCase() + str.substring(1),
  getQuery: () => {
    return new URLSearchParams(window.location.search);
  },
  focusFirstInput: () => {
    const input = document.querySelector('input');
    if (input) {
      input.focus();
    }
  },
  isSuperAdmin: () => {
    if (!window.location) {
      return false;
    }
    return window.location.hostname.indexOf('superadmin') === 0;
  },
  humanReadableRole: (role) => {
    switch (role) {
      case 'ROLE_MEMBER':
        return 'member';
      case 'ROLE_ADMIN':
        return 'admin';
      case 'ROLE_SUPERADMIN':
        return 'superAdmin';
      default:
        return null;
    }
  },
  getCompanyId: (hostname = window.location.hostname) => {
    return 'bostonglobe';
    // if (hostname.indexOf('superadmin') === 0) {
    //   return 'artifact';
    // }
    // const parts = hostname.split('.');
    // const subdomain = parts.shift();
    // const [topLevel, domainName] = parts.reverse();
    // if (
    //   !subdomain ||
    //   !topLevel ||
    //   !domainName
    // ) {
    //   return 'artifact';
    // }
    // return subdomain || 'artifact';
  },
  getCurrentDomain: () => {
    const separate = window.location.host.split('.');
    separate.shift();
    return separate.join('.');
  },
  getHeaderBaseOnLocation: (
    pathname = window.location.pathname,
    initial = 'Innovation Platform',
  ) => {
    switch (pathname.replace(/\/[0-9]+/, '')) {
      // case '/solutions':
      //   return 'Solutions';
      // case '/settings':
      //   return 'Settings';
      // case '/challenges':
      //   return 'Challenge management';
      // case '/profile':
      //   return 'Your Profile';
      // case '/users':
      // case '/user/add':
      // case '/user/edit':
      //   return 'Member management';
      default:
        return initial;
    }
  },
  replaceIdByUrl(content = '', gallery = []) {
    if (gallery && content) {
      gallery.forEach((image) => {
        const regex = new RegExp(`;;${image.imageId};;`, 'g');
        content = content.replace(regex, image.imageUrl);
      });
    }

    return content;
  },
  replaceUrlById(content = '', gallery = []) {
    if (gallery && content) {
      gallery.forEach((image) => {
        const regex = new RegExp(image.imageUrl, 'g');
        content = content.replace(regex, `;;${image.imageId};;`);
      });
    }

    return content;
  },
  getMarginLeftValue(possibleAnswer) {
    const referenceInputValueWidth = document.getElementById(
      'referenceInputValueWidth',
    );
    if (
      referenceInputValueWidth &&
      possibleAnswer &&
      possibleAnswer.value &&
      possibleAnswer.value.length > 0
    ) {
      referenceInputValueWidth.innerText = possibleAnswer.value;
      return referenceInputValueWidth.clientWidth;
    }

    return 190;
  },
  getUniqId() {
    const date = new Date();
    return parseInt(
      Math.random() * date.getMilliseconds() + date.getSeconds() * 10000000,
      10,
    );
  },
  goBack(history = window.history, pushPath = null) {
    if (history.length >= 1 && history.length <= 2) {
      history.push('/');
    } else {
      if (pushPath) {
        history.push(pushPath);
      } else {
        history.goBack();
      }
    }
  },
};
