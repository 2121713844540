import React  from 'react'
import PropTypes from 'prop-types'
import styles from './IconUser.module.css'
 
const IconUser = ({ firstName = '', lastName = '', className = '', avatarUrl='' }) => {
  const classes = [styles.icon, className].join(' ')
  if (!firstName) {
    return <span className={classes}></span>
  }
  if(!avatarUrl){
    return (<span className={classes}>{firstName.charAt(0).toUpperCase()}{lastName.charAt(0).toUpperCase()}</span>)
  }

  return (<img alt="avatar" src={avatarUrl} className={classes}/>)
}

IconUser.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  className: PropTypes.string,
  avatarUrl: PropTypes.string
}

export default IconUser