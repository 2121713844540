import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionCheckbox.module.css';
import { Form, Checkbox, Message } from 'semantic-ui-react';

const QuestionCheckbox = ({
  anchor,
  answer = [],
  question = '',
  possibleAnswers = [],
  saveAnswer,
  isEdit = true,
  error = null,
}) => {
  const [localAnswer, setLocalAnswer] = useState(answer);
  const isEmpty = error && localAnswer.length === 0;

  return (
    <div id={anchor}>
      <p className={styles.question}>{question}</p>
      {isEdit ? (
        <div className={styles.answerContainer}>
          <Form error={isEmpty}>
            {possibleAnswers.map((possibleAnswer) => (
              <Form.Field key={possibleAnswer.id}>
                <Checkbox
                  className={[
                    styles.checkbox,
                    isEmpty ? styles.error : '',
                  ].join(' ')}
                  label={possibleAnswer.label}
                  name="CheckboxGroup"
                  size="large"
                  placeholder="Type in text for a checkbox"
                  value={possibleAnswer.value}
                  checked={localAnswer.includes(possibleAnswer.value)}
                  onChange={(e, { value }) => {
                    const newLocalAnswer = [...localAnswer];
                    if (newLocalAnswer.includes(value)) {
                      newLocalAnswer.splice(newLocalAnswer.indexOf(value), 1);
                    } else {
                      newLocalAnswer.push(value);
                    }
                    setLocalAnswer(newLocalAnswer);
                    saveAnswer(newLocalAnswer);
                  }}
                />
              </Form.Field>
            ))}
          </Form>
          {isEmpty && (
            <Message
              error
              content="Answer can’t be empty"
              className={styles.minimalError}
            />
          )}
        </div>
      ) : (
        <p className={styles.answer}>
          {answer && typeof answer.join === 'function'
            ? answer.join(', ')
            : answer}
        </p>
      )}
    </div>
  );
};

QuestionCheckbox.propTypes = {
  anchor: PropTypes.number,
  question: PropTypes.string,
  possibleAnswers: PropTypes.array,
  answer: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  type: PropTypes.string,
  id: PropTypes.number,
  saveAnswer: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default QuestionCheckbox;
