import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
  Segment,
  Image,
  Divider,
  Card,
  Input,
  Form,
  TextArea,
} from 'semantic-ui-react';
import {
  fetchChallenge,
  setCurrent,
} from '../../views/challengesList/challengesSlice';
import {
  setId,
  setPosition,
  setShowModal,
} from '../contextMenu/contextMenuSlice';
import styles from './SolutionForm.module.css';
import AppStyles from '../../App.module.css';
import QuestionShort from '../questionShort/QuestionShort';
import QuestionLong from '../questionLong/QuestionLong';
import QuestionRadio from '../questionRadio/QuestionRadio';
import QuestionDate from '../questionDate/QuestionDate';
import QuestionTime from '../questionTime/QuestionTime';
import QuestionCheckbox from '../questionCheckbox/QuestionCheckbox';
import QuestionDropdown from '../questionDropdown/QuestionDropdown';
import Button from '../button/Button';
import controlledFetch from '../../app/controlledFetch';
import './SolutionForm.module.css';
import IconUser from '../iconUser/IconUser';
import { notificationOptions } from '../../app/config';
import Moment from 'react-moment';
import ContextMenu from '../contextMenu/ContextMenu';
import QuestionFile from '../questionFile/QuestionFile';
import SolutionComment from '../solutionComment/SolutionComment';
import helpers from '../../app/helpers';
class SolutionForm extends Component {
  constructor(props) {
    super(props);

    props.fetchChallenge({
      userRole: props.userRole,
      userId: props.userId,
      id: props.match.params.challengeId,
    });

    this.renderHeader = this.renderHeader.bind(this);
    this.saveSolution = this.saveSolution.bind(this);
    this.getSolution = this.getSolution.bind(this);
    this.setAnswers = this.setAnswers.bind(this);
    this.addVote = this.addVote.bind(this);
    this.addComment = this.addComment.bind(this);
    this.isFormValid = this.isFormValid.bind(this);

    this.getSolution();

    if (props.current) {
      props.setHeaderContent(this.renderHeader());
    }
    props.setShowSidebar(false);

    this.state = {
      user: {},
      createdAt: null,
      countComments: 0,
      countVotes: 0,
      answers: {},
      column: 0,
      anyChangeInForm: false,
      error: null,
      comments: [],
      commentValue: '',
      solutionTitle: '',
      canUpvote: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.current && this.props.current) {
      this.props.setHeaderContent(this.renderHeader());
    }
  }

  componentWillUnmount() {
    this.props.setCurrent(null);
  }

  renderHeader() {
    const { current, match, history } = this.props;
    return [
      <div key="left-header-side" className={styles.header}>
        <span
          onClick={() => {
            helpers.goBack(history);
          }}
          className={AppStyles.backLink}
        >
          <img src="/assets/arrow-left.svg" alt="arrow left" />
        </span>
        <div className={styles.breadcrumbs}>
          Innovation Platform
          {/*<span>{current.title}</span>*/}
          {/*<span className={styles.active}>*/}
          {/*  Solution{' '}*/}
          {/*  {match.params.solutionId ? `#${match.params.solutionId}` : ''}*/}
          {/*</span>*/}
        </div>
      </div>,
    ];
  }

  getSolution() {
    if (!this.props.match.params.solutionId) {
      return;
    }
    return controlledFetch(
      `${process.env.REACT_APP_API_URL}/user/solutions/${this.props.match.params.solutionId}`,
      { method: 'GET' },
    ).then((data) => {
      if (data) {
        this.setState({
          id: data.id,
          user: data.user,
          createdAt: data.createdAt,
          countVotes: data.countVotes,
          column: data.column,
          countComments: data.countComments,
          solutionTitle: data.title
        });
        data.answers.forEach((answer) => {
          this.setAnswers(answer.title, answer.id);
        });
        this.getComments();
      }
    });
  }

  getComments() {
    if (!this.props.match.params.solutionId) {
      return;
    }

    return controlledFetch(
      `${process.env.REACT_APP_API_URL}/user/comments?orderBy=id&orderDir=desc&filter[solutionId]=${this.state.id}`,
      {
        method: 'GET',
      },
    ).then((data) => {
      if (data) {
        this.setState({
          comments: data.data,
        });
      }
    });
  }

  addComment() {
    controlledFetch(`${process.env.REACT_APP_API_URL}/user/comments`, {
      method: 'POST',
      body: JSON.stringify([
        {
          solutionId: Number(this.state.id),
          comment: this.state.commentValue,
        },
      ]),
    }).then((data) => {
      if (data.status && data.status === 'OK') {
        toast.success(
          '🙌 Well done! Your comment has been posted.',
          notificationOptions,
        );
        this.getComments();

        this.setState({
          commentValue: '',
        });
      } else {
        this.setState({
          error: data,
        });
      }
    });
  }

  setAnswers(value, id) {
    this.setState({
      answers: {
        ...this.state.answers,
        [id]: {
          id,
          title: value,
        },
      },
    });
  }

  addVote() {
    this.setState({
      canUpvote: false,
    });
    controlledFetch(`${process.env.REACT_APP_API_URL}/user/votes`, {
      method: 'POST',
      body: JSON.stringify([
        {
          solutionId: Number(this.props.match.params.solutionId),
        },
      ]),
    }).then((data) => {
      if (data.status && data.status === 'OK') {
        toast.success(
          'Your vote has been counted for this solution.',
          notificationOptions,
        );
        helpers.goBack(this.props.history);
      } else {
        this.setState({
          error: data,
        });
      }
    });
  }

  isFormValid() {
    const error = {};
    let res = true;
    let firstErrorId = null;

    if (!this.state.solutionTitle || this.state.solutionTitle === '') {
      error.solutionTitle = "Title can't be empty";
      firstErrorId = 'solutionTitle';
      res = false;
    }

    this.props.current.questionnaire.forEach((questionnaire) => {
      if (
        questionnaire.type !== 'upload_file' &&
        !this.state.answers[questionnaire.id]
      ) {
        error[questionnaire.id] = "Answer can't be empty";
        if (res) {
          firstErrorId = questionnaire.id;
        }
        res = false;
      }
    });

    if (!res) {
      this.setState({ error });
      if (firstErrorId) {
        var element = document.getElementById(firstErrorId);
        // smooth scroll to element and align it at the bottom
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    return res;
  }

  saveSolution() {
    if (this.isFormValid()) {
      let url = `${process.env.REACT_APP_API_URL}/user/solutions`;
      if (this.props.edit) {
        url += '/' + this.props.match.params.solutionId;
      }

      controlledFetch(url, {
        method: this.props.edit ? 'PUT' : 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify([
          {
            challengeId: Number(this.props.match.params.challengeId),
            title: this.state.solutionTitle,
            answers: Object.values(this.state.answers),
            column: this.props.edit
              ? this.state.column.id
              : this.props.columns[0].id,
          },
        ]),
      }).then((data) => {
        if (data.status && data.status === 'OK') {
          if (this.props.edit) {
            toast.success(
              '🙌 Well done! Solution successfully edited.',
              notificationOptions,
            );
          } else {
            toast.success(
              '🙌 Well done! Solution successfully added.',
              notificationOptions,
            );
          }
          this.props.history.goBack();
        } else {
          this.setState({
            error: data,
          });
        }
      });
    }
  }

  render() {
    const { current, userId, columns } = this.props;
    const {
      id,
      user,
      createdAt,
      countComments,
      countVotes,
      error,
      column,
      comments,
      solutionTitle,
    } = this.state;

    if (!current) {
      return (
        <Segment className={AppStyles.segmentGhost}>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <Card className={styles.solution}>
                <Card.Header>
                  <Button
                    onClick={() => this.props.history.goBack()}
                    className={styles.close}
                  >
                    <Image ui={false} src="/assets/icon-close.svg" />
                  </Button>
                </Card.Header>
                <Card.Content>
                  <div className="header"></div>
                  <div className="meta"></div>
                  <div className="meta"></div>
                </Card.Content>
                <Card.Content extra className={styles.actions}>
                  <div className="header"></div>
                  <div className="meta"></div>
                </Card.Content>
                <Card.Content>
                  <div className="description"></div>
                  <div className="description"></div>
                </Card.Content>
              </Card>
            </div>
          </div>
        </Segment>
      );
    }

    const columnData = columns.filter((col) => col.id === column)[0];

    const openEdit = (event) => {
      const positionX = event.pageX;
      const positionY = event.pageY;

      this.props.setId(this.state.id);
      this.props.setPosition([positionX, positionY]);
      this.props.setShowModal(true);

      setTimeout(() => {
        const modal = document.querySelector(
          'body > div.ReactModalPortal > div > div',
        );
        if (modal) {
          const heightWithMargin = modal.offsetHeight + 16;
          this.props.setPosition([positionX, positionY - heightWithMargin]);
          this.props.setShowModal(true);
        }
      }, 0);
    };

    if (this.props.edit && this.props.userId !== this.state.user.id) {
      if (typeof this.state.user.id !== 'undefined') {
        this.props.history.push(
          `/challenge/view/${this.props.match.params.challengeId}/solution/${this.props.match.params.solutionId}`,
        );
      }
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {this.props.match.params.solutionId && (
            <Card className={styles.solution}>
              <Card.Header className={styles.cardHeader}>
                {!this.props.edit &&
                  (this.props.userId === this.state.user.id ||
                    this.props.userRole === 'ROLE_ADMIN') && (
                    <div onClick={openEdit}>
                      <svg
                        viewBox="0 0 4 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C0.9 4 0 3.1 0 2C0 0.9 0.9 0 2 0C3.1 0 4 0.9 4 2C4 3.1 3.1 4 2 4ZM2 6C3.1 6 4 6.9 4 8C4 9.1 3.1 10 2 10C0.9 10 0 9.1 0 8C0 6.9 0.9 6 2 6ZM2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                  )}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.goBack();
                  }}
                  className={styles.close}
                >
                  <Image src="/assets/icon-close.svg" />
                </a>
              </Card.Header>
              <Card.Content>
                <span>{solutionTitle || id ? `${id}` : ''}</span>
                <span className={styles.submitted}>
                  Submitted on <Moment format="MM/DD/YYYY">{createdAt}</Moment>
                </span>
              </Card.Content>
              <Card.Content extra className={styles.actions}>
                <div>
                  <IconUser
                    firstName={user.firstName}
                    lastName={user.lastName}
                    avatarUrl={user.avatarUrl}
                    className={styles.smallerAvatar}
                  />
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                </div>
                <div className={styles.socialIcons}>
                  <span>
                    <Image src="/assets/icon-like.svg" />
                    <span>{countVotes || 0}</span>
                  </span>
                  <span>
                    <Image src="/assets/icon-comment.svg" />
                    <span>{countComments || 0}</span>
                  </span>
                </div>
              </Card.Content>
            </Card>
          )}
          <h1 className={styles.title}>{current ? current.title : ''}</h1>
          <p className="questDesc">{current ? current.description : ''}</p>
          <Divider className="customDivider" />
          <div className={styles.questionnaire}>
            <QuestionShort
              key="solutionTitle"
              id="solutionTitle"
              answer={solutionTitle}
              saveAnswer={(value) => this.setState({ solutionTitle: value })}
              isEdit={
                !this.props.match.params.solutionId || this.props.edit === true
              }
              question="Solution title"
              error={error && error.solutionTitle ? error.solutionTitle : null}
              errorMsg={
                error && error.solutionTitle ? error.solutionTitle : null
              }
              placeholder="Enter a title for the solution"
            />

            <Divider className="customDivider" />
            {current.questionnaire &&
              current.questionnaire.map((questionnaire) => {
                switch (questionnaire.type) {
                  case 'short_answer':
                    return (
                      <QuestionShort
                        key={questionnaire.id}
                        id={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        question={questionnaire.question}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'long_answer':
                    return (
                      <QuestionLong
                        key={questionnaire.id}
                        id={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        question={questionnaire.question}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'radio':
                    return (
                      <QuestionRadio
                        key={questionnaire.id}
                        anchor={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        {...questionnaire}
                        id={current.id}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'checkbox':
                    return (
                      <QuestionCheckbox
                        key={questionnaire.id}
                        anchor={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        {...questionnaire}
                        id={current.id}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'dropdown':
                    return (
                      <QuestionDropdown
                        key={questionnaire.id}
                        anchor={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        {...questionnaire}
                        id={current.id}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'upload_file':
                    return (
                      <QuestionFile
                        key={questionnaire.id}
                        anchor={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        {...questionnaire}
                        id={current.id}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'date_picker':
                    return (
                      <QuestionDate
                        key={questionnaire.id}
                        anchor={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        {...questionnaire}
                        id={current.id}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  case 'time_picker':
                    return (
                      <QuestionTime
                        key={questionnaire.id}
                        anchor={questionnaire.id}
                        answer={
                          this.state.answers[questionnaire.id]
                            ? this.state.answers[questionnaire.id].title
                            : ''
                        }
                        saveAnswer={(value) =>
                          this.setAnswers(value, questionnaire.id)
                        }
                        isEdit={
                          !this.props.match.params.solutionId ||
                          this.props.edit === true
                        }
                        {...questionnaire}
                        id={current.id}
                        error={
                          error && error[questionnaire.id]
                            ? error[questionnaire.id]
                            : null
                        }
                      />
                    );
                  default:
                    return '';
                }
              })}
          </div>
          {this.state.id && !this.props.edit && (
            <div className={styles.comments}>
              <h2>Comments</h2>
              <Divider className="customDivider" />
              <Form>
                <TextArea
                  placeholder="Your comment"
                  style={{ minHeight: '9rem', resize: 'none' }}
                  onChange={(e) => {
                    this.setState({ commentValue: e.target.value });
                  }}
                  value={this.state.commentValue}
                />
                <Button
                  className={styles.commentSubmit}
                  text="Submit"
                  onClick={this.addComment}
                  primary
                />
              </Form>

              {comments &&
                comments.map((comment) => {
                  return (
                    <SolutionComment
                      key={comment.id}
                      firstName={comment.user.firstName}
                      lastName={comment.user.lastName}
                      authorAvatar={comment.user.avatarUrl}
                      date={new Date(comment.createdAt).toLocaleString('en-US')}
                      content={comment.comment}
                    />
                  );
                })}
            </div>
          )}
          <div className={styles.footer}>
            <div>
              {!this.props.match.params.solutionId || this.props.edit ? (
                <div className={styles.footerSingle}>
                  <Button
                    text={this.props.edit ? 'Edit solution' : 'Submit solution'}
                    primary={true}
                    onClick={this.saveSolution}
                    floated="left"
                  />
                </div>
              ) : (
                [
                  <div key="solutionStatus" className={styles.stage}>
                    <span>Solution stage:</span>
                    {columnData && (
                      <span
                        className={[
                          styles.status,
                          styles[`column-${columns.indexOf(columnData)}`],
                        ].join(' ')}
                      >
                        {columnData.title}
                      </span>
                    )}
                  </div>,
                  <div key="upvoteButton" className={styles.footerLinks}>
                    <Button
                      text="Upvote"
                      primary={true}
                      floated="right"
                      disabled={userId === user.id || !this.state.canUpvote}
                      onClick={this.addVote}
                    />
                  </div>,
                ]
              )}
            </div>
          </div>
        </div>
        {this.props.userRole === 'ROLE_ADMIN' ? (
          <ContextMenu storedId={this.state.id} pathPrefix="solution_admin" />
        ) : (
          <ContextMenu storedId={this.state.id} pathPrefix="solution" />
        )}
      </div>
    );
  }
}

SolutionForm.propTypes = {
  fetchChallenge: PropTypes.func.isRequired,
  setShowSidebar: PropTypes.func,
  setShowModal: PropTypes.func,
  setHeaderContent: PropTypes.func,
  setCurrent: PropTypes.func,
  userRole: PropTypes.string,
  userId: PropTypes.number,
  current: PropTypes.object,
  error: PropTypes.object,
  edit: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ challenges, userProfile, settings }) => {
  return {
    userRole: userProfile.role,
    userId: userProfile.id,
    current: challenges.current,
    error: challenges.error,
    columns: settings.columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChallenge: (props) => {
      dispatch(fetchChallenge(props));
    },
    setCurrent: (props) => {
      dispatch(setCurrent(props));
    },
    setShowModal: (props) => {
      dispatch(setShowModal(props));
    },
    setId: (props) => {
      dispatch(setId(props));
    },
    setPosition: (props) => {
      dispatch(setPosition(props));
    },
  };
};

const SolutionFormWithRouter = withRouter(SolutionForm);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SolutionFormWithRouter);
