
import React from 'react'
import PropTypes from 'prop-types'
import styles from './Counter.module.css'
 
const Counter = ({ error = false, length = 0, limit = 0 }) => <div key="counter"
  className={[styles.counter, error ? styles.error : ''].join(' ')}
>
  {length} / {limit}
</div>

Counter.propTypes = {
  error: PropTypes.bool,
  length: PropTypes.number,
  limit: PropTypes.number,
}

export default Counter