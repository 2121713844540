/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import Logo from '../../features/logo/Logo'
import { Cookies, withCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import styles from './Logout.module.css'
import { connect } from 'react-redux'
import { setUser } from '../userProfile/userProfileSlice'
import helpers from '../../app/helpers'

class Logout extends Component {
  constructor(props) {
    super(props)

    props.setShowSidebar(false)
    props.setHeaderContent(null)
    const cookieNames = Object.keys(props.cookies.getAll())
    cookieNames.forEach(name => {
      props.cookies.remove(name)
      props.cookies.remove(name, { path: '/', domain: helpers.getCurrentDomain() })
    })
    props.resetUser()
    setTimeout(() => {
      props.setShowSidebar(true)
      props.setHeaderContent(props.headerContent)
      props.history.push('/')
    }, 4000)
  }

  render() {
    return <div className={styles.background}>
      <Modal size="tiny"
        defaultOpen={true}
        centered={true}
        closeOnDimmerClick={false}
        onClose={() => { this.props.history.push('/') }}
        className={styles.modal}>
        <Logo className={styles.logoAnimation}
          login
          size={100} />
        <p className={`${styles.description} ${styles.animation}`}>We’ve logged you out. See you soon!</p>
      </Modal>
    </div>
  }
}

Logout.propTypes = {
  history: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
  resetUser: PropTypes.func.isRequired,
  headerContent: PropTypes.array,
}

const mapDispatchToProps = dispatch => {
  return {
    resetUser: () => {
      dispatch(setUser())
    },
  }
}

export default connect(null, mapDispatchToProps)(withRouter(withCookies(Logout)))