import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  NavLink,
  // Link
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';
import helpers from './app/helpers';
import ScrollToTop from './app/scrollToTop';

import Logo from './features/logo/Logo';
import Notification from './features/notification/Notification';
import User from './features/user/User';

import { fetchCompanySettings } from './views/settingsView/settingsSlice';
import { fetchUserProfile } from './views/userProfile/userProfileSlice';
import styles from './App.module.css';
import AnimatedSwitch from './features/animatedSwitch/AnimatedSwitch';

function renderAdminList() {
  return (
    <ul className={styles.links}>
      <li>
        <NavLink
          to="/challenges"
          activeClassName={styles.selected}
          isActive={(match, location) => {
            if (location.pathname.includes('/challenge')) {
              return true;
            }

            return false;
          }}
        >
          Challenges
        </NavLink>
      </li>
      <li>
        <NavLink to="/solutions" activeClassName={styles.selected}>
          Solutions
        </NavLink>
      </li>
    </ul>
  );
}

function renderSuperAdminList() {
  return (
    <ul className={styles.links}>
      <li>
        <NavLink to="/superadmins" activeClassName={styles.selected}>
          SuperAdmins
        </NavLink>
      </li>
      <li>
        <NavLink to="/companies" activeClassName={styles.selected}>
          Companies
        </NavLink>
      </li>
    </ul>
  );
}

function App() {
  const userRole = useSelector(({ userProfile }) => userProfile.role);
  const userId = useSelector(({ userProfile }) => userProfile.id);
  const companyName = useSelector(({ settings }) => settings.name);
  const companyError = useSelector(({ settings }) => settings.error);
  const dispatch = useDispatch();
  const [cookies] = useCookies(['accessToken']);
  const [showSidebar, setShowSidebar] = useState(true);
  const [didCompanyRequest, setDidCompanyRequest] = useState(false);
  const [didProfileRequest, setDidProfileRequest] = useState(false);
  const [extendedSidebar, setExtendedSidebar] = useState(false);
  const superadmin = helpers.isSuperAdmin();
  const isNotUser = userRole !== 'ROLE_MEMBER';
  const defaultHeader = [
    <div key="left-header-side">
      <Logo className="headerTop" />
      <NavLink to={superadmin ? '/superadmins' : '/challenges'}>
        <h1>{helpers.getHeaderBaseOnLocation(window.location.pathname)}</h1>
      </NavLink>
    </div>,
    <div key="right-header-side">
      <Notification />
      <User />
    </div>,
  ];
  const defaultSecondHeader = null;

  const [headerContent, setHeaderContent] = useState(defaultHeader);
  const [secondHeaderContent, setSecondHeaderContent] = useState(
    defaultSecondHeader,
  );
  if (companyError) {
    return (
      <Segment className={styles.segment}>
        <Dimmer active inverted>
          {companyError.content}
        </Dimmer>
      </Segment>
    );
  }
  if (!didCompanyRequest && !superadmin && !companyName) {
    dispatch(fetchCompanySettings());
    setDidCompanyRequest(true);
  }
  if (cookies.accessToken && userId === -1) {
    if (!didProfileRequest) {
      dispatch(fetchUserProfile(superadmin));
      setDidProfileRequest(true);
    }
    return (
      <Segment className={styles.segment}>
        <Dimmer active inverted>
          <Loader size="small">Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <div
        onClick={(e) => {
          if (
            !e.target ||
            (e.target.className &&
              typeof e.target.className.indexOf === 'function' &&
              e.target.className.indexOf('App_sidebar_') > -1)
          ) {
            return;
          }
          if (extendedSidebar) {
            setExtendedSidebar(false);
          }
        }}
        className={[
          styles.wrapper,
          window.location.pathname.includes('/solution') &&
          !window.location.pathname.includes('/solutions')
            ? 'solutionContainer'
            : '',
          extendedSidebar ? styles.blockedWrapper : '',
        ].join(' ')}
      >
        <div
          className={[
            styles.sidebar,
            extendedSidebar ? styles.extendedSidebar : '',
            isNotUser && showSidebar ? styles.showSidebar : '',
          ].join(' ')}
        >
          {isNotUser && showSidebar && superadmin
            ? renderSuperAdminList()
            : renderAdminList()}
        </div>
        <div
          className={[
            styles.content,
            isNotUser && showSidebar && extendedSidebar
              ? styles.extendedSidebar
              : '',
            isNotUser && showSidebar && extendedSidebar
              ? styles.extendedSidebar
              : '',
            isNotUser ? styles.showSidebar : '',
            !isNotUser ? styles.userStyles : '',
          ].join(' ')}
        >
          <div className={styles.container}>
            {isNotUser && showSidebar && (
              <img
                src="/assets/icon-hamburger.svg"
                alt="hamburger"
                className={[
                  styles.hamburger,
                  extendedSidebar ? styles.extendedSidebar : '',
                ].join(' ')}
                onClick={() => {
                  setExtendedSidebar(!extendedSidebar);
                }}
              />
            )}
            {headerContent}
          </div>

          {secondHeaderContent ? (
            <div className={styles.container}>{secondHeaderContent}</div>
          ) : (
            ''
          )}

          <AnimatedSwitch
            setHeaderContent={setHeaderContent}
            setSecondHeaderContent={setSecondHeaderContent}
            headerContent={headerContent}
            setShowSidebar={setShowSidebar}
            cookies={cookies}
          />
          <div className={styles.footer}>
            {/* <img src="/assets/xlab-logo.svg"
              alt="Xlab logo" />
            <div className={styles.footerLinks}>
              <Link to='/privacy'>Privacy Policy</Link>
              <Link to='/terms'>Terms and conditions</Link>
            </div> */}
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
