import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Image,
  Button,
  Message,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import controlledFetch from '../../app/controlledFetch';
import { toast } from 'react-toastify';
import prettyBytes from 'pretty-bytes';
import styles from './InputUpload.module.css';
import { notificationOptions, MAX_UPLOAD_FILE_SIZE } from '../../app/config';
import Compress from 'compress.js';

class InputUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: props.imagePreviewUrl,
      error: null,
      loading: false,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.afterFetch = this.afterFetch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imagePreviewUrl !== this.props.imagePreviewUrl) {
      this.setState({ imagePreviewUrl: this.props.imagePreviewUrl });
    }
  }

  uploadImage(file) {
    if (!this.props.endpoint) {
      this.props.callback(file);
      return;
    }
    const data = new FormData();
    if (file) {
      this.setState({ loading: true });
      data.append(this.props.fieldName, file);
    }
    if (file === '' && this.props.removeEndpoint) {
      return controlledFetch(
        `${process.env.REACT_APP_API_URL}${this.props.removeEndpoint}`,
        {
          method: 'DELETE',
          body: null,
        },
      ).then(this.afterFetch);
    }
    controlledFetch(`${process.env.REACT_APP_API_URL}${this.props.endpoint}`, {
      method: file ? 'POST' : 'DELETE',
      body: file ? data : null,
    }).then((data) => this.afterFetch(data, file));
  }

  afterFetch(data, file) {
    if (data && data.content) {
      this.setState({
        error: data,
        imagePreviewUrl: '',
        file: '',
      });
    } else {
      if (typeof file !== 'undefined') {
        toast.success(
          `Image ${file ? 'uploaded' : 'deleted'}`,
          notificationOptions,
        );
      } else {
        toast.success(
          `Image ${data.data ? 'uploaded' : 'deleted'}`,
          notificationOptions,
        );
      }
    }
    this.setState({ loading: false });
    this.props.callback(data);
  }

  handleImageChange(e) {
    e.preventDefault();
    this.setState({ error: null });

    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (
          file.type !== 'image/png' &&
          file.type !== 'image/jpeg' &&
          file.type !== 'image/jpg'
        ) {
          const message = `Image bad type. Please upload png, jpg or jpeg.`;
          toast.success(message, notificationOptions);
          this.setState({
            error: { content: message },
            imagePreviewUrl: '',
            file: '',
          });
        } else if (file.size > MAX_UPLOAD_FILE_SIZE) {
          const message = `Image too big. Maximum size is ${prettyBytes(
            MAX_UPLOAD_FILE_SIZE,
          )}`;
          toast.success(message, notificationOptions);
          this.setState({
            error: { content: message },
            imagePreviewUrl: '',
            file: '',
          });
        } else {
          const compress = new Compress();
          compress
            .compress([file], {
              size: 100, // the max size in MB, defaults to 2MB
              quality: 1, // the quality of the image, max is 1,
              maxWidth: 1920, // the max width of the output image, defaults to 1920px
              maxHeight: 1080, // the max height of the output image, defaults to 1920px
              resize: true, // defaults to true, set false if you do not want to resize the image width and height
            })
            .then((data) => {
              const img = data[0];
              const base64str = img.data;
              const imgExt = img.ext;
              const resizedFile = Compress.convertBase64ToFile(
                base64str,
                imgExt,
              );

              const resizedReader = new FileReader();
              resizedReader.onloadend = () => {
                this.setState({
                  file: resizedFile,
                  imagePreviewUrl: resizedReader.result,
                });
                this.uploadImage(resizedFile);
              };
              resizedReader.readAsDataURL(resizedFile);
            });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(e) {
    e.preventDefault();
    this.setState({ file: '', imagePreviewUrl: '', error: null });
    this.uploadImage('');
  }

  render() {
    let { imagePreviewUrl, error, loading } = this.state;
    const { fieldName, backgroundImage } = this.props;

    return (
      <div
        className={`${styles.wrapper} ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <Form.Input
          className={styles.upload}
          id="upload"
          type="file"
          onChange={this.handleImageChange}
          accept=".jpg, .jpeg, .png"
        />
        <label
          htmlFor="upload"
          className={`${styles.label} ${
            imagePreviewUrl ? styles.uploaded : ''
          } ${fieldName === 'logoFile' ? styles.companyLogo : ''}`}
        >
          {!imagePreviewUrl && (
            <>
              <img
                alt="background"
                src={
                  backgroundImage ? backgroundImage : '/assets/icon-image.svg'
                }
              />
              <div className={styles.fileSizeInfo}>
                Supported formats: jpg, png. Size limit 10MB
              </div>
            </>
          )}
          <div className={styles.imageContainer}>
            <Button
              primary
              inverted
              size="small"
              onClick={this.uploadImage}
              className={`${styles.button} ${
                imagePreviewUrl ? styles.onImage : ''
              }`}
            >
              Click to upload
            </Button>
            {imagePreviewUrl && (
              <Image className={styles.preview} src={imagePreviewUrl} />
            )}
            {imagePreviewUrl && (
              <span className={styles.close} onClick={this.removeImage} />
            )}
            {loading && (
              <Dimmer active inverted>
                <Loader size="small">Loading</Loader>
              </Dimmer>
            )}
          </div>
        </label>
        {error && (
          <Message
            error
            content={error.content}
            className={styles.minimalError}
          />
        )}
      </div>
    );
  }
}

InputUpload.defaultProps = {
  imagePreviewUrl: '',
  endpoint: '',
  fieldName: null,
  className: '',
  callback: () => {},
};

InputUpload.propTypes = {
  imagePreviewUrl: PropTypes.string,
  backgroundImage: PropTypes.string,
  removeEndpoint: PropTypes.string,
  endpoint: PropTypes.string,
  className: PropTypes.string,
  fieldName: PropTypes.string,
  callback: PropTypes.func,
};

export default InputUpload;
