import React, { Component } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';
import styles from './Editor.module.css';
import challengeStyles from './../../views/challengeView/ChallengeView.module.css';
import { withRouter } from 'react-router-dom';
import controlledFetch from '../../app/controlledFetch';
import helpers from '../../app/helpers';

Quill.register('modules/imageUploader', ImageUploader);
class Editor extends Component {
  constructor(props) {
    super(props);
    const icons = Quill.import('ui/icons');
    icons['clean'] = '<span>clean styles</span>';
    icons[
      'blockquote'
    ] = `<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.21611 3.24616C7.21611 2.60616 7.45078 2.05149 7.92011 1.58216C8.38944 1.13416 8.98678 0.910156 9.71211 0.910156C10.5441 0.910156 11.2161 1.19816 11.7281 1.77416C12.2401 2.37149 12.4961 3.10749 12.4961 3.98216C12.4961 5.90216 11.9841 7.33149 10.9601 8.27016C9.93611 9.23016 8.79478 9.77416 7.53611 9.90216V7.95016C8.19744 7.84349 8.79478 7.54482 9.32811 7.05416C9.86144 6.58482 10.1388 5.99816 10.1601 5.29416C9.98944 5.40082 9.76544 5.45416 9.48811 5.45416C8.78411 5.45416 8.22944 5.25149 7.82411 4.84616C7.41878 4.46216 7.21611 3.92882 7.21611 3.24616ZM0.912109 3.24616C0.912109 2.60616 1.14678 2.05149 1.61611 1.58216C2.08544 1.13416 2.68278 0.910157 3.40811 0.910157C4.24011 0.910157 4.91211 1.19816 5.42411 1.77416C5.93611 2.37149 6.19211 3.10749 6.19211 3.98216C6.19211 5.90216 5.68011 7.33149 4.65611 8.27016C3.63211 9.23016 2.49078 9.77416 1.23211 9.90216L1.23211 7.95016C1.89344 7.84349 2.49078 7.54482 3.02411 7.05416C3.55744 6.58482 3.83478 5.99816 3.85611 5.29416C3.68544 5.40082 3.46144 5.45416 3.18411 5.45416C2.48011 5.45416 1.92544 5.25149 1.52011 4.84616C1.11478 4.46216 0.912109 3.92882 0.912109 3.24616Z" fill="black"/>
    </svg>`;
    this.modules = {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        ['clean'],
      ],
      imageUploader: {
        upload: this.upload.bind(this),
      },
    };
    this.formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'imageBlot',
    ];
    this.state = {
      content: helpers.replaceIdByUrl(props.content, props.gallery) || '',
    };
    this.scrollTop = 0;
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    document.querySelector('.ql-container.ql-snow').onscroll = (e) => {
      if (this.scrollTop > 150 && e.srcElement.scrollTop < 3) {
        return;
      }
      this.scrollTop = e.srcElement.scrollTop;
    };
  }

  componentWillUnmount() {
    document.querySelector('.ql-container.ql-snow').onscroll = () => {};
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.content && !equal(this.props.content, prevProps.content)) ||
      !equal(this.props.gallery, prevProps.gallery)
    ) {
      this.setState({
        content: helpers.replaceIdByUrl(this.props.content, this.props.gallery),
      });
    }
  }

  upload(file) {
    const { setError, match, callback, id } = this.props;
    return new Promise((resolve, reject) => {
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        setError('Bad file type');
        return reject();
      }
      if (sizeInMB > 10) {
        setError('Image is too big. We accept images under 10MB');
        return reject();
      }

      const formData = new FormData();
      formData.append('imageFile', file);
      return controlledFetch(
        `${process.env.REACT_APP_API_URL}/admin/challenges/${
          id || match.params.id
        }/gallery`,
        {
          method: 'POST',
          body: formData,
        },
      )
        .then(async () => {
          const gallery = await callback();
          return resolve(`;;${gallery[0].imageId};;`);
        })
        .catch((error) => {
          return reject('Upload failed');
        });
    });
  }

  onChange(val) {
    const { setContent, gallery } = this.props;
    this.setState(
      {
        content: helpers.replaceIdByUrl(val, gallery),
      },
      () => {
        setContent(helpers.replaceUrlById(val, gallery));
      },
    );
  }

  onBlur(range, source, editor) {
    setTimeout(() => {
      const fixRange = editor.getSelection();
      if (fixRange) {
        const container = document.querySelector('.ql-container.ql-snow');
        container.style.opacity = 0;
        setTimeout(() => {
          container.scrollTop = this.scrollTop;
          container.style.opacity = 1;
        }, 100);
      }
    }, 100);
  }

  render() {
    const { content } = this.state;

    return (
      <ReactQuill
        theme="snow"
        className={[styles.quill, challengeStyles.content].join(' ')}
        value={content}
        formats={this.formats}
        modules={this.modules}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }
}

Editor.propTypes = {
  setContent: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  id: PropTypes.number,
  content: PropTypes.string,
  match: PropTypes.object.isRequired,
};

export default withRouter(Editor);
