import { createSlice } from '@reduxjs/toolkit'
import controlledFetch from '../../app/controlledFetch'

export const companies = createSlice({
  name: 'companies',
  initialState: {
    list: [],
    total: 0
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
  },
})

export const { setList, setTotal } = companies.actions

export const companiesList = state => state.companies

export const fetchCompaniesList = () => dispatch => {
  controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/companies`, {
    method: 'GET'
  }).then(data => {
    if (data.data) {
      dispatch(setList(data.data))
      dispatch(setTotal(data.info.total))
    }
  })
}


export default companies.reducer
