import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { showConfirmationModal, setShowModal } from './contextMenuSlice';
import styles from './ContextMenu.module.css';
import controlledFetch from '../../app/controlledFetch';
import ConfirmationPopup from '../confirmationPopup/ConfirmationPopup';
import { toast } from 'react-toastify';
import { notificationOptions } from '../../app/config';
import helpers from '../../app/helpers';

ReactModal.setAppElement('#root');

class ContextMenu extends Component {
  constructor() {
    super();
    this.modalWidth = 184;

    this.state = {
      header: '',
      content: <span />,
      actionText: '',
      confirmedAction: () => {},
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.actions = this.actions.bind(this);
  }

  actions() {
    const {
      pathPrefix,
      history,
      storedId,
      activeUsers,
      fullName,
      title,
      callback,
      showConfirmationModal,
      enabled,
      status,
      match,
      email,
    } = this.props;
    const actions = [];
    switch (pathPrefix) {
      case 'superadmin':
        actions.push({
          name: 'Edit user',
          onClick: () => {
            history.push(`/superadmin/edit/${storedId}`);
          },
        });
        if (email && status === 'active') {
          actions.push({
            name: 'Reset password',
            onClick: () => {
              controlledFetch(
                `${process.env.REACT_APP_API_URL}/reset_password/superadmin`,
                {
                  method: 'POST',
                  body: JSON.stringify({
                    email,
                    companyId: storedId,
                  }),
                },
              ).then((data) => {
                if (data.status === 'OK') {
                  toast.success(
                    `Password reset link sent to ${email}.`,
                    notificationOptions,
                  );
                }
              });
            },
          });
        }
        actions.push({
          name: 'Delete',
          className: styles.red,
          onClick: () => {
            this.setState({
              header: 'Delete SuperAdmin',
              content: (
                <span>
                  Are you sure you want to delete member{' '}
                  <strong>{fullName}</strong> ({email})?
                </span>
              ),
              actionText: 'Delete',
              confirmedAction: () => {
                controlledFetch(
                  `${process.env.REACT_APP_API_URL}/superadmin/superadmins/${storedId}`,
                  {
                    method: 'DELETE',
                  },
                ).then((data) => {
                  if (data.status === 'OK') {
                    callback(data);
                    showConfirmationModal(false);
                    toast.success(
                      <span>
                        Member removed <strong>{email}</strong>
                      </span>,
                      notificationOptions,
                    );
                  }
                });
              },
            });
            showConfirmationModal(true);
          },
        });
        break;
      case 'company':
        actions.push({
          name: 'Edit',
          onClick: () => {
            history.push(`/company/edit/${storedId}`);
          },
        });
        if (activeUsers > 0) {
          if (enabled === false) {
            actions.push({
              name: 'Enable',
              className: styles.primary,
              onClick: () => {
                this.setState({
                  header: 'Enable company',
                  content: (
                    <span>
                      Are you sure you want to enable company{' '}
                      <strong>{fullName}</strong>?
                    </span>
                  ),
                  actionText: 'Enable',
                  className: 'primary',
                  confirmedAction: () => {
                    controlledFetch(
                      `${process.env.REACT_APP_API_URL}/superadmin/companies/${storedId}/enable`,
                      {
                        method: 'PUT',
                      },
                    ).then((data) => {
                      if (data.status === 'OK') {
                        callback(data);
                        showConfirmationModal(false);
                        toast.success(
                          <span>
                            Company enabled <strong>{fullName}</strong>
                          </span>,
                          notificationOptions,
                        );
                      }
                    });
                  },
                });
                showConfirmationModal(true);
              },
            });
          } else {
            actions.push({
              name: 'Disable',
              className: styles.red,
              onClick: () => {
                this.setState({
                  header: 'Disable company',
                  content: (
                    <span>
                      Are you sure you want to disable company{' '}
                      <strong>{fullName}</strong>?
                    </span>
                  ),
                  actionText: 'Disable',
                  className: 'red',
                  confirmedAction: () => {
                    controlledFetch(
                      `${process.env.REACT_APP_API_URL}/superadmin/companies/${storedId}/disable`,
                      {
                        method: 'PUT',
                      },
                    ).then((data) => {
                      if (data.status === 'OK') {
                        callback(data);
                        showConfirmationModal(false);
                        toast.success(
                          <span>
                            Company disabled <strong>{fullName}</strong>
                          </span>,
                          notificationOptions,
                        );
                      }
                    });
                  },
                });
                this.props.showConfirmationModal(true);
              },
            });
          }
        }
        if (activeUsers === 0) {
          actions.push({
            name: 'Delete',
            className: styles.red,
            onClick: () => {
              this.setState({
                header: 'Delete company',
                content: (
                  <span>
                    Are you sure you want to delete company{' '}
                    <strong>{fullName}</strong>? This action can’t be undone.
                  </span>
                ),
                actionText: 'Delete',
                confirmedAction: () => {
                  controlledFetch(
                    `${process.env.REACT_APP_API_URL}/superadmin/companies/${storedId}`,
                    {
                      method: 'DELETE',
                    },
                  ).then((data) => {
                    if (data.status === 'OK') {
                      callback(data);
                      showConfirmationModal(false);
                      toast.success(
                        <span>
                          Member removed <strong>{fullName}</strong>
                        </span>,
                        notificationOptions,
                      );
                    }
                  });
                },
              });
              showConfirmationModal(true);
            },
          });
        }

        break;
      case 'challenge':
        actions.push({
          name: 'Edit basic information',
          onClick: () => {
            history.push(`/challenge/edit/${storedId}/1`);
          },
        });
        actions.push({
          name: 'Edit description',
          onClick: () => {
            history.push(`/challenge/edit/${storedId}/2`);
          },
        });
        actions.push({
          name: 'Edit questionnaire',
          onClick: () => {
            history.push(`/challenge/edit/${storedId}/3`);
          },
        });
        actions.push({
          name: 'Solutions',
          onClick: () => {
            history.push(`/solutions/${storedId}`);
          },
        });
        if (this.props.status === 'PUBLISHED') {
          actions.push({
            name: 'Unpublish',
            onClick: () => {
              controlledFetch(
                `${process.env.REACT_APP_API_URL}/admin/challenges/${storedId}`,
                {
                  method: 'PUT',
                  body: JSON.stringify({ status: 'DRAFT' }),
                },
              ).then((data) => {
                if (data.status === 'OK') {
                  callback(data);
                  toast.success(
                    <span>Challenge unpublished</span>,
                    notificationOptions,
                  );
                }
              });
            },
          });
        }
        if (this.props.status !== 'PUBLISHED') {
          actions.push({
            name: 'Publish',
            onClick: () => {
              controlledFetch(
                `${process.env.REACT_APP_API_URL}/admin/challenges/${storedId}`,
                {
                  method: 'PUT',
                  body: JSON.stringify({ status: 'PUBLISHED' }),
                },
              ).then((data) => {
                if (data.status === 'OK') {
                  callback(data);
                  toast.success(
                    <span>Challenge published</span>,
                    notificationOptions,
                  );
                }
              });
            },
          });
          actions.push({
            name: 'Delete',
            className: styles.red,
            onClick: () => {
              this.setState({
                header: 'Delete challenge',
                content: (
                  <span>
                    Are you sure you want to delete challenge{' '}
                    <strong>{title}</strong> ?
                  </span>
                ),
                actionText: 'Delete',
                confirmedAction: () => {
                  controlledFetch(
                    `${process.env.REACT_APP_API_URL}/admin/challenges/${storedId}`,
                    {
                      method: 'DELETE',
                    },
                  ).then((data) => {
                    if (data.status === 'OK') {
                      callback(data);
                      showConfirmationModal(false);
                      toast.success(
                        <span>Challenge deleted</span>,
                        notificationOptions,
                      );
                    }
                  });
                },
              });
              showConfirmationModal(true);
            },
          });
        }
        break;
      case 'solution':
        actions.push({
          name: 'Edit answers',
          onClick: () => {
            history.push(
              `/challenge/view/${match.params.challengeId}/solution/${storedId}/edit`,
            );
          },
        });
        break;
      case 'solution_admin':
        actions.push({
          name: 'Edit answers',
          onClick: () => {
            history.push(
              `/challenge/view/${match.params.challengeId}/solution/${storedId}/edit`,
            );
          },
        });
        actions.push({
          name: 'Edit questions',
          onClick: () => {
            history.push(`/challenge/edit/${match.params.challengeId}/3`);
          },
        });
        actions.push({
          name: 'Delete',
          onClick: () => {
            controlledFetch(
              `${process.env.REACT_APP_API_URL}/admin/solutions/${storedId}`,
              {
                method: 'DELETE',
              },
            ).then((data) => {
              if (data.status && data.status === 'OK') {
                toast.success(
                  '🙌 Well done! Solution successfully deleted.',
                  notificationOptions,
                );
                this.props.history.goBack();
              } else {
                this.setState({
                  error: data,
                });
              }
            });
          },
        });
        break;
      default:
        actions.push({
          name: 'Edit user',
          onClick: () => {
            history.push(`/user/edit/${storedId}`);
          },
        });
        if (email && status === 'active') {
          actions.push({
            name: 'Reset password',
            onClick: () => {
              controlledFetch(
                `${process.env.REACT_APP_API_URL}/reset_password/user`,
                {
                  method: 'POST',
                  body: JSON.stringify({
                    email,
                    companyId: helpers.getCompanyId(),
                  }),
                },
              ).then((data) => {
                if (data.status === 'OK') {
                  toast.success(
                    `Password reset link sent to ${email}.`,
                    notificationOptions,
                  );
                }
              });
            },
          });
        } else if (email && status === 'invited') {
          actions.push({
            name: 'Resend invitation',
            onClick: () => {
              controlledFetch(
                `${process.env.REACT_APP_API_URL}/admin/users/${storedId}/reinvite`,
                {
                  method: 'PUT',
                },
              ).then((data) => {
                if (data.status === 'OK') {
                  toast.success(
                    '👍 Email invitation sent.',
                    notificationOptions,
                  );
                }
              });
            },
          });
        }
        actions.push({
          name: 'Delete',
          className: styles.red,
          onClick: () => {
            this.setState({
              header: 'Delete member',
              content: (
                <span>
                  Are you sure you want to delete member{' '}
                  <strong>{fullName}</strong> ({email})?
                </span>
              ),
              actionText: 'Delete',
              confirmedAction: () => {
                controlledFetch(
                  `${process.env.REACT_APP_API_URL}/admin/users/${storedId}`,
                  {
                    method: 'DELETE',
                  },
                ).then((data) => {
                  if (data.status === 'OK') {
                    callback(data);
                    showConfirmationModal(false);
                    toast.success(
                      <span>
                        Member removed <strong>{email}</strong>
                      </span>,
                      notificationOptions,
                    );
                  }
                });
              },
            });
            showConfirmationModal(true);
          },
        });
        break;
    }

    return actions;
  }

  handleOpenModal() {
    this.props.setShowModal(true);
  }

  handleCloseModal() {
    this.props.setShowModal(false);
  }

  render() {
    return (
      <div>
        <ReactModal
          closeTimeoutMS={200}
          className={styles.contextMenu}
          isOpen={this.props.showModal}
          contentLabel="Context menu"
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.handleCloseModal}
          style={{
            overlay: {
              background: 'none',
              position: 'absolute',
            },
            content: {
              width: this.modalWidth,
              left: this.props.position[0] - this.modalWidth,
              top: this.props.position[1],
            },
          }}
        >
          {this.actions().map((action) => (
            <div
              key={action.name}
              className={action.className}
              onClick={() => {
                action.onClick();
                this.handleCloseModal();
              }}
            >
              {action.name}
            </div>
          ))}
        </ReactModal>
        <ConfirmationPopup
          {...this.state}
          open={this.props.confirmationModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contextMenu }) => {
  return {
    showModal: contextMenu.showModal,
    confirmationModal: contextMenu.confirmationModal,
    storedId: contextMenu.id,
    status: contextMenu.status,
    title: contextMenu.title,
    fullName: contextMenu.fullName,
    email: contextMenu.email,
    enabled: contextMenu.enabled,
    activeUsers: contextMenu.activeUsers,
    position: contextMenu.position,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowModal: (showModal) => {
      dispatch(setShowModal(showModal));
    },
    showConfirmationModal: (confirmationModal) => {
      dispatch(showConfirmationModal(confirmationModal));
    },
  };
};

ContextMenu.defaultProps = {
  callback: () => {},
};

ContextMenu.propTypes = {
  showConfirmationModal: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  callback: PropTypes.func,
  email: PropTypes.string,
  fullName: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  activeUsers: PropTypes.number,
  enabled: PropTypes.bool,
  confirmationModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  storedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  position: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const ContextMenuWithRouter = withRouter(ContextMenu);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuWithRouter);
