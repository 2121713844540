import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Form, Icon, Image, Popup, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { fetchSuperAdminsList } from '../../views/superAdminsList/superAdminsSlice'
import styles from './SuperAdminEditContent.module.css'
import Button from '../button/Button'
import controlledFetch from '../../app/controlledFetch'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { notificationOptions } from '../../app/config'
import helpers from '../../app/helpers'
 
class SuperAdminEditContent extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      anyChangeInForm: false,
      error: null,
      isPopupOpen: false,
      copied: false
    }
    
    this.fetchUser = this.fetchUser.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)

    this.fetchUser(props)
  }

  fetchUser(props = this.props) {
    controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/superadmins/${props.match.params.id}`, {
      method: 'GET',
    }).then(data => {
      if (typeof data === 'object') {
        this.setState(data)
      }
    })
  }
  
  submitForm() { 
    const { firstName, lastName } = this.state
    controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/superadmins/${this.props.match.params.id}`, {
      method: 'PUT',
      body: JSON.stringify({ firstName, lastName, role: 'ROLE_SUPERADMIN' }),
    }).then(data => {
      if (data.status && data.status === "OK") {
        this.props.fetchSuperAdminsList()
        this.props.history.goBack()
        toast.success('🙌 Well done! All changes have been successfully updated.', notificationOptions)
      } else {
        this.setState({
          error: data
        })
      }
    })
  }

  handleOpen() {
    this.setState({ copied: true, isPopupOpen: false }, () => {
      this.setState({ isPopupOpen: true })
    })
    this.timeout = setTimeout(() => {
      this.setState({ copied: false, isPopupOpen: false })
    }, 1000)
  }

  handleClose() {
    this.setState({ copied: false })
    clearTimeout(this.timeout)
  }

  render() {
    const { error, copied, firstName } = this.state
    return [
      <Modal.Header key="header">Edit SuperAdmin</Modal.Header>,
      <Modal.Content key="content">
        <Modal.Description>
          <p>Manage SuperAdmin details. E-mail can’t be changed.</p>
          {!firstName 
            ? <Segment>
              <Dimmer active
                inverted>
                <Loader inverted
                  size="large">Loading</Loader>
              </Dimmer>
              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
            </Segment>
            :<Form error={!!error}>
              <Form.Group widths='equal'>
                <Form.Input 
                  fluid 
                  label='First Name' 
                  size="large"
                  placeholder='First Name' 
                  error={error && error.errorFields && error.errorFields.firstName ? error : null}
                  value={this.state.firstName}
                  onChange={(classObj, data) => {
                    this.setState({ error: null, firstName: data.value.trim(), anyChangeInForm: true })
                  }}
                />
                <Form.Input 
                  fluid 
                  label='Last name' 
                  size="large"
                  placeholder='Last name' 
                  value={this.state.lastName} 
                  error={error && error.errorFields && error.errorFields.lastName ? error : null}
                  onChange={(classObj, data) => {
                    this.setState({ error: null, lastName: data.value.trim(), anyChangeInForm: true })
                  }} 
                />
              </Form.Group>
              <Form.Input 
                label='E-mail' 
                placeholder='E-mail' 
                size="large"
                disabled={true}
                className={styles.disabledInputEmail}
                icon={<Popup 
                  position='top center' 
                  on='hover'
                  onOpen={() => { this.setState({ isPopupOpen: true }) }}
                  onClose={() => { this.setState({ isPopupOpen: false }) }}
                  content={copied ? 'Copied!' : 'Copy to the clipboard'}
                  open={this.state.isPopupOpen}
                  trigger={
                    <CopyToClipboard onCopy={this.handleOpen}
                      text={this.state.email}>
                      <Icon link >
                        <Image src='/assets/icon-copy.svg'
                          alt="copy"/>
                      </Icon>
                    </CopyToClipboard>
                  }
                />}
                value={this.state.email} 
              />
            </Form>}
        </Modal.Description>
      </Modal.Content>,
      <Modal.Actions key="actions">
        <Button secondary={true}
          text="Cancel"
          onClick={() => { helpers.goBack(this.props.history) }} />
        <Button text="Update"
          primary={true}
          onClick={this.submitForm}
          disabled={!this.state.anyChangeInForm} />
      </Modal.Actions>
    ]
  }
}

SuperAdminEditContent.propTypes = {
  fetchSuperAdminsList: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSuperAdminsList: () => {
      dispatch(fetchSuperAdminsList())
    },
  }
}

const SuperAdminEditContentWithRouter = withRouter(SuperAdminEditContent)
export default connect(null, mapDispatchToProps)(SuperAdminEditContentWithRouter)