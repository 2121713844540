import { createSlice } from '@reduxjs/toolkit'

export const contextMenu = createSlice({
  name: 'contextMenu',
  initialState: {
    showModal: false,
    confirmationModal: false,
    enabled: undefined,
    id: -1,
    email: '',
    status: '',
    fullName: '',
    activeUsers: -1,
    title: '',
    position: [0, 0]
  },
  reducers: {
    showConfirmationModal: (state, action) => {
      state.confirmationModal = action.payload
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload
    },
    setId: (state, action) => {
      state.id = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setPosition: (state, action) => {
      state.position = action.payload
    },
    setFullName: (state, action) => {
      state.fullName = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setEnabled: (state, action) => {
      state.enabled = action.payload
    },
    setActiveUsers: (state, action) => {
      state.activeUsers = action.payload
    },
  },
})

export const { setActiveUsers, setEnabled, showConfirmationModal, setShowModal, setId, setEmail, setPosition, setStatus, setFullName, setTitle } = contextMenu.actions

export const id = state => state.id
export const email = state => state.email
export const showModal = state => state.showModal
export const status = state => state.status
export const position = state => state.position
export const fullName = state => state.fullName
export const title = state => state.title
export const enabled = state => state.enabled
export const activeUsers = state => state.activeUsers

export default contextMenu.reducer
