import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionShort.module.css';
import { Form, Input, Message } from 'semantic-ui-react';
import Counter from '../counter/Counter';
import { connect, useSelector, useDispatch } from 'react-redux';
import { setCurrent } from '../../views/challengesList/challengesSlice';

const QuestionShort = ({
  id,
  question,
  answer,
  isEdit,
  saveAnswer,
  error,
  placeholder,
  errorMsg,
}) => {
  const dispatch = useDispatch();
  const current = useSelector(({ challenges }) => challenges.current);
  const [value, setValue] = useState(answer);
  const [dirty, setDirty] = useState(false);
  const limit = 150;
  const TooManyCharsError = value.length === limit;
  const isEmpty = (dirty || error) && value.length === 0;
  return (
    <div id={id}>
      <p className={styles.question}>{question}</p>
      {isEdit ? (
        <Form error={isEmpty} className={styles.answerContainer}>
          <Counter
            limit={limit}
            length={value.length}
            error={TooManyCharsError}
          />
          <Input
            className={[styles.shortInput, isEmpty ? styles.error : ''].join(
              ' ',
            )}
            onChange={(obj, data) => {
              if (data.value.length > limit) {
                setValue(data.value.slice(0, limit));
                return;
              }
              setValue(data.value);
              saveAnswer(data.value);
              setDirty(true);
              dispatch(
                setCurrent({
                  ...current,
                  questionnaire: [...current.questionnaire],
                }),
              );
            }}
            placeholder={placeholder}
            value={value}
          />
          {(isEmpty || error) && (
            <Message
              error
              content={error || errorMsg}
              className={styles.minimalError}
            />
          )}
        </Form>
      ) : (
        <p className={styles.answer}>{answer}</p>
      )}
    </div>
  );
};

QuestionShort.defaultProps = {
  question: '',
  answer: '',
  isEdit: true,
  placeholder: 'Type in short answer',
  errorMsg: 'Answer can’t be empty',
};

QuestionShort.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
  isEdit: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrent: (props) => {
      dispatch(setCurrent(props));
    },
  };
};

export default connect(null, mapDispatchToProps)(QuestionShort);
