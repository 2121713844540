import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { ToastContainer } from 'react-toastify'
import App from './App'
import store from './app/store'
import * as serviceWorker from './serviceWorker'
import 'semantic-ui-less/semantic.less'
import "react-datepicker/dist/react-datepicker.css"
import 'react-toastify/dist/ReactToastify.css'
import './index.css'

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
