import React  from 'react'
import PropTypes from 'prop-types'
import styles from './SolutionComment.module.css'
import IconUser from '../iconUser/IconUser'

const SolutionComment = ({ firstName, lastName, authorAvatar='', content, highlight=false, date='1/01/2020 16:20' }) => {
  return <div className={styles.comment}>
    <div className={[styles.commentContainer, highlight ? styles.highlight : ''].join(' ')}>
      <p>{content}</p>
    </div>
    <div className={styles.commentMeta}>
      <div className={styles.commentAuthor}>
        {authorAvatar ?
          (<img alt="avatar"
            src={authorAvatar}
            className={styles.avatar} />) :
          (<IconUser firstName={firstName}
            lastName={lastName}
            className={styles.avatar} />)}
        <h4>{firstName + ' ' + lastName}</h4>
      </div>
      <span className={styles.commentDate}>{date}</span>
    </div>
  </div>
}

SolutionComment.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  authorAvatar: PropTypes.string,
  content: PropTypes.string,
  highlight: PropTypes.bool,
  date: PropTypes.string
}

export default SolutionComment