import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './QuestionTime.module.css';
import { Form, Radio, Message } from 'semantic-ui-react';

const QuestionTime = ({
  anchor,
  answer = '',
  question = '',
  possibleAnswers = [],
  saveAnswer,
  isEdit = true,
  error,
}) => {
  const [localAnswer, setLocalAnswer] = useState(answer);
  const isEmpty = error && localAnswer.length === 0;
  return (
    <div id={anchor}>
      <p className={styles.question}>{question}</p>
      {isEdit ? (
        <div className={styles.answerContainer}>
          <Form error={isEmpty}>
            {possibleAnswers.map((possibleAnswer) => (
              <Form.Field key={possibleAnswer.id}>
                <Radio
                  className={[styles.radio, isEmpty ? styles.error : ''].join(
                    ' ',
                  )}
                  label={
                    possibleAnswer.label ||
                    moment(possibleAnswer.value).format('h:mm A')
                  }
                  name="radioGroup"
                  size="large"
                  placeholder="Type in text for a radio"
                  value={possibleAnswer.value}
                  checked={localAnswer === possibleAnswer.value}
                  onChange={(e, { value }) => {
                    setLocalAnswer(value);
                    saveAnswer(value);
                  }}
                />
              </Form.Field>
            ))}
          </Form>
          {(isEmpty || error) && (
            <Message
              error
              content={error || 'Answer can’t be empty'}
              className={styles.minimalError}
            />
          )}
        </div>
      ) : (
        <p className={styles.answer}>{answer}</p>
      )}
    </div>
  );
};

QuestionTime.propTypes = {
  anchor: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.string,
  possibleAnswers: PropTypes.array,
  type: PropTypes.string,
  id: PropTypes.number,
  saveAnswer: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default QuestionTime;
