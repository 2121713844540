import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import equal from 'fast-deep-equal'
import { Segment, Dimmer, Loader, Image } from 'semantic-ui-react'
import { fetchCompaniesList } from './companiesSlice'
import Table from '../../features/table/Table'
import ContextMenu from '../../features/contextMenu/ContextMenu'

class CompaniesList extends Component {
  constructor(props) {
    super(props)

    props.setShowSidebar(true)
    props.setHeaderContent(props.defaultHeader)
    if (props.list.length === 0) {
      props.fetchCompaniesList()
    }

    this.state = {
      loading: props.list.length === 0,
    }
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.list, prevProps.list)) {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    const { list, total } = this.props
    if (!list || total === 0) {
      return <Segment>
        <Dimmer active
          inverted>
          <Loader inverted
            size="large">Loading</Loader>
        </Dimmer>
        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
      </Segment>
    }
    return (
      <div className="container">
        <Table 
          editPath="/company/edit/:id"
          buttonToAction="/company/add" 
          buttonAddUserText="Add Company" 
          rows={list} 
          loading={this.state.loading} 
          searchInput={true}
        />
        <ContextMenu callback={this.props.fetchCompaniesList}
          pathPrefix="company" />
      </div>
    )
  }
}

const mapStateToProps = ({ companies }) => {
  return {
    list: companies.list,
    total: companies.total,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCompaniesList: () => {
      dispatch(fetchCompaniesList())
    },
  }
}

CompaniesList.propTypes = {
  list: PropTypes.array.isRequired,
  total: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  defaultHeader: PropTypes.array,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
  fetchCompaniesList: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList)