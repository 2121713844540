import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';
import styles from './User.module.css';
import IconUser from '../iconUser/IconUser';
import { useCookies } from 'react-cookie';

const User = () => {
  const email = useSelector(({ userProfile }) => userProfile.email);
  const fullName = useSelector(({ userProfile }) => userProfile.fullName);
  const firstName = useSelector(({ userProfile }) => userProfile.firstName);
  const lastName = useSelector(({ userProfile }) => userProfile.lastName);
  const avatarUrl = useSelector(({ userProfile }) => userProfile.avatarUrl);
  const cookieUrl = useSelector(({ userProfile }) => userProfile.cookieUrl);
  const [cookies] = useCookies(['accessToken']);

  return (
    <NavLink to="/profile">
      <div className={styles.user}>
        {avatarUrl ? (
          <Image src={avatarUrl} />
        ) : (
          <IconUser
            firstName={firstName}
            lastName={lastName}
            className={styles.iconUser}
          />
        )}
        <span>{fullName || email}</span>
      </div>
      <img
        src={`${cookieUrl}?bearer=${cookies['accessToken']}`}
        style={{ display: 'none' }}
      />
    </NavLink>
  );
};

export default User;
