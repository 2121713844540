import { createSlice } from '@reduxjs/toolkit';
import controlledFetch from '../../app/controlledFetch';
import { extendErrorObject } from '../../app/errorHandler';
import helpers from '../../app/helpers';
const tinycolor = require('tinycolor2');

export const settings = createSlice({
  name: 'settings',
  initialState: {
    canRegister: false,
    canLoginWithDefaultMethod: false,
    canLoginWithOkta: false,
    canLoginWithLinkedIn: false,
    logoUrl: null,
    name: '',
    primaryColor: '',
    domains: [],
    columns: [
      { id: 460000073, color: '#FFC700', title: 'Evaluation' },
      { id: 580000135, color: '#00BC29', title: 'Experimentation' },
      { id: 60000621, color: '#FF4949', title: 'Solution Parking Lot' },
    ],
    stats: null,
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPrimaryColor: (state, action) => {
      // state.primaryColor = '#005dc7';
      // const color = tinycolor('#005dc7');
      // document.documentElement.style.setProperty('--primary', '#005dc7');
      // document.documentElement.style.setProperty(
      //   '--primaryHover',
      //   color.lighten().toString(),
      // );
      // document.documentElement.style.setProperty(
      //   '--primaryDarken',
      //   color.darken(20).toString(),
      // );
      // document.documentElement.style.setProperty(
      //   '--primaryLighten',
      //   `${action.payload}10`,
      // );
    },
    setColumns: (state, action) => {
      if (action.payload.length > 0) {
        state.columns = action.payload;
      }
      state.columns.forEach((col, index) => {
        document.documentElement.style.setProperty(
          `--column-${index}`,
          col.color,
        );
      });
    },
    setDomains: (state, action) => {
      state.domains = action.payload;
    },
    setState: (state, action) => {
      state.domains = action.payload.domains;
      state.logoUrl = action.payload.logoUrl;
      state.name = action.payload.name;
      state.primaryColor = action.payload.primaryColor;
      state.stats = action.payload.stats;
      if (
        typeof action.payload.columns !== 'undefined' &&
        action.payload.columns.length > 0
      ) {
        state.columns = action.payload.columns;
      }
    },
    setCompanySettings: (state, action) => {
      state.canRegister = action.payload.canRegister || state.canRegister;
      state.canLoginWithDefaultMethod =
        action.payload.canLoginWithDefaultMethod ||
        state.canLoginWithDefaultMethod;
      state.canLoginWithOkta =
        action.payload.canLoginWithOkta || state.canLoginWithOkta;
      state.canLoginWithLinkedIn =
        action.payload.canLoginWithLinkedIn || state.canLoginWithLinkedIn;
      state.logoUrl = action.payload.logoUrl;
      state.name = action.payload.name;
      state.primaryColor = action.payload.primaryColor;
      if (
        typeof action.payload.columns !== 'undefined' &&
        action.payload.columns.length > 0
      ) {
        state.columns = action.payload.columns;
      }
      state.columns.forEach((col, index) => {
        document.documentElement.style.setProperty(
          `--column-${index}`,
          col.color,
        );
      });
    },
  },
});

export const {
  setCompanySettings,
  setState,
  setName,
  setPrimaryColor,
  setColumns,
  setDomains,
  setError,
} = settings.actions;

export const canRegister = (state) => state.canRegister;
export const canLoginWithDefaultMethod = (state) =>
  state.canLoginWithDefaultMethod;
export const canLoginWithOkta = (state) => state.canLoginWithOkta;
export const canLoginWithLinkedIn = (state) => state.canLoginWithLinkedIn;
export const logoUrl = (state) => state.logoUrl;
export const name = (state) => state.name;
export const primaryColor = (state) => state.primaryColor;
export const domains = (state) => state.domainss;
export const stats = (state) => state.stats;
export const columns = (state) => state.columns;

export const fetchSettings = () => (dispatch) => {
  controlledFetch(`${process.env.REACT_APP_API_URL}/admin/company`, {
    method: 'GET',
  }).then((data) => {
    if (data) {
      dispatch(setState(data));
    }
  });
};

export const fetchCompanySettings = () => (dispatch) => {
  controlledFetch(
    `${process.env.REACT_APP_API_URL}/companies/${helpers.getCompanyId()}`,
    {
      method: 'GET',
    },
  ).then((data) => {
    if (data.status === 'ERROR') {
      dispatch(setError(extendErrorObject(data)));
      return;
    }
    dispatch(setCompanySettings(data));
    const color = tinycolor(data.primaryColor);
    document.documentElement.style.setProperty('--primary', data.primaryColor);
    document.documentElement.style.setProperty(
      '--primaryHover',
      color.lighten().toString(),
    );
    document.documentElement.style.setProperty(
      '--primaryDarken',
      color.darken(20).toString(),
    );
    document.documentElement.style.setProperty(
      '--primaryLighten',
      `${data.primaryColor}10`,
    );
    if (data.columns && data.columns.length > 0) {
      data.columns.forEach((column, index) => {
        document.documentElement.style.setProperty(
          `--column-${index}`,
          column.color,
        );
      });
    }
  });
};

export default settings.reducer;
