import { REQUEST_TIMEOUT, notificationOptions } from './config';
import { extendErrorObject } from './errorHandler';
import helpers from './helpers';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';

export default function controlledFetch(url = '', params = {}) {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  const controller = new AbortController();
  const superadmin = helpers.isSuperAdmin();
  setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

  let headers = params.headers ? params.headers : {};
  if (accessToken && url.indexOf('/refresh') === -1) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const req = (counter = 0) =>
    counter > 2
      ? null
      : fetch(url, {
          headers,
          signal: controller.signal,
          ...params,
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            return {
              status: response.status,
              ...(await response.json()),
            };
          })
          .then((data) => {
            if (
              (data.code === 401 || data.code === 403) &&
              data.message.indexOf('JWT Token') > -1
            ) {
              const refreshToken = cookies.get('refreshToken');
              if (refreshToken) {
                return controlledFetch(
                  `${process.env.REACT_APP_API_URL}/token/${
                    superadmin ? 'superadmin' : 'user'
                  }/refresh?refresh_token=${refreshToken}`,
                  {
                    method: 'POST',
                  },
                ).then((refreshData) => {
                  if (refreshData.token) {
                    cookies.set('accessToken', refreshData.token, {
                      path: '/',
                    });
                    cookies.set('refreshToken', refreshData.refresh_token, {
                      path: '/',
                    });
                    headers.Authorization = `Bearer ${refreshData.token}`;
                    return req(counter + 1);
                  }
                  if (
                    refreshData.status &&
                    (refreshData.status > 399 || refreshData.status === 'ERROR')
                  ) {
                    window.location.href = '/logout';
                  }
                });
              } else {
                window.location.href = '/logout';
              }
            }
            if (data.status && (data.status > 399 || data.status === 'ERROR')) {
              const res = {
                ...data,
                ...extendErrorObject(data),
              };
              toast.error(res.content, notificationOptions);
              return res;
            }
            return data;
          })
          .catch((error) => {
            const res = {
              ...error,
              ...extendErrorObject(error),
            };
            toast.error(res.content, notificationOptions);
            return res;
          });

  return req();
}
