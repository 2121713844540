import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuoteList from './QuoteList';
import styles from './Column.module.css';

class Column extends Component {
  render() {
    const { id, quotes, index, isScrollable, useClone, width } = this.props;
    return (
      <div
        key={quotes.length}
        draggableid={String(id)}
        index={index}
        className={styles[`column-${index}`]}
        style={{ width }}
      >
        <div className={styles.container}>
          <QuoteList
            listId={String(id)}
            listType="QUOTE"
            quotes={quotes}
            internalScroll={isScrollable}
            useClone={Boolean(useClone)}
            search={this.props.search}
          />
        </div>
      </div>
    );
  }
}

Column.propTypes = {
  id: PropTypes.number,
  quotes: PropTypes.array,
  index: PropTypes.number,
  isScrollable: PropTypes.bool,
  useClone: PropTypes.bool,
};

export default Column;
