import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserProfile, setUser } from './userProfileSlice';
import styles from './userProfile.module.css';
import { Button, Form, Input, Checkbox } from 'semantic-ui-react';
import controlledFetch from '../../app/controlledFetch';
import InputUpload from '../../features/inputUpload/InputUpload';
import { notificationOptions } from '../../app/config';
import { toast } from 'react-toastify';
import helpers from '../../app/helpers';
import AppStyles from '../../App.module.css';
import Logo from '../../features/logo/Logo';
import Notification from '../../features/notification/Notification';
import User from '../../features/user/User';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: props.profile.firstName,
      lastName: props.profile.lastName,
      // notification1: props.profile.notification1,
      // notification2: props.profile.notification2,
      // notification3: props.profile.notification3,
      emailNotifications: props.profile.emailNotifications,
      emailNotification: props.profile.emailNotification,
    };

    props.fetchUserProfile();

    this.isSuperAdmin = helpers.isSuperAdmin();
    this.submitForm = this.submitForm.bind(this);
    props.setShowSidebar(false);
    props.setHeaderContent(this.renderHeader());
  }

  submitForm() {
    const {
      firstName,
      lastName,
      // notification1,
      // notification2,
      // notification3,
      emailNotifications,
    } = this.state;
    controlledFetch(
      `${process.env.REACT_APP_API_URL}/${
        this.isSuperAdmin ? 'superadmin' : 'user'
      }/profile`,
      {
        method: 'PUT',
        body: JSON.stringify({
          firstName,
          lastName,
          // notification1,
          // notification2,
          // notification3,
          emailNotifications,
        }),
      },
    ).then((data) => {
      if (data.status && data.status === 'OK') {
        this.props.fetchUserProfile();
        // helpers.goBack(this.props.history)
        toast.success(
          '🙌 Well done! All changes have been successfully updated.',
          notificationOptions,
        );
      } else {
        this.setState({
          error: data,
        });
      }
    });
  }

  renderHeader() {
    return [
      <div key="left-header-side">
        <span
          onClick={() => {
            helpers.goBack(this.props.history);
          }}
          className={AppStyles.backLink}
        >
          <img src="/assets/arrow-left.svg" alt="arrow left" />
        </span>
        <Logo className="headerTop" />
        <NavLink to={this.isSuperAdmin ? '/superadmins' : '/challenges'}>
          <h1>{helpers.getHeaderBaseOnLocation(window.location.pathname)}</h1>
        </NavLink>
      </div>,
      <div key="right-header-side">
        <Notification />
        <User />
      </div>,
    ];
  }

  render() {
    const { profile, history } = this.props;
    const {
      // notification1,
      // notification2,
      // notification3,
      emailNotifications,
    } = this.state;

    const resetPassword = () => {
      const email = profile.email;
      controlledFetch(`${process.env.REACT_APP_API_URL}/reset_password/user`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          companyId: helpers.getCompanyId(),
        }),
      }).then((data) => {
        if (data.status === 'OK') {
          toast.success(
            `Password reset link sent to ${email}.`,
            notificationOptions,
          );
        }
      });
    };

    return (
      <div className={styles.container}>
        <Form>
          <div className={styles.content}>
            <h4>Profile information</h4>
            <section className={styles.sectionWithImage}>
              <div>
                <div className={styles.thumbnail}>
                  <InputUpload
                    endpoint="/user/profile/avatar"
                    imagePreviewUrl={profile.avatarUrl}
                    fieldName="avatarFile"
                    callback={this.props.fetchUserProfile}
                    className={styles.avatar}
                  />
                </div>
              </div>
              <div>
                <label className={styles.w50}>
                  <div>First name</div>
                  <Input
                    value={this.state.firstName}
                    onChange={(e) => {
                      this.setState({ firstName: e.target.value });
                    }}
                    size="large"
                    disabled={true}
                  />
                </label>
                <label className={styles.w50}>
                  <div>Last name</div>
                  <Input
                    value={this.state.lastName}
                    onChange={(e) => {
                      this.setState({ lastName: e.target.value });
                    }}
                    size="large"
                    disabled={true}
                  />
                </label>
                <label>
                  <div>Email notification</div>
                  <Input value={profile.emailNotification} size="large" disabled={true} />
                </label>
              </div>
            </section>
            <h4>Your activity</h4>
            <section className={styles.counters}>
              {profile.userRole === 'ROLE_MEMBER' ? (
                ''
              ) : (
                <div>
                  Challenges
                  <strong>{profile.countChallenges}</strong>
                </div>
              )}
              <div>
                Upvotes
                <strong>{profile.countVotes}</strong>
              </div>
              <div>
                Comments
                <strong>{profile.countComments}</strong>
              </div>
              <div>
                Solutions
                <strong>{profile.countSolutions}</strong>
              </div>
            </section>
            <h4>Notification settings</h4>
            <section className={styles.notifications}>
              {/* <Form.Field>
                <Checkbox
                  label="Someone responded to your comment"
                  name="CheckboxGroup"
                  checked={notification1}
                  onChange={(e, { checked }) => {
                    this.setState({ notification1: checked });
                  }}
                  size="large"
                  placeholder="Type in text for a checkbox"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Someone upvoted your solution"
                  name="CheckboxGroup"
                  checked={notification2}
                  onChange={(e, { checked }) => {
                    this.setState({ notification2: checked });
                  }}
                  size="large"
                  placeholder="Type in text for a checkbox"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Your solution has been moved to another stage"
                  name="CheckboxGroup"
                  checked={notification3}
                  onChange={(e, { checked }) => {
                    this.setState({ notification3: checked });
                  }}
                  size="large"
                  placeholder="Type in text for a checkbox"
                />
              </Form.Field> */}
              <Form.Field>
                <Checkbox
                  label="E-mail notifications"
                  name="CheckboxGroup"
                  checked={emailNotifications}
                  onChange={(e, { checked }) => {
                    this.setState({ emailNotifications: checked });
                  }}
                  disabled={!profile.emailNotification}
                  size="large"
                  placeholder="Type in text for a checkbox"
                />
              </Form.Field>
            </section>
            {/* <h4>Password reset</h4>
            <section className={styles.reset}>
              <div>By clicking the button below you’ll receive an e-mail with password reset link.</div>
              <Button primary onClick={resetPassword}>
                                Reset password
              </Button>
            </section> */}
            <section className={styles.buttons}>
              <div>
                <NavLink to="/logout">
                  <Button primary={true} inverted={true}>
                    Log out
                  </Button>
                </NavLink>
              </div>
              <div>
                {/* <Button basic={true} onClick={() => history.goBack()}>
                  Cancel
                </Button> */}
                <Button primary={true} onClick={this.submitForm}>
                  Save
                </Button>
              </div>
            </section>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ userProfile }) => {
  return {
    profile: userProfile,
    name: userProfile.firstName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: () => {
      dispatch(fetchUserProfile());
    },
    setUser: () => {
      dispatch(setUser());
    },
  };
};

UserProfile.propTypes = {
  profile: PropTypes.object,
  history: PropTypes.object,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
  defaultHeader: PropTypes.array,
  fetchUserProfile: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserProfile));
