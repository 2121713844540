import React from 'react';
import { Switch, Route, Redirect, withRouter, NavLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import helpers from '../../app/helpers';
import UserAddContent from '../../features/userAddContent/UserAddContent';
import SuperAdminEditContent from '../../features/superAdminEditContent/SuperAdminEditContent';
import SuperAdminAddContent from '../../features/superAdminAddContent/SuperAdminAddContent';
import UserEditContent from '../../features/userEditContent/UserEditContent';
import SolutionForm from '../solutionForm/SolutionForm';
import SolutionsKanban from '../../views/solutionsKanban/SolutionsKanban';
import CompaniesAddContent from '../../features/companiesAddContent/CompaniesAddContent';
import CompaniesEditContent from '../../features/companiesEditContent/CompaniesEditContent';
import ResetPassword from '../../views/resetPassword/ResetPassword';
import Register from '../../views/register/Register';
import Login from '../../views/login/Login';
import LoginDev from '../../views/login/LoginDev';
import Logout from '../../views/logout/Logout';
import UserProfile from '../../views/userProfile/userProfile';
import UsersList from '../../views/usersList/UsersList';
import SuperAdminsList from '../../views/superAdminsList/SuperAdminsList';
import CompaniesList from '../../views/companiesList/CompaniesList';
import NoMatch from '../../views/noMatch/NoMatch';
import FormPopup from '../../views/formPopup/FormPopup';
import SettingsView from '../../views/settingsView/SettingsView';
import ChallengesList from '../../views/challengesList/ChallengesList';
import ChallengeForm from '../../views/challengeForm/ChallengeForm';
import Logo from '../../features/logo/Logo';
import Notification from '../../features/notification/Notification';
import User from '../../features/user/User';
import ChallengeView from '../../views/challengeView/ChallengeView';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, path, ...rest }) {
  const [cookies] = useCookies(['accessToken']);
  const userRole = useSelector(({ userProfile }) => userProfile.role);
  return (
    <Route
      {...rest}
      path={path}
      render={() =>
        cookies.accessToken ? (
          userRole === 'ROLE_MEMBER' &&
          !path.includes('/challenge') &&
          !path.includes('/solutions') &&
          !path.includes('/profile') ? (
            <Redirect to={{ pathname: '/challenges' }} />
          ) : (
            children
          )
        ) : (
          <Redirect to={{ pathname: '/logout' }} />
        )
      }
    />
  );
}

const superAdminRoutes = (setShowSidebar, setHeaderContent, defaultHeader) => [
  <PrivateRoute path="/superadmin/edit/:id" key="/superadmin/edit/:id">
    <SuperAdminsList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
    <FormPopup>
      <SuperAdminEditContent />
    </FormPopup>
  </PrivateRoute>,
  <PrivateRoute path="/superadmins" key="/superadmins">
    <SuperAdminsList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/companies" key="/companies">
    <CompaniesList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/superadmin/add" key="/superadmin/add">
    <SuperAdminsList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
    <FormPopup>
      <SuperAdminAddContent />
    </FormPopup>
  </PrivateRoute>,
  <PrivateRoute path="/profile" key="/profile">
    <UserProfile
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/company/add" key="/company/add">
    <CompaniesList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
    <FormPopup>
      <CompaniesAddContent />
    </FormPopup>
  </PrivateRoute>,
  <PrivateRoute path="/company/edit/:id" key="/company/edit/:id">
    <CompaniesList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
    <FormPopup>
      <CompaniesEditContent />
    </FormPopup>
  </PrivateRoute>,
];

const adminRoutes = (
  setShowSidebar,
  setHeaderContent,
  defaultHeader,
  setSecondHeaderContent,
) => [
  <PrivateRoute path="/challenge/add/:step?" key="/challenge/add/:step">
    <ChallengeForm
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      setSecondHeaderContent={setSecondHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute
    path="/challenge/edit/:id/:step?"
    key="/challenge/edit/:id/:step?"
  >
    <ChallengeForm
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      setSecondHeaderContent={setSecondHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/solutions/:challengeId?" key="/solutions/:challengeId?">
    <SolutionsKanban
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/users" key="/users">
    <UsersList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/user/add" key="/user/add">
    <UsersList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
    <FormPopup>
      <UserAddContent />
    </FormPopup>
  </PrivateRoute>,
  <PrivateRoute path="/user/edit/:id" key="/user/edit/:id">
    <UsersList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
    <FormPopup>
      <UserEditContent />
    </FormPopup>
  </PrivateRoute>,
  <PrivateRoute path="/settings" key="/settings">
    <SettingsView
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/profile" key="/profile">
    <UserProfile
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/challenges" key="/challenges">
    <ChallengesList
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute
    path="/challenge/view/:challengeId/solution/:solutionId/edit"
    key="/challenge/view/:challengeId/solution/:solutionId/edit"
  >
    <SolutionForm
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
      edit={true}
    />
  </PrivateRoute>,
  <PrivateRoute
    path="/challenge/view/:challengeId/solution/:solutionId?"
    key="/challenge/view/:challengeId/solution/:solutionId?"
  >
    <SolutionForm
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
  <PrivateRoute path="/challenge/view/:id" key="/challenge/view/:id">
    <ChallengeView
      setShowSidebar={setShowSidebar}
      setHeaderContent={setHeaderContent}
      defaultHeader={defaultHeader}
    />
  </PrivateRoute>,
];

const AnimatedSwitch = withRouter(
  ({
    location,
    setHeaderContent,
    setShowSidebar,
    headerContent,
    setSecondHeaderContent,
  }) => {
    const companyName = useSelector(({ settings }) => settings.name);
    const [cookies] = useCookies(['accessToken']);
    const superadmin = helpers.isSuperAdmin();
    const pathname = location.pathname.split('/');
    const firstAndSecondPathnameSegments = `${pathname[1]}${
      pathname[2] ? `/${pathname[2]}` : ''
    }`;
    const userRole = useSelector(({ userProfile }) => userProfile.role);
    const defaultHeader = [
      <div key="left-header-side">
        <Logo className="headerTop" />
        <NavLink to={superadmin ? '/superadmins' : '/challenges'}>
          <h1>
            {userRole === 'ROLE_MEMBER'
              ? `Innovation Platform`
              : helpers.getHeaderBaseOnLocation(location.pathname)}
          </h1>
        </NavLink>
      </div>,
      <div key="right-header-side">
        <Notification />
        <User />
      </div>,
    ];
    return (
      <TransitionGroup>
        <CSSTransition
          key={firstAndSecondPathnameSegments}
          classNames="slide"
          timeout={{ enter: 300, exit: 0 }}
        >
          <Switch location={location}>
            {superadmin
              ? superAdminRoutes(
                  setShowSidebar,
                  setHeaderContent,
                  defaultHeader,
                )
              : adminRoutes(
                  setShowSidebar,
                  setHeaderContent,
                  defaultHeader,
                  setSecondHeaderContent,
                )}
            <Route path="/login">
              <Redirect to={{ pathname: '/' }} />
            </Route>
            <Route path="/signin">
              <Register
                setHeaderContent={setHeaderContent}
                setShowSidebar={setShowSidebar}
              />
            </Route>
            <Route path="/password-reset">
              <ResetPassword
                setHeaderContent={setHeaderContent}
                setShowSidebar={setShowSidebar}
              />
            </Route>
            <Route path="/logout">
              <Logout
                setHeaderContent={setHeaderContent}
                setShowSidebar={setShowSidebar}
                headerContent={headerContent}
              />
            </Route>
            <Route path="/privacy">
              <p>This is privacy policy page.</p>
            </Route>
            <Route path="/terms">
              <p>This is terms and conditions page.</p>
            </Route>
            <Route path="/">
              {process.env.NODE_ENV === 'development' ? (
                <LoginDev
                  setHeaderContent={setHeaderContent}
                  setShowSidebar={setShowSidebar}
                />
              ) : (
                <Login
                  setHeaderContent={setHeaderContent}
                  setShowSidebar={setShowSidebar}
                />
              )}
            </Route>
            {!cookies.accessToken && <Redirect to={{ pathname: '/' }} />}
            <Route component={NoMatch} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  },
);

export default AnimatedSwitch;
