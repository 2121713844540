import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'semantic-ui-react';
import IconUser from '../iconUser/IconUser';
import styles from './SolutionTile.module.css';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const SolutionTile = ({ quote, index }) => {
  return (
    <Link to={quote.url}>
      <Card className={[styles.solution, styles[`column-${index}`]].join(' ')}>
        <Card.Content>
          <div className={styles.solutionTitle}>
            <span>{quote.solution.title || quote.solution.id}</span>
          </div>
          <div className={styles.statusWrapper}>
            <span
              className={[styles.status, styles[`column-${index}`]].join(' ')}
            >
              <span>{quote.columnData.title}</span>
            </span>
          </div>
        </Card.Content>
        <Card.Content extra className={styles.actions}>
          <div className={styles.userWrapper}>
            {quote.solution.user.avatarUrl ? (
              <Image
                className={styles.smallerAvatar}
                src={quote.solution.user.avatarUrl}
              />
            ) : (
              <IconUser
                firstName={quote.solution.user.firstName}
                lastName={quote.solution.user.lastName}
                className={styles.smallerAvatar}
              />
            )}
            <span className={styles.username}>
              {quote.solution.user.firstName} {quote.solution.user.lastName},{' '}
              <Moment format="DD/MM/YYYY">{quote.solution.createdAt}</Moment>
            </span>
          </div>
          <div className={styles.socialIcons}>
            <span>
              <Image src="/assets/icon-like.svg" />
              <span>{quote.solution.countVotes || 0}</span>
            </span>
            <span>
              <Image src="/assets/icon-comment.svg" />
              <span>{quote.solution.countComments || 0}</span>
            </span>
          </div>
        </Card.Content>
      </Card>
    </Link>
  );
};

SolutionTile.propTypes = {
  index: PropTypes.number,
  quote: PropTypes.object,
};

export default SolutionTile;
