import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { NavLink, withRouter } from 'react-router-dom';
import { Segment, Image, Card } from 'semantic-ui-react';
import NoMatch from '../noMatch/NoMatch';
import {
  fetchChallenge,
  fetchChallengesList,
} from '../challengesList/challengesSlice';
import styles from './ChallengeView.module.css';
import AppStyles from '../../App.module.css';
import Button from '../../features/button/Button';
import helpers from '../../app/helpers';
import User from '../../features/user/User';
import Notification from '../../features/notification/Notification';
import Logo from '../../features/logo/Logo';
import controlledFetch from '../../app/controlledFetch';
import SolutionTile from '../../features/solutionTile/SolutionTile';
import IconUser from '../../features/iconUser/IconUser';
import Moment from 'react-moment';

class ChallengeView extends Component {
  constructor(props) {
    super(props);

    this.getSolution();
    props.fetchChallenge({
      id: props.match.params.id,
      userRole: props.userRole,
    });
    if (props.setShowSidebar) {
      props.setShowSidebar(false);
      props.setHeaderContent(this.renderHeader());
    }
    this.state = {
      loading: props.setShowSidebar ? props.current === null : true,
      answers: {},
      blink: false,
    };
    this.dropdownOptions = [
      {
        key: 'basic',
        text: 'Step 1: Edit basic information',
        value: 'basic',
        onClick: () => {
          props.history.push(`/challenge/edit/${props.match.params.id}/1`);
        },
      },
      {
        key: 'description',
        text: 'Step 2: Edit description',
        value: 'description',
        onClick: () => {
          props.history.push(`/challenge/edit/${props.match.params.id}/2`);
        },
      },
      {
        key: 'questionnaire',
        text: 'Step 3: Edit questionnaire',
        value: 'questionnaire',
        onClick: () => {
          props.history.push(`/challenge/edit/${props.match.params.id}/3`);
        },
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.current, prevProps.current)) {
      this.setState({
        loading: false,
      });
    }
  }

  getSolution() {
    if (!this.props.match.params.solutionId) {
      return;
    }
    return controlledFetch(`${process.env.REACT_APP_API_URL}/user/solutions/`, {
      method: 'GET',
    }).then((data) => {
      if (data) {
        data.answers.forEach((answer) => {
          this.setAnswers(answer.title, answer.id);
        });
      }
    });
  }

  setAnswers(value, id) {
    this.setState({
      answers: {
        ...this.state.answers,
        [id]: {
          id,
          title: value,
        },
      },
    });
  }

  renderHeader() {
    return [
      <div key="left-header-side">
        <span
          onClick={() => {
            this.props.fetchChallengesList(this.props.userRole);
            helpers.goBack(this.props.history, '/challenges');
          }}
          className={AppStyles.backLink}
        >
          <img src="/assets/arrow-left.svg" alt="arrow left" />
        </span>
        <Logo className="headerTop" />
        <NavLink to="/challenges">
          <h1>{helpers.getHeaderBaseOnLocation(window.location.pathname)}</h1>
        </NavLink>
      </div>,
      <div key="right-header-side">
        <Notification />
        <User />
      </div>,
    ];
  }

  render() {
    if (
      this.props.error &&
      this.props.error.content === 'Challenge id not found.'
    ) {
      return <NoMatch />;
    }
    const { current, match, history, userRole, columns } = this.props;
    if (!current) {
      return (
        <Segment className={AppStyles.segmentGhost}>
          <div className={styles.container}>
            <Card.Group itemsPerRow={2} className={styles.content}>
              <h1 className="header"> </h1>
              <p className="description"></p>
              <div className="description"></div>
              <div className="w100">
                <Image
                  src="/assets/img-placeholder.png"
                  wrapped
                  className={styles.thumbnail}
                  ui={false}
                />
              </div>
              <div className="description"></div>
              <div className="description"></div>
              <div className="description"></div>
            </Card.Group>
            <Card className={styles.rightSidebar}>
              <div className={styles.counters}>
                <div>
                  <div className={styles.countersSolution}>
                    <Image src="/assets/icon-bulb.svg" />
                  </div>
                  <div>
                    <strong>0</strong>
                    <small>Solutions</small>
                  </div>
                </div>
                <div>
                  <div className={styles.countersLike}>
                    <Image src="/assets/icon-like.svg" />
                  </div>
                  <div>
                    <strong>0</strong>
                    <small>Votes</small>
                  </div>
                </div>
              </div>
              <Button
                text="Submit your solution"
                primary={true}
                size="massive"
                onClick={() => {
                  this.props.history.push(
                    `/challenge/view/${this.props.match.params.id}/solution`,
                  );
                }}
              />
              <Button
                text="Browse solutions"
                primary={true}
                inverted={true}
                size="massive"
                onClick={() => {}}
              />
            </Card>
          </div>
        </Segment>
      );
    }

    const scrollToRef = () => {
      refSolution.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.setState({ blink: true });
      setTimeout(() => {
        this.setState({ blink: false });
      }, 2000);
    };
    const refSolution = React.createRef();
    const {
      title,
      description,
      content,
      tags,
      gallery,
      user,
      updatedAt,
    } = current;
    const { blink } = this.state;

    return (
      <div className={styles.container}>
        <Card.Group itemsPerRow={2} className={styles.content}>
          <h1>{title}</h1>
          <p className="description">{description}</p>
          <div className={styles.tags}>
            {tags.map((tag) => (
              <span key={tag}>{tag}</span>
            ))}
          </div>
          <Card.Content extra className={styles.author}>
            <div style={{ float: 'left' }}>
              <IconUser
                firstName={user ? user.firstName : '-'}
                lastName={user ? user.lastName : '-'}
                avatarUrl={user ? user.avatarUrl : ''}
                className={styles.smallerAvatar}
              />
              <span>
                {user ? user.firstName : '-'} {user ? user.lastName : '-'}
              </span>
            </div>
            <div style={{ float: 'right' }}>
              <Moment format="MM/DD/YYYY">{updatedAt}</Moment>
            </div>
          </Card.Content>
          <div className="w100">
            <Image
              src={current.thumbnailUrl}
              wrapped
              className={styles.thumbnail}
              ui={false}
            />
          </div>
          <div
            className="ql-editor quill"
            dangerouslySetInnerHTML={{
              __html: helpers.replaceIdByUrl(content, gallery),
            }}
          />
          <div
            className={[blink ? AppStyles.segmentGhost : '', 'w-100'].join(' ')}
          >
            <h2 key="solutions_divider" className="divider" ref={refSolution}>
              Solutions
            </h2>
            {current.solutions.length > 0 ? (
              <Card.Group
                key="solutions_card"
                itemsPerRow={1}
                className={styles.solutions}
              >
                {current.solutions.map((solution) => {
                  const columnData = columns.filter(
                    (col) => col.id === solution.column,
                  )[0];
                  const quote = {
                    columnData,
                    solution,
                    url: `/challenge/view/${current.id}/solution/${solution.id}`,
                  };

                  return (
                    <SolutionTile
                      quote={quote}
                      index={columns.indexOf(columnData)}
                      key={'c' + current.id + '' + solution.id}
                    />
                  );
                })}
              </Card.Group>
            ) : (
              <div className={styles.noSolutions}>
                <img alt={'no-solutions'} src="/assets/icon-no-solutions.svg" />
                <span>There are no solutions yet</span>
              </div>
            )}
          </div>
        </Card.Group>
        {current.status === 'PUBLISHED' || userRole === 'ROLE_MEMBER' ? (
          <Card className={styles.rightSidebar}>
            <div className={styles.counters}>
              <div>
                <div className={styles.countersSolution}>
                  <Image src="/assets/icon-bulb-black.svg" />
                </div>
                <div>
                  <strong>{current.countSolutions || '0'}</strong>
                  <small>Solutions</small>
                </div>
              </div>
              <div>
                <div className={styles.countersLike || '0'}>
                  <Image src="/assets/icon-like.svg" />
                </div>
                <div>
                  <strong>{current.countVotes || '0'}</strong>
                  <small>Votes</small>
                </div>
              </div>
            </div>
            <Button
              text="Submit your solution"
              primary={true}
              size="massive"
              onClick={() => {
                this.props.history.push(
                  `/challenge/view/${this.props.match.params.id}/solution`,
                );
              }}
            />
            <Button
              text="Browse solutions"
              primary={true}
              inverted={true}
              size="massive"
              onClick={scrollToRef}
            />
            {userRole !== 'ROLE_MEMBER' ? (
              <Button
                text="Edit challenge"
                className="margin"
                dropdown={true}
                size="massive"
                options={this.dropdownOptions}
                onClick={() => {
                  history.push(`/challenge/edit/${match.params.id}/1`);
                }}
              />
            ) : (
              ''
            )}
          </Card>
        ) : (
          <Card className={styles.rightSidebar}>
            <div className={styles.info}>
              <Image src="/assets/icon-info.svg" />
              <div>
                This challenge is a draft and can’t be seen by other members.
              </div>
            </div>
            <Button
              text="Edit challenge"
              dropdown={true}
              size="massive"
              options={this.dropdownOptions}
              onClick={() => {
                history.push(`/challenge/edit/${match.params.id}/1`);
              }}
            />
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ challenges, userProfile, settings }) => {
  return {
    current: challenges.current,
    error: challenges.error,
    userRole: userProfile.role,
    columns: settings.columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChallenge: (props) => {
      dispatch(fetchChallenge(props));
    },
    fetchChallengesList: (props) => {
      dispatch(fetchChallengesList(props));
    },
  };
};

ChallengeView.propTypes = {
  match: PropTypes.object.isRequired,
  error: PropTypes.object,
  current: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  fetchChallenge: PropTypes.func.isRequired,
  defaultHeader: PropTypes.array,
  setShowSidebar: PropTypes.func,
  setHeaderContent: PropTypes.func,
  userRole: PropTypes.string.isRequired,
};

const ChallengeViewWithRouter = withRouter(ChallengeView);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeViewWithRouter);
