import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Form, Segment, Dimmer, Loader, Image, Icon, Popup } from 'semantic-ui-react'
import { fetchUsersList } from '../../views/usersList/usersSlice'
import styles from './UserEditContent.module.css'
import Button from '../button/Button'
import controlledFetch from '../../app/controlledFetch'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { notificationOptions } from '../../app/config'
import helpers from '../../app/helpers'
 
class UserEditContent extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      anyChangeInForm: false,
      error: null,
      isPopupOpen: false,
      copied: false
    }
    
    this.fetchUser = this.fetchUser.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)

    this.fetchUser(props)
  }

  fetchUser(props = this.props) {
    controlledFetch(`${process.env.REACT_APP_API_URL}/admin/users/${props.match.params.id}`, {
      method: 'GET',
    }).then(data => {
      if (typeof data === 'object') {
        this.setState(data)
      }
    })
  }
  
  submitForm() { 
    const { firstName, lastName, role } = this.state
    controlledFetch(`${process.env.REACT_APP_API_URL}/admin/users/${this.props.match.params.id}`, {
      method: 'PUT',
      body: JSON.stringify({ firstName, lastName, role }),
    }).then(data => {
      if (data.status && data.status === "OK") {
        this.props.fetchUsersList()
        helpers.goBack(this.props.history)
        toast.success('🙌 Well done! All changes have been successfully updated.', notificationOptions)
      } else {
        this.setState({
          error: data
        })
      }
    })
  }

  handleOpen() {
    this.setState({ copied: true, isPopupOpen: false }, () => {
      this.setState({ isPopupOpen: true })
    })
    this.timeout = setTimeout(() => {
      this.setState({ copied: false, isPopupOpen: false })
    }, 1000)
  }
  
  handleClose() {
    this.setState({ copied: false })
    clearTimeout(this.timeout)
  }

  render() {
    const { error, role } = this.state
    return [
      <Modal.Header key="header">Edit member</Modal.Header>,
      <Modal.Content key="content">
        <Modal.Description>
          <p>Set role and manage member details.</p>
          {role === '' 
            ? <Segment>
              <Dimmer active
                inverted>
                <Loader inverted
                  size="large">Loading</Loader>
              </Dimmer>
              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
            </Segment>
            : <Form error={!!error}>
              <Form.Group>
                <Form.Select
                  label='Role'
                  defaultValue={role}
                  size="large"
                  onChange={(classObj, data) => {
                    this.setState({ role: data.value, anyChangeInForm: true })
                  }} 
                  options={[
                    {
                      text: 'Member',
                      value: 'ROLE_MEMBER'
                    },
                    {
                      text: 'Admin',
                      value: 'ROLE_ADMIN'
                    },
                  ]}
                  className={styles.select} 
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input 
                  fluid 
                  label='First Name' 
                  size="large"
                  placeholder='First Name' 
                  error={error && error.errorFields && error.errorFields.firstName ? error : null}
                  defaultValue={this.state.firstName}
                  onChange={(classObj, data) => {
                    this.setState({ error: null, firstName: data.value, anyChangeInForm: data.value.length > 0 })
                  }}
                />
                <Form.Input 
                  fluid 
                  label='Last name' 
                  size="large"
                  placeholder='Last name' 
                  error={error && error.errorFields && error.errorFields.lastName ? error : null}
                  defaultValue={this.state.lastName} 
                  onChange={(classObj, data) => {
                    this.setState({ error: null, lastName: data.value, anyChangeInForm: data.value.length > 0 })
                  }} 
                />
              </Form.Group>
              <Form.Input 
                label='E-mail' 
                size="large"
                placeholder='E-mail' 
                disabled={true}
                className={styles.disabledInputEmail}
                icon={<Popup 
                  position='top center' 
                  on='hover'
                  onOpen={() => { this.setState({ isPopupOpen: true }) }}
                  onClose={() => { this.setState({ isPopupOpen: false }) }}
                  content={this.state.copied ? 'Copied!' : 'Copy to the clipboard'}
                  open={this.state.isPopupOpen}
                  trigger={
                    <CopyToClipboard onCopy={this.handleOpen}
                      text={this.state.email}>
                      <Icon link >
                        <Image src='/assets/icon-copy.svg'
                          alt="copy"/>
                      </Icon>
                    </CopyToClipboard>
                  }
                />}
                value={this.state.email} 
              />
            </Form>}
        </Modal.Description>
      </Modal.Content>,
      <Modal.Actions key="actions">
        <Button secondary={true}
          text="Cancel"
          onClick={() => { helpers.goBack(this.props.history) }} />
        <Button text="Update"
          primary={true}
          onClick={this.submitForm}
          disabled={!this.state.anyChangeInForm} />
      </Modal.Actions>
    ]
  }
}

UserEditContent.propTypes = {
  fetchUsersList: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsersList: () => {
      dispatch(fetchUsersList())
    },
  }
}

const UserEditContentWithRouter = withRouter(UserEditContent)
export default connect(null, mapDispatchToProps)(UserEditContentWithRouter)