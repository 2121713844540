import React  from 'react'
import styles from './LoginScreensFooter.module.css'

const LoginScreensFooter = () => {
  return <footer>
    <div className={styles.footer}>
      <span>Copyright NewLab 2020</span>
      <div>
        <a href="#">Privacy Policy</a>
        <a href="#">Terms and conditions</a>
      </div>
    </div>
  </footer>
}

export default LoginScreensFooter