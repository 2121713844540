const getTranslation = (errorMessage) => {
  switch(errorMessage) {
  case '[TOO_FEW_SIGNS] The minimum number of characters is 1.': 
    return 'Field cannot be empty'
  case '[COMPANY_NOT_FOUND] Company id not found.': 
    return '404 page not found'
  case 'The user aborted a request.': 
    return 'Connection issue with server. Please try again.'
  case 413: 
    return 'Max image size – 10MB'
  default: 
    return 'Something went wrong, please try again in a few minutes. If the problem persists, please contact the platform administrator.'
  }
}

const scrapFirstErrorFieldsMessage = (errorFields = []) => {
  if (!errorFields) {
    return null
  }
  if (errorFields.length && errorFields.length > 0) {
    return Object.values(errorFields[0])[0]
  }
  const keys = Object.keys(errorFields)
  return errorFields[keys[0]]
}

module.exports = {
  extendErrorObject: (error = '') => {
    if (typeof error === 'string') {
      return {
        content: getTranslation(error)
      }
    }
    return {
      content: getTranslation(scrapFirstErrorFieldsMessage(error.errorFields) || error.message || error.status || error)
    }
  }
}