import { createSlice } from '@reduxjs/toolkit';
import controlledFetch from '../../app/controlledFetch';
import { extendErrorObject } from '../../app/errorHandler';

export const challenges = createSlice({
  name: 'challenges',
  initialState: {
    list: [],
    current: null,
    total: -1,
    error: null,
    uploadFileDisabled: false,
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setTotal: (state, action) => {
      state.total = Number(action.payload);
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUploadFileDisabled: (state, action) => {
      state.uploadFileDisabled = action.payload;
    },
  },
});

export const {
  setList,
  setTotal,
  setCurrent,
  setError,
  setUploadFileDisabled,
} = challenges.actions;
export const challengesList = (state) => state.challenges;
export const fetchChallengesList = (userRole) => (dispatch) => {
  controlledFetch(
    `${process.env.REACT_APP_API_URL}/${
      userRole === 'ROLE_MEMBER' ? 'user' : 'admin'
    }/challenges?orderBy=updatedAt`,
    {
      method: 'GET',
    },
  ).then((data) => {
    if (data.data) {
      dispatch(setList(data.data));
    }
    if (data.info) {
      dispatch(setTotal(data.info.total));
    }
  });
};

export const fetchChallenge = ({ id, userRole }) => (dispatch) => {
  return controlledFetch(
    `${process.env.REACT_APP_API_URL}/${
      userRole === 'ROLE_MEMBER' ? 'user' : 'admin'
    }/challenges/${id}`,
    {
      method: 'GET',
    },
  ).then((data) => {
    if (data.status === 'ERROR') {
      dispatch(setError(extendErrorObject(data)));
      return;
    }
    dispatch(setError(null));
    if (data.thumbnailUrl) {
      const img = new Image();
      img.src = data.thumbnailUrl;
      img.onload = () => {
        dispatch(setCurrent(data));
      };
      img.onerror = () => {
        setTimeout(() => {
          dispatch(setCurrent(data));
        }, 200);
      };
    } else {
      dispatch(setCurrent(data));
    }
    return data;
  });
};

export default challenges.reducer;
