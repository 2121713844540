import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Popup } from 'semantic-ui-react'
import iro from '@jaames/iro'
import styles from './ColorPicker.module.css'
 
class ColorPicker extends Component {
  constructor(props) {
    super(props)

    this.colorPicker = null
    this.primaryColor = '#0000FF'
    this.hexColorRegExp = /^#(?:[0-9a-f]{3}){1,2}$/i
    this.state = {
      color: props.color || this.primaryColor,
    }

    this.onColorChange = this.onColorChange.bind(this)
    this.mountPicker = this.mountPicker.bind(this)
  }

  mountPicker() {
    this.colorPicker = new iro.ColorPicker("#picker", {
      width: 180,
      color: this.state.color.match(this.hexColorRegExp) ? this.state.color : this.primaryColor
    })
    this.colorPicker.on('color:change', this.onColorChange)
  }

  unmountPicker() {
    if (this.colorPicker && typeof this.colorPicker.off === 'function') {
      this.colorPicker.off('color:change', this.onColorChange)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.color !== prevProps.color) {
      this.setState({ color: this.props.color })
    }
  }

  componentWillUnmount() {
    this.unmountPicker() 
  }

  onColorChange(color) {
    this.props.callback(color.hexString)
  }
 
  render() {
    const { error, label } = this.props
    return <div className={styles.container}>
      <Form.Input
        label={label}
        size="large"
        placeholder=''
        type="text" 
        value={this.state.color} 
        labelPosition="right"
        onChange={(e) => { 
          let { value } = e.target
          if (value.indexOf('#') !== 0) {
            value = `#${value}`
          }
          this.props.callback(value)
          this.props.onChange()
        }}
        error={error ? error : null}
      >
        <input />
        <Popup
          content={<div id="picker" />}
          on='click'
          position="bottom center"
          style={this.state.popupStyles}
          onMount={() => {this.mountPicker()}}
          onUnmount={() => {this.unmountPicker()}}
          trigger={<div 
            style={{ backgroundColor: this.state.color }} 
            className={`ui label label ${styles.trigger}`} 
          />}
        />
      </Form.Input>
    </div>
  }
}

ColorPicker.defaultProps = {
  onChange: () => {}
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.object,
  callback: PropTypes.func,
  onChange: PropTypes.func
}

export default ColorPicker