import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../button/Button';
import InputTag from '../inputTag/InputTag';
import controlledFetch from '../../app/controlledFetch';
import { fetchUsersList } from '../../views/usersList/usersSlice';
import { Modal, Select } from 'semantic-ui-react';
import styles from './UserAddContent.module.css';
import { toast } from 'react-toastify';
import { notificationOptions } from '../../app/config';
import { withRouter } from 'react-router-dom';
import helpers from '../../app/helpers';

class UserAddContent extends Component {
  constructor() {
    super();
    this.state = {
      email: [],
      role: 'ROLE_MEMBER',
      error: null,
    };

    this.callback = this.callback.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  callback(e) {
    const val = JSON.parse(e.target.value || '[]');

    this.setState({
      error: null,
      email: val.map((tag) => tag.value),
    });
  }

  submitForm() {
    controlledFetch(`${process.env.REACT_APP_API_URL}/admin/users`, {
      method: 'POST',
      body: JSON.stringify(
        this.state.email.map((email) => ({
          email,
          role: this.state.role,
        })),
      ),
    }).then((data) => {
      if (data.status && data.status === 'OK') {
        this.props.fetchUsersList();
        helpers.goBack(this.props.history);
        toast.success(
          `👏 You added a new member! We‘ve sent the email invitation.`,
          notificationOptions,
        );
      } else {
        this.setState({ error: data });
      }
    });
  }

  render() {
    const { error } = this.state;
    return [
      <Modal.Header key="header">Add Member</Modal.Header>,
      <Modal.Content key="content">
        <Modal.Description>
          <p>
            Enter new member email address and select the member’s role. We’ll
            send the invites. And yes, you can enter multiple emails :).
          </p>
          <InputTag
            className={styles.tagify}
            placeholder="E-mail"
            callback={this.callback}
            pattern={
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }
            error={error}
          >
            <Select
              className={styles.select}
              defaultValue={this.state.role}
              onChange={(classObj, data) => {
                this.setState({ role: data.value });
              }}
              options={[
                {
                  text: 'Member',
                  value: 'ROLE_MEMBER',
                },
                {
                  text: 'Admin',
                  value: 'ROLE_ADMIN',
                },
              ]}
            />
          </InputTag>
        </Modal.Description>
      </Modal.Content>,
      <Modal.Actions key="actions">
        <Button
          text="Cancel"
          secondary={true}
          onClick={() => {
            helpers.goBack(this.props.history);
          }}
        />
        <Button
          text="Add member"
          primary={true}
          disabled={this.state.email.length === 0}
          onClick={this.submitForm}
        />
      </Modal.Actions>,
    ];
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersList: () => {
      dispatch(fetchUsersList());
    },
  };
};

UserAddContent.propTypes = {
  fetchUsersList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const UserAddContentWithRouter = withRouter(UserAddContent);
export default connect(null, mapDispatchToProps)(UserAddContentWithRouter);
