import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Modal, Form, Button as SemanticButton } from 'semantic-ui-react'
import Button from '../../features/button/Button'
import Logo from '../../features/logo/Logo'
import controlledFetch from '../../app/controlledFetch'
import { withCookies, Cookies } from 'react-cookie'
import helpers from '../../app/helpers'
import styles from './ResetPassword.module.css'
import { extendErrorObject } from '../../app/errorHandler'
import equal from 'fast-deep-equal'
import LoginScreensFooter from "../../features/loginScreensFooter/LoginScreensFooter";

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    props.setShowSidebar(false)
    props.setHeaderContent(null)
    this.superadmin = helpers.isSuperAdmin()
    const query = helpers.getQuery()
    this.hash = query.get('hash')
    this.state = {
      login: '',
      password: '',
      password2: '',
      cookies: props.cookies.getAll(),
      error: null,
      focus: null,
      step: this.hash ? 2 : 0,
      showPass: false
    }

    this.frontValidation = this.frontValidation.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.sendLogin = this.sendLogin.bind(this)
    this.goForward = this.goForward.bind(this)

    if(this.state.cookies.accessToken) {
      props.history.push(this.superadmin ? '/superadmins' : '/challenges')
    }
  }

  componentDidMount() {
    helpers.focusFirstInput()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step || !equal(prevState.error, this.state.error)) {
      helpers.focusFirstInput()
    }
  }

  goForward() {
    const { history } = this.props
    if (this.superadmin) {
      history.push('/superadmins')
      return
    } 
    history.push('/challenges')
  }

  frontValidation() {
    const { login, password, password2 } = this.state
    const EmailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    switch(this.state.step) {
    case 0:
      if (login.length === 0 || !login.match(EmailRegExp)) {
        this.setState({ error: extendErrorObject('Email is not valid') })
        return false
      }
      break
    case 2:
      if (this.hash) {
        if (password !== password2) {
          this.setState({ error: extendErrorObject('Passwords don\'t match') })
          return false
        }
      }
      if (password.length < 8) {
        this.setState({ error: extendErrorObject('Password should have at least 8 characters') })
        return false
      }
      break
    default:
      break
    }
    if (this.state.error) {
      this.setState({ error: null })
    }
    return true
  }

  nextStep() {
    if (!this.frontValidation()) {
      return
    }
    this.setState({ step: this.state.step + 1 })
    return
  }

  sendLogin() {
    if (!this.frontValidation()) {
      return
    }
    const { login } = this.state
    const url = this.superadmin ? `${process.env.REACT_APP_API_URL}/reset_password/superadmin` : `${process.env.REACT_APP_API_URL}/reset_password/user`
    const fetchProps = this.superadmin 
      ? {
        method: 'POST',
        body: JSON.stringify({ 
          email: login,
        })
      } 
      : {
        method: 'POST',
        body: JSON.stringify({ 
          companyId: helpers.getCompanyId(),
          email: login,
        })
      }
    controlledFetch(url, fetchProps).then(data => {
      if (data.status === 'OK') {
        this.setState({ step: this.state.step + 1 })
      } else {
        this.setState({ error: data })
      }
    })
  }

  submitForm() { 
    if (!this.frontValidation()) {
      return
    }
    const { password } = this.state
    controlledFetch(`${process.env.REACT_APP_API_URL}/reset_password/${this.superadmin ? 'superadmin' : 'user'}/${this.hash}`, {
      method: 'PUT',
      body: JSON.stringify({ 
        plainPassword: password
      })
    }).then(data => {
      if (data.access_token) {
        this.props.cookies.set('accessToken', data.access_token, { path: '/' })
        this.props.cookies.set('refreshToken', data.refresh_token, { path: '/' })
        this.nextStep()
        setTimeout(this.goForward, 5000)
      } else if (this.hash && data.status === 'OK') {
        this.nextStep()
        setTimeout(this.goForward, 5000)
      } else {
        this.setState({ error: data })
      }
    })
  }

  renderButtons(continueAction) {
    return <Modal.Actions key="actions"
      className={[styles.animation, styles.buttons].join(' ')}>
      <Button text="Continue"
        primary={true}
        className="higher"
        onClick={continueAction} />
      {this.state.step === 0 && <SemanticButton className="ui button underlined"
        onClick={this.props.history.goBack}>Go back</SemanticButton>}
      {this.state.step > 0 && this.state.step !== 2 && <SemanticButton className="ui button underlined"
        onClick={() => { this.setState({ step: this.state.step - 1 }) }}>Go back</SemanticButton>}
    </Modal.Actions>
  }

  render() {
    const { error } = this.state

    switch(this.state.step) {
    case 0:
      return <div className={styles.background}>
        <Modal size="tiny"
          defaultOpen={true}
          centered={true}
          closeOnDimmerClick={false}
          onClose={() => { helpers.goBack(this.props.history) }}
          className={styles.modal}>
          <Logo className={styles.logoAnimation}
            login
            size={100} />
          <h3 className={`${styles.h3} ${styles.animation}`}>Password recovery</h3>
          <Form error={!!error}
            className={styles.animation}>
            {!this.hash && this.state.step === 0 && <Form.Input
              size="large" 
              placeholder='Type your e-mail' 
              name="login" 
              defaultValue=""
              error={error}
              onKeyDown={event => { event.key === 'Enter' && this.sendLogin() }}     
              onChange={event => { this.setState({ error: null, login: event.target.value }) }}
            />}
          </Form>
          {this.renderButtons(this.sendLogin)}
        </Modal>
        <LoginScreensFooter />
      </div>
    case 1:
      return <div className={styles.background}>
        <Modal size="tiny"
          defaultOpen={true}
          centered={true}
          closeOnDimmerClick={false}
          onClose={() => { helpers.goBack(this.props.history) }}
          className={styles.modal}>
          <Logo className={styles.logoAnimation}
            login
            size={100} />
          <h3 className={`${styles.h3} ${styles.animation}`}>We’ve sent the recovery email!</h3>
          <p className={`${styles.description} ${styles.animation}`}>{this.state.login}</p>
        </Modal>
        <LoginScreensFooter />
      </div>
    case 2:
      return <div className={styles.background}>
        <Modal size="tiny"
          defaultOpen={true}
          centered={false}
          closeOnDimmerClick={false}
          onClose={() => { helpers.goBack(this.props.history) }}
          dimmer="inverted">
          <Logo className={styles.logoAnimation}
            inverted
            size={100} />
          <h3 className={`${styles.h3} ${styles.animation}`}>Set password</h3>
          <Form error={!!error}
            className={styles.animation}>
            <Form.Input
              icon
              size="large"
              placeholder='Specify password'
              id="password"
              type={this.state.showPass ? 'text' : "password"}
              name="password"
              error={error}
              value={this.state.password}
              onKeyDown={event => { event.key === 'Enter' && this.setState({ focus: 'repeatpassword' }) }}  
              onChange={event => { this.setState({ error: null, password: event.target.value }) }}>
              <input />
              <i className="icon link"
                onClick={() => { this.setState({ showPass: !this.state.showPass})}}>
                <img src={this.state.showPass ? '/assets/eye-off.svg' : '/assets/eye.svg'}
                  alt="show password" />
              </i>
            </Form.Input>
            <Form.Input
              size="large"
              placeholder='Repeat password'
              id="repeatPassword"
              type={this.state.showPass ? 'text' : "password"}
              name="repeatPassword"
              error={error}
              value={this.state.password2}
              focus={this.state.focus === 'repeatpassword'}
              onKeyDown={event => { event.key === 'Enter' && this.submitForm() }}  
              onChange={event => { this.setState({ error: null, password2: event.target.value }) }} />
          </Form>
          {this.renderButtons(this.submitForm)}
        </Modal>
        <LoginScreensFooter />
      </div>
    case 3:
      return <div className={styles.background}>
        <Modal size="tiny"
          defaultOpen={true}
          centered={false}
          closeOnDimmerClick={false}
          onClose={() => { helpers.goBack(this.props.history) }}
          dimmer="inverted">
          <Logo className={styles.logoAnimation}
            inverted
            size={100} />
          <h3 className={`${styles.h3} ${styles.animation}`}>Password set!</h3>
        </Modal>
        <LoginScreensFooter />
      </div>
    default:
      return null
    }
  }
}

ResetPassword.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  history: PropTypes.object,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
}

export default withRouter(withCookies(ResetPassword))
