import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import QuestionShortInput from '../questionShort/QuestionShortInput';
import styles from './QuestionSelect.module.css';
import DividerWithActions from '../dividerWithActions/DividerWithActions';
import QuestionLongInput from '../questionLong/QuestionLongInput';
import QuestionRadioInput from '../questionRadio/QuestionRadioInput';
import QuestionCheckboxInput from '../questionCheckbox/QuestionCheckboxInput';
import QuestionDropdownInput from '../questionDropdown/QuestionDropdownInput';
import QuestionFileInput from '../questionFile/QuestionFileInput';
import QuestionDateInput from '../questionDate/QuestionDateInput';
import QuestionTimeInput from '../questionTime/QuestionTimeInput';

const QuestionSelect = ({
  id,
  error,
  callback,
  defaultValue,
  addNewField,
  type,
  goUp,
  goDown,
  remove,
}) => {
  const [typeVal, setType] = useState(type || 'short_answer');
  const uploadFileDisabled = useSelector(
    ({ challenges }) => challenges.uploadFileDisabled,
  );
  const listInputCallback = useCallback(
    ({ question, possibleAnswers }) => {
      const res = {
        type: typeVal,
      };
      if (question !== undefined) {
        res.question = question;
      }
      if (possibleAnswers !== undefined) {
        res.possibleAnswers = possibleAnswers;
      }
      callback(res);
    },
    [typeVal, callback],
  );
  let formInput = '';
  console.log(error ? error[id] : null);
  switch (typeVal) {
    case 'long_answer':
      formInput = (
        <QuestionLongInput
          defaultValue={defaultValue.question}
          error={error ? error[id] : null}
          callback={(question) => {
            callback({
              type: typeVal,
              question,
            });
          }}
        />
      );
      break;
    case 'radio':
      formInput = (
        <QuestionRadioInput
          defaultValue={defaultValue}
          error={error ? error[id] : null}
          callback={listInputCallback}
        />
      );
      break;
    case 'checkbox':
      formInput = (
        <QuestionCheckboxInput
          defaultValue={defaultValue}
          error={error ? error[id] : null}
          callback={listInputCallback}
        />
      );
      break;
    case 'dropdown':
      formInput = (
        <QuestionDropdownInput
          defaultValue={defaultValue}
          error={error ? error[id] : null}
          callback={listInputCallback}
        />
      );
      break;
    case 'upload_file':
      formInput = (
        <QuestionFileInput
          defaultValue={defaultValue.question}
          error={error ? error[id] : null}
          callback={(question) => {
            callback({
              type: typeVal,
              question,
            });
          }}
        />
      );
      break;
    case 'date_picker':
      formInput = (
        <QuestionDateInput
          defaultValue={defaultValue}
          error={error ? error[id] : null}
          callback={listInputCallback}
        />
      );
      break;
    case 'time_picker':
      formInput = (
        <QuestionTimeInput
          defaultValue={defaultValue}
          error={error ? error[id] : null}
          callback={listInputCallback}
        />
      );
      break;
    default:
      formInput = (
        <QuestionShortInput
          defaultValue={defaultValue.question}
          error={error ? error[id] : null}
          callback={(question) => {
            callback({
              type: 'short_answer',
              question,
            });
          }}
        />
      );
      break;
  }
  const options = [
    { key: 'short_answer', text: 'Short answer', value: 'short_answer' },
    { key: 'long_answer', text: 'Long answer', value: 'long_answer' },
    { key: 'radio', text: 'Radio', value: 'radio' },
    { key: 'checkbox', text: 'Checkbox', value: 'checkbox' },
    { key: 'dropdown', text: 'Dropdown', value: 'dropdown' },
    {
      key: 'upload_file',
      text: 'Photo upload',
      value: 'upload_file',
      disabled: uploadFileDisabled,
    },
    { key: 'date_picker', text: 'Date picker', value: 'date_picker' },
    { key: 'time_picker', text: 'Time picker', value: 'time_picker' },
  ];
  return (
    <Form id={id} className={styles.container}>
      <Form.Group className={styles.actionsWrapper}>
        <DividerWithActions
          addNewField={addNewField}
          goUp={goUp}
          goDown={goDown}
          remove={remove}
        />
        {formInput}
      </Form.Group>
      <Form.Group className={styles.selectType}>
        <Form.Select
          size="large"
          options={options}
          defaultValue={typeVal}
          placeholder="Type"
          onChange={(classObj, data) => {
            callback({
              type: data.value,
            });
            setType(data.value);
          }}
        />
      </Form.Group>
    </Form>
  );
};

QuestionSelect.defaultProps = {
  callback: () => {},
  addNewField: () => {},
  goUp: () => {},
  goDown: () => {},
  remove: () => {},
  defaultValue: {},
  type: 'short_answer',
};

QuestionSelect.propTypes = {
  id: PropTypes.number,
  error: PropTypes.object,
  callback: PropTypes.func,
  addNewField: PropTypes.func,
  goUp: PropTypes.func,
  goDown: PropTypes.func,
  remove: PropTypes.func,
  type: PropTypes.string,
  defaultValue: PropTypes.object,
};

export default QuestionSelect;
