import React  from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import 'moment-timezone'
 
const DateField = (props) => {
  const calendarStrings = {
    lastDay : '[Yesterday,] LT',
    sameDay : '[Today,] LT',
    nextDay : '[Tomorrow,] LT',
    lastWeek : '[last] dddd [,] LT',
    nextWeek : 'dddd [,] LT',
    sameElse : 'L'
  }
 
  return (
    props.datetime 
      ? <Moment calendar={props.calendar || calendarStrings}>
        {props.datetime}
      </Moment>
      : 'Never'
  )
}

DateField.propTypes = {
  datetime: PropTypes.string,
  calendar: PropTypes.object,
}

export default DateField