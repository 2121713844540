import React  from 'react'
import PropTypes from 'prop-types'
import styles from './DividerWithActions.module.css'
     
const DividerWithActions = ({ 
  addNewField = null, 
  goUp = null, 
  goDown = null, 
  remove = null,
  className = '' 
}) => {
  return <div className={`${styles.divider} ${className ? styles[className] : ''}`}>
    {addNewField && <span className={styles.addButton}
      onClick={addNewField}>
      <svg width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.iconAdd}>
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20ZM11 16C11.5128 16 11.9355 15.614 11.9933 15.1166L12 15V12H15C15.5523 12 16 11.5523 16 11C16 10.4872 15.614 10.0645 15.1166 10.0067L15 10H12V7C12 6.44772 11.5523 6 11 6C10.4872 6 10.0645 6.38604 10.0067 6.88338L10 7V10H7C6.44772 10 6 10.4477 6 11C6 11.5128 6.38604 11.9355 6.88338 11.9933L7 12H10V15C10 15.5523 10.4477 16 11 16Z"
          fill="black"/>
      </svg>
    </span>}
    {goUp && <span className={styles.arrowUp}
      onClick={goUp}>
      <svg width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C8.51284 16 8.93551 15.614 8.99327 15.1166L9 15V3.415L14.2929 8.70711C14.6534 9.06759 15.2206 9.09532 15.6129 8.7903L15.7071 8.70711C16.0676 8.34662 16.0953 7.77939 15.7903 7.3871L15.7071 7.29289L8.70711 0.292892C8.67864 0.264426 8.64847 0.237669 8.61675 0.212786L8.70711 0.292892C8.67171 0.257499 8.63433 0.225313 8.59531 0.196335C8.57113 0.178491 8.54628 0.161718 8.52066 0.146067C8.50861 0.138606 8.49639 0.131499 8.48406 0.124672C8.46429 0.113794 8.44389 0.103377 8.4231 0.0936546C8.4061 0.08568 8.3888 0.0781975 8.37134 0.0712261C8.35158 0.0633163 8.3318 0.0561228 8.31174 0.0495472C8.29685 0.0447292 8.28146 0.0400972 8.26599 0.0358448C8.24444 0.0298328 8.22275 0.0246391 8.20079 0.0201645C8.18338 0.0167017 8.16595 0.013629 8.14847 0.0110188C8.12811 0.00790691 8.10706 0.00543499 8.08581 0.00362968C8.06792 0.00214481 8.05059 0.00112247 8.03325 0.000549316C8.0224 0.000185013 8.01122 0 8 0L7.96775 0.000516891C7.94973 0.00109482 7.93173 0.00215626 7.91377 0.00370216L8 0C7.94938 0 7.89964 0.00376034 7.85104 0.0110188C7.83405 0.013628 7.81663 0.0167027 7.79927 0.0202408C7.77725 0.0246401 7.75556 0.0298338 7.73416 0.0357208C7.71854 0.0400972 7.70315 0.0447283 7.68786 0.0497379C7.6682 0.0561228 7.64842 0.0633154 7.62894 0.071104C7.6112 0.0781965 7.5939 0.0856791 7.57678 0.0936737C7.55611 0.103377 7.53571 0.113794 7.51572 0.124876C7.50361 0.131499 7.49139 0.138607 7.47929 0.145996C7.45372 0.161719 7.42887 0.178491 7.40484 0.196314C7.39879 0.200718 7.39293 0.205175 7.3871 0.209705C7.35153 0.23767 7.32136 0.264426 7.29289 0.292892L0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711C0.653377 9.06759 1.22061 9.09532 1.6129 8.7903L1.70711 8.70711L7 3.415V15C7 15.5523 7.44772 16 8 16Z"
          fill="#0000FF"/>
      </svg>
    </span>}
    {goDown && <span className={styles.arrowDown}
      onClick={goDown}>
      <svg width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C8.51284 16 8.93551 15.614 8.99327 15.1166L9 15V3.415L14.2929 8.70711C14.6534 9.06759 15.2206 9.09532 15.6129 8.7903L15.7071 8.70711C16.0676 8.34662 16.0953 7.77939 15.7903 7.3871L15.7071 7.29289L8.70711 0.292892C8.67864 0.264426 8.64847 0.237669 8.61675 0.212786L8.70711 0.292892C8.67171 0.257499 8.63433 0.225313 8.59531 0.196335C8.57113 0.178491 8.54628 0.161718 8.52066 0.146067C8.50861 0.138606 8.49639 0.131499 8.48406 0.124672C8.46429 0.113794 8.44389 0.103377 8.4231 0.0936546C8.4061 0.08568 8.3888 0.0781975 8.37134 0.0712261C8.35158 0.0633163 8.3318 0.0561228 8.31174 0.0495472C8.29685 0.0447292 8.28146 0.0400972 8.26599 0.0358448C8.24444 0.0298328 8.22275 0.0246391 8.20079 0.0201645C8.18338 0.0167017 8.16595 0.013629 8.14847 0.0110188C8.12811 0.00790691 8.10706 0.00543499 8.08581 0.00362968C8.06792 0.00214481 8.05059 0.00112247 8.03325 0.000549316C8.0224 0.000185013 8.01122 0 8 0L7.96775 0.000516891C7.94973 0.00109482 7.93173 0.00215626 7.91377 0.00370216L8 0C7.94938 0 7.89964 0.00376034 7.85104 0.0110188C7.83405 0.013628 7.81663 0.0167027 7.79927 0.0202408C7.77725 0.0246401 7.75556 0.0298338 7.73416 0.0357208C7.71854 0.0400972 7.70315 0.0447283 7.68786 0.0497379C7.6682 0.0561228 7.64842 0.0633154 7.62894 0.071104C7.6112 0.0781965 7.5939 0.0856791 7.57678 0.0936737C7.55611 0.103377 7.53571 0.113794 7.51572 0.124876C7.50361 0.131499 7.49139 0.138607 7.47929 0.145996C7.45372 0.161719 7.42887 0.178491 7.40484 0.196314C7.39879 0.200718 7.39293 0.205175 7.3871 0.209705C7.35153 0.23767 7.32136 0.264426 7.29289 0.292892L0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711C0.653377 9.06759 1.22061 9.09532 1.6129 8.7903L1.70711 8.70711L7 3.415V15C7 15.5523 7.44772 16 8 16Z"
          fill="#0000FF"/>
      </svg>
    </span>}
    {remove && <span className={styles.remove}
      onClick={remove}>
      <img src="/assets/icon-close-circle.svg"
        alt="close" />
    </span>}
  </div>
}

DividerWithActions.propTypes = {
  addNewField: PropTypes.func,
  goUp: PropTypes.func,
  goDown: PropTypes.func,
  remove: PropTypes.func,
  className: PropTypes.string,
}

export default DividerWithActions