import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import equal from 'fast-deep-equal';
import { Modal, Form, Button as SemanticButton } from 'semantic-ui-react';
import Button from '../../features/button/Button';
import Logo from '../../features/logo/Logo';
import LoginScreensFooter from '../../features/loginScreensFooter/LoginScreensFooter';
import controlledFetch from '../../app/controlledFetch';
import { withCookies, Cookies } from 'react-cookie';
import helpers from '../../app/helpers';
import styles from './Login.module.css';
import { extendErrorObject } from '../../app/errorHandler';
import { fetchUserProfile } from '../userProfile/userProfileSlice';
import { connect } from 'react-redux';

class LoginDev extends Component {
  constructor(props) {
    super(props);

    props.setShowSidebar(false);
    props.setHeaderContent(null);
    this.superadmin = helpers.isSuperAdmin();
    this.companyId = helpers.getCompanyId();
    this.animateClass = styles.logoAnimation;
    this.apiUrl = process.env.REACT_APP_API_URL.replace(/\/api$/, '');
    this.state = {
      login: '',
      password: '',
      cookies: props.cookies.getAll(),
      error: null,
      step: 0,
      showPass: false,
    };

    this.frontValidation = this.frontValidation.bind(this);
    this.goForward = this.goForward.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.receiveWindowMessage = this.receiveWindowMessage.bind(this);

    if (this.state.cookies.accessToken) {
      props.history.push(this.superadmin ? '/superadmins' : '/challenges');
    }
  }

  componentDidMount() {
    helpers.focusFirstInput();
    if (this.state.step === 0) {
      window.addEventListener('message', this.receiveWindowMessage, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveWindowMessage, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, role } = this.props;
    if (
      prevState.step !== this.state.step ||
      !equal(prevState.error, this.state.error)
    ) {
      helpers.focusFirstInput();
    }
    if (prevState.step > this.state.step) {
      this.animateClass = '';
    }
    if (role !== prevProps.role) {
      switch (role) {
        case 'ROLE_ADMIN':
        case 'ROLE_MEMBER':
          history.push('/challenges');
          break;
        case 'ROLE_SUPERADMIN':
          history.push('/superadmins');
          break;
        default:
          break;
      }
    }
  }

  goForward(data = {}) {
    const { cookies, fetchUserProfile } = this.props;
    cookies.set('accessToken', data.access_token, { path: '/' });
    cookies.set('refreshToken', data.refresh_token, { path: '/' });
    fetchUserProfile(this.superadmin);
  }

  frontValidation() {
    const { login, password } = this.state;
    const EmailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    switch (this.state.step) {
      case 0:
        if (login.length === 0 || !login.match(EmailRegExp)) {
          this.setState({ error: extendErrorObject('Email is not valid') });
          return false;
        }
        break;
      case 1:
        if (password.length < 8) {
          this.setState({
            error: extendErrorObject(
              'Password should have at least 8 characters',
            ),
          });
          return false;
        }
        break;
      default:
        break;
    }
    if (this.state.error) {
      this.setState({ error: null });
    }
    return true;
  }

  nextStep() {
    if (!this.frontValidation()) {
      return;
    }
    if (this.state.step < 2) {
      this.setState({
        error: null,
        step: this.state.step + 1,
      });
      return;
    }
  }

  receiveWindowMessage(event) {
    if (
      this.apiUrl === event.origin &&
      event.data &&
      typeof event.data === 'string'
    ) {
      const response = JSON.parse(event.data);
      this.goForward(response);
    }
  }

  submitForm() {
    if (!this.frontValidation()) {
      return;
    }
    const { login, password } = this.state;
    let url = this.superadmin
      ? `${process.env.REACT_APP_API_URL}/token/superadmin`
      : `${process.env.REACT_APP_API_URL}/token/user`;
    let fetchProps = this.superadmin
      ? {
          method: 'POST',
          body: JSON.stringify({
            email: login,
            plainPassword: password,
          }),
        }
      : {
          method: 'POST',
          body: JSON.stringify({
            companyId: this.companyId,
            email: login,
            plainPassword: password,
          }),
        };
    controlledFetch(url, fetchProps).then((data) => {
      if (data.access_token) {
        this.goForward(data);
      } else if (this.hash && data.status === 'OK') {
        helpers.goBack(this.props.history);
      } else {
        this.setState({ error: data });
      }
    });
  }

  renderButtons(continueAction) {
    const { history } = this.props;
    return (
      <Modal.Actions
        key="actions"
        step={this.state.step}
        className={[
          this.state.login.length === 0 ? styles.animation : '',
          styles.buttons,
        ].join(' ')}
      >
        <Button
          text="Continue"
          primary={true}
          className="higher"
          onKeyDown={(e) => {
            e.key === 'Enter' && continueAction();
          }}
          onClick={continueAction}
        />
        {this.state.step > 0 && (
          <SemanticButton
            floated="left"
            className="ui button underlined"
            onKeyDown={(e) => {
              e.key === 'Enter' && this.setState({ step: this.state.step - 1 });
            }}
            onClick={() => {
              this.setState({ step: this.state.step - 1 });
            }}
          >
            Go back
          </SemanticButton>
        )}
        {this.state.step === 1 && (
          <SemanticButton
            floated="right"
            className="ui button underlined"
            onKeyDown={(e) => {
              e.key === 'Enter' && history.push('/password-reset');
            }}
            onClick={() => {
              history.push('/password-reset');
            }}
          >
            Forgot password?
          </SemanticButton>
        )}
        {this.state.step === 0 && !this.superadmin && (
          <SemanticButton
            floated="right"
            className="ui button underlined"
            onKeyDown={(e) => {
              e.key === 'Enter' &&
                window.open(
                  `${this.apiUrl}/linkedinauth.html?companyId=${this.companyId}`,
                  '_blank',
                  'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                );
            }}
            onClick={() => {
              window.open(
                `${this.apiUrl}/linkedinauth.html?companyId=${this.companyId}`,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
              );
            }}
          >
            Log in with LinkedIn
          </SemanticButton>
        )}
        {this.state.step === 0 && !this.superadmin && (
          <SemanticButton
            floated="right"
            className="ui button underlined"
            onKeyDown={(e) => {
              e.key === 'Enter' &&
                window.open(
                  `${this.apiUrl}/oktaauth.html?companyId=${this.companyId}`,
                  '_blank',
                  'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                );
            }}
            onClick={() => {
              window.open(
                `${this.apiUrl}/oktaauth.html?companyId=${this.companyId}`,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
              );
            }}
          >
            Log in with Okta
          </SemanticButton>
        )}
        {this.state.step === 0 && !this.superadmin && (
          <SemanticButton
            className="ui button underlined"
            onKeyDown={(e) => {
              e.key === 'Enter' && history.push('/signin');
            }}
            onClick={() => {
              history.push('/signin');
            }}
          >
            Register
          </SemanticButton>
        )}
      </Modal.Actions>
    );
  }

  render() {
    const { error, login } = this.state;

    switch (this.state.step) {
      case 0:
        return (
          <div className={styles.background}>
            <Modal
              size="tiny"
              defaultOpen={true}
              centered={true}
              closeOnDimmerClick={false}
              onClose={() => {
                helpers.goBack(this.props.history);
              }}
              className={styles.modal}
            >
              <Logo className={this.animateClass} login size={100} />
              <h3
                className={`${styles.h3} ${
                  login.length === 0 ? styles.animation : ''
                }`}
              >
                Log In
              </h3>
              <Form
                error={!!error}
                className={login.length === 0 ? styles.animation : ''}
              >
                {!this.hash && this.state.step === 0 && (
                  <Form.Input
                    placeholder="Type your e-mail"
                    size="large"
                    name="login"
                    error={error}
                    value={this.state.login}
                    onKeyDown={(event) => {
                      event.key === 'Enter' && this.nextStep();
                    }}
                    onChange={(event) => {
                      this.setState({ error: null, login: event.target.value });
                    }}
                  />
                )}
              </Form>
              {this.renderButtons(this.nextStep)}
            </Modal>
            <LoginScreensFooter />
          </div>
        );
      case 1:
        return (
          <div className={styles.background}>
            <Modal
              size="tiny"
              defaultOpen={true}
              centered={true}
              closeOnDimmerClick={false}
              onClose={() => {
                helpers.goBack(this.props.history);
              }}
              className={styles.modal}
            >
              <Logo className={this.animateClass} login size={100} />
              <h3 className={styles.h3}>Enter password</h3>
              <p className={styles.description}>{this.state.login}</p>
              <Form error={!!error}>
                <Form.Input
                  icon
                  size="large"
                  placeholder=""
                  id="password"
                  type={this.state.showPass ? 'text' : 'password'}
                  name="password"
                  error={error}
                  value={this.state.password}
                  onKeyDown={(event) => {
                    event.key === 'Enter' && this.submitForm();
                  }}
                  onChange={(event) => {
                    this.setState({
                      error: null,
                      password: event.target.value,
                    });
                  }}
                >
                  <input />
                  <i
                    className="icon link"
                    onClick={() => {
                      this.setState({ showPass: !this.state.showPass });
                    }}
                  >
                    <img
                      src={
                        this.state.showPass
                          ? '/assets/eye-off.svg'
                          : '/assets/eye.svg'
                      }
                      alt="show password"
                    />
                  </i>
                </Form.Input>
              </Form>
              {this.renderButtons(this.submitForm)}
            </Modal>
            <LoginScreensFooter />
          </div>
        );
      default:
        return null;
    }
  }
}

LoginDev.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  history: PropTypes.object,
  role: PropTypes.string,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ userProfile }) => {
  return {
    role: userProfile.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: (superadmin) => {
      dispatch(fetchUserProfile(superadmin));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCookies(LoginDev)));
