import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionFile.module.css';
import { Form } from 'semantic-ui-react';
import InputUpload from '../inputUpload/InputUpload';

const QuestionFile = ({ id, question, answer, isEdit, saveAnswer }) => {
  const [val, setVal] = useState(answer ? answer : {});
  React.useEffect(() => {
    if (!val.imageUrl && answer) {
      setVal(answer);
    }
  });
  return (
    <div id={id}>
      <p className={styles.question}>{question}</p>
      {isEdit ? (
        <Form className={styles.answerContainer}>
          <p>Drag a photo into the box or click the button to upload.</p>
          <InputUpload
            endpoint={`/user/challenges/${id}/gallery`}
            imagePreviewUrl={val.imageUrl}
            fieldName="imageFile"
            backgroundImage="/assets/icon-upload.svg"
            removeEndpoint={`/user/challenges/${id}/gallery/${val.imageId}`}
            callback={({ data }) => {
              if (data && data.imageUrl) {
                saveAnswer(data);
                setVal(data);
              }
            }}
          />
        </Form>
      ) : (
        <span>
          {val.imageUrl ? (
            <img src={val.imageUrl} alt="input upload" />
          ) : (
            'no file uploaded'
          )}
        </span>
      )}
    </div>
  );
};

QuestionFile.defaultProps = {
  question: '',
  answer: null,
  isEdit: true,
};

QuestionFile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gallery: PropTypes.array,
  error: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isEdit: PropTypes.bool,
  saveAnswer: PropTypes.func,
};

export default QuestionFile;
