/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination
} from 'react-table'
import { useCookies } from 'react-cookie'
import { Input, Table as SemanticTable, Segment, Dimmer, Loader, Image, Icon, Card } from 'semantic-ui-react'
import IconUser from '../iconUser/IconUser'
import helpers from '../../app/helpers'
import DateField from '../dateField/DateField'
import Button from '../button/Button'
import styles from './Table.module.css'
import {
  setId,
  setEmail,
  setStatus,
  setShowModal,
  setPosition,
  setFullName,
  setTitle,
  setEnabled,
  setActiveUsers
} from '../contextMenu/contextMenuSlice'
import controlledFetch from '../../app/controlledFetch'
import Tile from '../tile/Tile'

function Table(props) {
  const superadmin = helpers.isSuperAdmin()
  const history = useHistory()
  const dispatch = useDispatch()
  const [cookies, setCookie] = useCookies(['accessToken', 'refreshToken', 'tableViewMode'])
  const userRole = useSelector(({userProfile}) => userProfile.role)
  if (!cookies.tableViewMode) {
    setCookie('tableViewMode', props.defaultTileView ? 'tile' : 'list')
  }
  const [search, setSearch] = useState('')
  const openEdit = useCallback((event, data) => {
    const positionX = event.pageX
    const positionY = event.pageY
    dispatch(setId(data.row.original.id))
    dispatch(setEmail(data.row.original.email))
    dispatch(setFullName(data.row.original.name ? data.row.original.name : `${data.row.original.firstName} ${data.row.original.lastName}`))
    dispatch(setStatus(data.row.original.status))
    dispatch(setPosition([positionX, positionY]))
    dispatch(setEnabled(data.row.original.enabled))
    dispatch(setTitle(data.row.original.title))
    dispatch(setActiveUsers(data.row.original.roleUserCount))
    dispatch(setShowModal(true))
    setTimeout(() => {
      const modal = document.querySelector('body > div.ReactModalPortal > div > div')
      if (modal) {
        const heightWithMargin = modal.offsetHeight + 16
        dispatch(setPosition([positionX, positionY - heightWithMargin]))
        dispatch(setShowModal(true))
      }
    }, 0)
  }, [dispatch])
  const editPath = data => props.editPath.replace(':id', data.row.original.id)
  const cols = [
    {
      Header: 'email',
      accessor: 'email',
    }
  ]
  const keys = props.rows.length > 0 ? Object.keys(props.rows[0]) : []
  if (keys.includes('firstName')) {
    cols.push({
      Header: 'First Name',
      accessor: 'firstName',
      className: 'tableColumnName',
      Cell: data => {
        const { firstName, lastName } = data.row.values
        if (lastName && lastName.length === 1) {
          return <div onClick={() => history.push(editPath(data))}
            className={styles.tableCellEmail}><IconUser firstName={data.row.original.email} className={styles.icon} />{String(data.row.original.email)}</div>
        }
        return <div onClick={() => history.push(editPath(data))}>
          <IconUser firstName={firstName}
            lastName={lastName} className={styles.icon}/>{helpers.firstCharUpper(String(data.value))}
        </div>
      }
    })
  }
  if (keys.includes('title')) {
    cols.push({
      Header: 'Challenge',
      accessor: 'title',
      className: 'tableColumnTitle',
      Cell: data => {
        return <div onClick={() => history.push(editPath(data))}>{helpers.firstCharUpper(String(data.value))}</div>
      }
    })
  }
  if (keys.includes('views')) {
    cols.push({
      Header: 'Views',
      accessor: 'views',
      Cell: data => {
        return <div onClick={() => history.push(editPath(data))}>
          {helpers.firstCharUpper(String(data.value))}
        </div>
      }
    })
  }
  if (keys.includes('solutions')) {
    cols.push({
      Header: 'Solutions',
      accessor: 'solutions',
      Cell: data => {
        return <div onClick={() => history.push(editPath(data))}>
          {helpers.firstCharUpper(String(data.value))}
        </div>
      }
    })
  }
  if (keys.includes('creator')) {
    cols.push({
      Header: 'Creator',
      accessor: 'creator',
      Cell: data => {
        return <div onClick={() => history.push(editPath(data))}>
          {helpers.firstCharUpper(String(data.value))}
        </div>
      }
    })
  }
  if (keys.includes('name')) {
    cols.push({
      Header: 'Company Name',
      accessor: 'name',
      className: 'tableColumnName',
      Cell: data => <span 
        onClick={() => {
          controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/companies/${data.row.original.id}/token/personanongrata007@${data.row.original.id}.lo`, {
            method: 'GET'
          }).then(res => {
            if (res.access_token && res.refresh_token) {
              setCookie('accessToken', res.access_token, { path: '/', domain: helpers.getCurrentDomain() })
              setCookie('refreshToken', res.refresh_token, { path: '/', domain: helpers.getCurrentDomain() })
              const win = window.open(`//${data.row.original.id}.${helpers.getCurrentDomain()}/users`, '_blank')
              win.focus()
            }
          })
        }}>{helpers.firstCharUpper(String(data.value))}</span>
    })
  }
  if (keys.includes('lastName')) {
    cols.push({
      Header: 'Last name',
      accessor: 'lastName',
      className: 'tableColumnLastName',
      Cell: data => <span onClick={() => history.push(editPath(data))}>{helpers.firstCharUpper(String(data.value))}</span>
    })
  }
  if (keys.includes('role') && !superadmin) {
    cols.push({
      Header: 'Role',
      accessor: 'role',
      Cell: data => <span className={styles.tableCellRole}>{helpers.firstCharUpper(helpers.humanReadableRole(data.value))}</span>
    })
  } 
  if (keys.includes('roleUserCount')) {
    cols.push({
      Header: 'Users',
      accessor: 'roleUserCount',
      Cell: data => {
        const text = helpers.firstCharUpper(String(data.value))
        return <span className={styles[`tableCell${text}`]}>{text}</span>
      }
    })
  }
  if (keys.includes('roleAdminCount')) {
    cols.push({
      Header: 'Admins',
      accessor: 'roleAdminCount',
      Cell: data => {
        const text = helpers.firstCharUpper(String(data.value))
        return <span className={styles[`tableCell${text}`]}>{text}</span>
      }
    })
  }
  if (keys.includes('countSolutions')) {
    cols.push({
      Header: 'Solutions',
      accessor: 'countSolutions',
      className: 'tableColumnCounter',
      Cell: data => {
        const text = helpers.firstCharUpper(String(data.value))
        return <span className={styles[`tableCell${text}`]}>{text}</span>
      }
    })
  }
  if (keys.includes('countComments')) {
    cols.push({
      Header: 'Comments',
      accessor: 'countComments',
      className: 'tableColumnCounter',
      Cell: data => {
        const text = data.value
        return <span className={styles[`tableCell${text}`]}>{text}</span>
      }
    })
  }
  if (keys.includes('countVotes')) {
    cols.push({
      Header: 'Upvotes',
      accessor: 'countVotes',
      className: 'tableColumnCounter',
      Cell: data => {
        const text = helpers.firstCharUpper(String(data.value))
        return <span className={styles[`tableCell${text}`]}>{text}</span>
      }
    })
  }
  if (keys.includes('updatedAt')) {
    cols.push({
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: data => <DateField datetime={data.value}/>
    });
  }
  if(props.rows.length) {
    cols.push({
      Header: 'Edit',
      accessor: 'edit',
      className: 'tableColumnEdit',
      disableSortBy: true,
      Cell: data => <div className={styles.tableCellEdit}
        onClick={event => {
          openEdit(event, data)
        }}><svg width="4"
          height="16"
          viewBox="0 0 4 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M2 4C0.9 4 0 3.1 0 2C0 0.9 0.9 0 2 0C3.1 0 4 0.9 4 2C4 3.1 3.1 4 2 4ZM2 6C3.1 6 4 6.9 4 8C4 9.1 3.1 10 2 10C0.9 10 0 9.1 0 8C0 6.9 0.9 6 2 6ZM2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12Z"
            fill="#0000FF"
            className={styles.dots}/>
        </svg>
      </div>,
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => cols, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    page,
    rows,
    pageCount,
  } = useTable({ 
    columns, 
    data: props.rows, 
    initialState: { 
      pageSize: 20, 
      hiddenColumns: ['email'] 
    }}, 
  useGlobalFilter, 
  useSortBy, 
  usePagination
  )

  const filter = (e) => {
    const value = e.target.value || undefined
    setGlobalFilter(value)
    setSearch(value)
  }

  return (
    <div>
      <div className={styles.top}>
        <div>
          {props.searchInput && <div>
            <Input 
              onChange={filter} 
              placeholder="Search..." 
              className={styles.inputSearch} 
              value={search}
              size="large" 
              icon={<Icon 
                link 
                onClick={() => { setSearch(''); setGlobalFilter(undefined) }} 
                hidden={!search}>
                <Image src="/assets/icon-close.svg" />
              </Icon>
              }/>
          </div>}
        </div>
        <div>
          {props.buttonToAction && props.buttonAddUserText && <Button to={props.buttonToAction}
            text={props.buttonAddUserText}
            primary={true} />}
        </div>
      </div>
      {props.defaultTileView !== undefined && cookies.tableViewMode !== 'list' && <div>
        {rows.length > 0 && <Card.Group itemsPerRow={2}
          className={[
            styles.tiles,
            userRole === 'ROLE_MEMBER' ? styles.member : ''
          ].join(' ')}>
          {rows.map(row => <Tile key={row.original.id}
            openEdit={e => { openEdit(e, { row }) }}
            {...row.original} />
          )}
        </Card.Group>}
      </div>}
      {(props.defaultTileView === undefined || cookies.tableViewMode === 'list') && <SemanticTable {...getTableProps()}
        sortable
        basic='very'
        selectable
        unstackable>
        <SemanticTable.Header>
          {headerGroups.map(headerGroup => (
            <SemanticTable.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <SemanticTable.HeaderCell 
                  { ...column.getHeaderProps(column.getSortByToggleProps()) } 
                  sorted={column.isSorted ? (column.isSortedDesc ? 'descending' : 'ascending') : null}
                  className={`${styles.sticky} ${styles[`${column.id}Header`]} ${column.isSorted ? styles.sorted : ''}`}
                >
                  {column.render('Header')}
                </SemanticTable.HeaderCell>
              ))}
            </SemanticTable.Row>
          ))}
        </SemanticTable.Header>
        <SemanticTable.Body {...getTableBodyProps()}>
          {props.loading 
            ?<SemanticTable.Row>
              <SemanticTable.Cell colSpan={columns.length}>
                <Segment>
                  <Dimmer active
                    inverted>
                    <Loader size='small'>Loading</Loader>
                  </Dimmer>
                  <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
              </SemanticTable.Cell>
            </SemanticTable.Row>
            : page.length === 0
              ? <SemanticTable.Row>
                <SemanticTable.Cell colSpan={columns.length}>No results</SemanticTable.Cell>
              </SemanticTable.Row>
              : page.map(row => {
                prepareRow(row)
                return (
                  <SemanticTable.Row {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <SemanticTable.Cell {...cell.getCellProps()}
                        className={cell.column.className}>{cell.render('Cell')}</SemanticTable.Cell>
                    })}
                  </SemanticTable.Row>
                )
              })
          }
        </SemanticTable.Body>
      </SemanticTable>}
      {(props.defaultTileView === undefined || cookies.tableViewMode === 'list') && <div className={styles.pagination}>
        <Button
          onClick={() => previousPage()}
          size="mini"
          disabled={!canPreviousPage}
          text={<svg width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            className={styles.previous}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L9 1"
              strokeWidth="1.5"/>
          </svg>} 
        />
        <span>
          {pageIndex + 1} of {pageCount}
        </span>
        <Button
          onClick={() => nextPage()}
          size="mini"
          disabled={!canNextPage}
          text={<svg width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            className={styles.next}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L9 1"
              strokeWidth="1.5"/>
          </svg>} 
        />
      </div>}
    </div>
  )
}

Table.propTypes = {
  buttonAddUserText: PropTypes.string,
  editPath: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  buttonToAction: PropTypes.string,
  loading: PropTypes.bool,
  searchInput: PropTypes.bool,
  filterSelect: PropTypes.bool,
  switchTileListView: PropTypes.bool,
  defaultTileView: PropTypes.bool,
}

export default Table