import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { toast } from 'react-toastify';
import { withRouter, NavLink } from 'react-router-dom';
import {
  fetchChallengesList,
  fetchChallenge,
  setCurrent,
  setUploadFileDisabled,
} from '../challengesList/challengesSlice';
import { Confirm, Form } from 'semantic-ui-react';
import helpers from '../../app/helpers';
import Button from '../../features/button/Button';
import InputTag from '../../features/inputTag/InputTag';
import controlledFetch from '../../app/controlledFetch';
import styles from './ChallengeForm.module.css';
import AppStyles from '../../App.module.css';
import { notificationOptions } from '../../app/config';
import InputUpload from '../../features/inputUpload/InputUpload';
import Editor from '../../features/editor/Editor';
import NoMatch from '../noMatch/NoMatch';
import QuestionSelect from '../../features/questionSelect/QuestionSelect';
import DividerWithActions from '../../features/dividerWithActions/DividerWithActions';
import ChallengeView from '../challengeView/ChallengeView';
import QuestionStatic from '../../features/questionStatic/QuestionStatic';
import Counter from '../../features/counter/Counter';

const TITLE_MAX_LENGTH = 150;

class ChallengeForm extends Component {
  constructor(props) {
    super(props);

    this.initial = {
      id: null,
      title: '',
      description: '',
      status: null,
      content: null,
      thumbnailUrl: '',
      error: null,
      gallery: [],
      tags: [],
      questionnaire: [{ id: helpers.getUniqId() }],
      showBackArrowConfirmModal: false,
      showConfirmModalOnClickBackArrow: false,
    };

    const {
      title,
      description,
      content,
      thumbnailUrl,
      gallery,
      tags,
      status,
      questionnaire,
    } = props.current || {};

    this.state = props.match.params.id
      ? {
          id: Number(props.match.params.id) || this.initial.id,
          title: title || this.initial.title,
          description: description || this.initial.description,
          status: status || this.initial.status,
          content: content || this.initial.content,
          thumbnailUrl: thumbnailUrl || this.initial.thumbnailUrl,
          error: props.error || this.initial.error,
          gallery: gallery || this.initial.gallery,
          tags: tags || this.initial.tags,
          questionnaire: questionnaire || this.initial.questionnaire,
          showBackArrowConfirmModal: this.initial.showBackArrowConfirmModal,
        }
      : this.initial;

    this.step = Number(props.match.params.step) || 1;
    this.callback = this.callback.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.setContent = this.setContent.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.createEmptyChallenge = this.createEmptyChallenge.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderSecondHeader = this.renderSecondHeader.bind(this);
    this.getGallery = this.getGallery.bind(this);
    this.addNewField = this.addNewField.bind(this);
    this.goUp = this.goUp.bind(this);
    this.goDown = this.goDown.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.openBackArrowConfirmModal = this.openBackArrowConfirmModal.bind(this);
    this.closeBackArrowConfirmModal = this.closeBackArrowConfirmModal.bind(
      this,
    );

    props.setShowSidebar(false);
    props.setHeaderContent(this.renderHeader());
    props.setSecondHeaderContent(this.renderSecondHeader());
  }

  componentWillUnmount() {
    this.props.setCurrent(null);
    this.props.setSecondHeaderContent(null);
  }

  componentDidMount() {
    if (this.state.id) {
      this.props.fetchChallenge({
        id: this.state.id,
        userRole: this.props.userRole,
      });
    } else {
      this.props.setCurrent(null);
      this.createEmptyChallenge();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params !== prevProps.match.params) {
      this.step = Number(this.props.match.params.step) || 1;
    }
    if (!prevProps.current && !equal(this.props.current, prevProps.current)) {
      const {
        title,
        status,
        description,
        content,
        thumbnailUrl,
        gallery,
        tags,
        questionnaire,
      } = this.props.current || this.initial;
      this.setState(
        {
          title,
          status,
          description,
          content,
          thumbnailUrl,
          gallery,
          tags,
          questionnaire:
            questionnaire && questionnaire.length > 0
              ? questionnaire
              : this.state.questionnaire,
        },
        () => {
          this.props.setSecondHeaderContent(this.renderSecondHeader());
        },
      );
    } else if (this.props.current) {
      if (!equal(this.props.current.gallery, prevProps.current.gallery)) {
        this.setState({
          gallery: this.props.current.gallery,
        });
      } else if (
        !equal(this.props.current.thumbnailUrl, prevProps.current.thumbnailUrl)
      ) {
        this.setState({
          thumbnailUrl: this.props.current.thumbnailUrl,
        });
      }
    }
    if (prevProps.match.params.step !== this.props.match.params.step) {
      this.props.setSecondHeaderContent(this.renderSecondHeader());
    }
    if (
      !equal(prevState.title, this.state.title) ||
      !equal(prevState.description, this.state.description) ||
      !equal(prevState.questionnaire, this.state.questionnaire) ||
      !equal(prevState.tags, this.state.tags) ||
      !equal(prevState.content, this.state.content)
    ) {
      console.log('this.state: ', this.state);
      this.setState({ showConfirmModalOnClickBackArrow: true });
    }
    if (
      this.state.showBackArrowConfirmModal !==
      prevState.showBackArrowConfirmModal
    ) {
      this.props.setSecondHeaderContent(this.renderSecondHeader());
    }

    const uploadFileFields = this.state.questionnaire.filter(
      (quest) => quest.type === 'upload_file',
    );
    this.props.setUploadFileDisabled(uploadFileFields.length > 1);
  }

  async getGallery() {
    const data = await this.props.fetchChallenge({
      id: this.state.id,
      userRole: this.props.userRole,
    });
    return data.gallery;
  }

  nextStep() {
    if (this.step < 4) {
      this.submitForm(this.step === 3 && this.state.status === 'PUBLISHED');
      return;
    }
  }

  previousStep() {
    const { history, match, userRole } = this.props;
    if (this.step > 1) {
      history.push(
        match.path.replace(':id', this.state.id).replace(':step?', --this.step),
      );
      return;
    }
    this.props.fetchChallengesList(userRole);
    helpers.goBack(history, '/challenges');
  }

  openBackArrowConfirmModal() {
    if (this.state.showConfirmModalOnClickBackArrow) {
      console.log('tutaj2');
      this.setState({ showBackArrowConfirmModal: true });
    } else {
      console.log('tutaj');
      this.previousStep();
    }
  }
  closeBackArrowConfirmModal() {
    this.setState({ showBackArrowConfirmModal: false });
  }

  renderHeader() {
    return (
      <>
        <span
          onClick={this.openBackArrowConfirmModal}
          className={AppStyles.backLink}
        >
          <img src="/assets/arrow-left.svg" alt="arrow left" />
        </span>
        {this.props.defaultHeader}
      </>
    );
  }

  renderSecondHeader() {
    return [
      <div key="left-header-side" className={styles.header}>
        <Confirm
          open={this.state.showBackArrowConfirmModal}
          onCancel={this.closeBackArrowConfirmModal}
          onConfirm={this.closeBackArrowConfirmModal}
          content="Do you want to leave without saving?"
          confirmButton={
            <NavLink
              primary="false"
              to="/challenges"
              className={styles.quitLink}
            >
              OK
            </NavLink>
          }
        />
        <div className={styles.breadcrumbs}>
          <span>
            {this.state.status !== 'NEW'
              ? 'Edit challenge'
              : 'Create challenge'}
          </span>
          {/* {this.step === 1 && (
            <span className={styles.active}>Basic information</span>
          )}
          {this.step === 2 && (
            <span className={styles.active}>Challenge description</span>
          )}
          {this.step === 3 && (
            <span className={styles.active}>Challenge questionnaire</span>
          )}
          {this.step === 4 && (
            <span className={styles.active}>Challenge preview</span>
          )} */}
        </div>
      </div>,
      <div key="right-header-side">
        {this.step === 1 ||
          (this.state.status === 'PUBLISHED' && (
            <NavLink
              to="/challenges"
              className={styles.quitLink}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showBackArrowConfirmModal: true });
              }}
            >
              {this.state.status !== 'PUBLISHED' ? 'Quit' : 'Drop changes'}
            </NavLink>
          ))}
        {this.step > 1 && this.state.status !== 'PUBLISHED' && (
          <span
            onClick={async () => {
              await this.submitForm(true);
            }}
            className={styles.quitLink}
          >
            Save & quit
          </span>
        )}
        {this.state.status !== 'PUBLISHED' && this.step < 4 && (
          <Button
            text="Previous step"
            primary={true}
            onClick={this.previousStep}
            disabled={this.step < 2}
          />
        )}
        {this.state.status !== 'PUBLISHED' && this.step < 4 && (
          <Button
            text={'Next step'}
            primary={true}
            onClick={this.nextStep}
            disabled={this.step > 3}
          />
        )}
        {(this.step === 4 || this.state.status === 'PUBLISHED') && (
          <Button
            text={
              this.state.status === 'PUBLISHED' ? 'Save and Close' : 'Publish'
            }
            primary={true}
            onClick={() =>
              this.setState({ status: 'PUBLISHED' }, () => {
                this.submitForm(true);
              })
            }
          />
        )}
      </div>,
    ];
  }

  setContent(content) {
    this.setState({
      content,
    });
  }

  callback(e) {
    const val = JSON.parse(e.target.value || '[]');

    this.setState({
      error: null,
      tags: val.map((tag) => tag.value),
    });
  }

  createEmptyChallenge() {
    controlledFetch(`${process.env.REACT_APP_API_URL}/admin/challenges`, {
      method: 'POST',
      body: JSON.stringify([{}]),
    }).then((data) => {
      const challengeId = data.data && data.data[0] ? data.data[0].id : null;
      if (challengeId) {
        this.setState({
          id: Number(challengeId),
        });
        this.props.history.push(`/challenge/edit/${challengeId}/1`);
      }
    });
  }

  isFormValid() {
    if (this.step === 2) {
      return true;
    }
    const error = {};
    let result = true;
    let firstErrorId = null;
    if (this.step === 1) {
      error.errorFields = {};
      if (!this.state.title) {
        error.errorFields.title = 'Title can’t be empty';
        result = false;
      }
      if (!this.state.description) {
        error.errorFields.description = 'Description can’t be empty';
        result = false;
      }
    }

    if (this.step === 3) {
      const questionsTitleArray = [];

      this.state.questionnaire.forEach((questionnaire) => {
        if (questionsTitleArray.includes(questionnaire.question)) {
          error[questionnaire.id] = 'Questions cannot have the same title';
          console.log('error');
          if (result) {
            firstErrorId = questionnaire.id;
          }
          result = false;
        } else {
          questionsTitleArray.push(questionnaire.question);
        }

        if (!questionnaire.question) {
          error[questionnaire.id] = 'Question title can’t be empty';
          if (result) {
            firstErrorId = questionnaire.id;
          }
          result = false;
        } else {
          if (['dropdown', 'checkbox', 'radio'].includes(questionnaire?.type)) {
            if (
              !Array.isArray(questionnaire?.possibleAnswers) ||
              questionnaire.possibleAnswers?.length < 2
            ) {
              error[questionnaire.id] =
                'There must be at least 2 possible answers';

              if (result) {
                firstErrorId = questionnaire.id;
              }
              result = false;
            } else {
              const areDuplicatesAnswers = (arr) => {
                const strings = arr.map((el) => el.value);
                const duplicates = strings.filter(
                  (item, index) => strings.indexOf(item) !== index,
                );

                return !!duplicates.length;
              };

              if (areDuplicatesAnswers(questionnaire.possibleAnswers)) {
                error[questionnaire.id] = 'Possible answers cannot be the same';

                if (result) {
                  firstErrorId = questionnaire.id;
                }
                result = false;
              }

              if (
                questionnaire.possibleAnswers.findIndex((el) => !el.value) > -1
              ) {
                error[questionnaire.id] = 'Possible answers can’t be empty';

                if (result) {
                  firstErrorId = questionnaire.id;
                }
                result = false;
              }
            }
          }
        }
      });
    }

    console.log(error);

    if (!result) {
      this.setState({ error });
      if (firstErrorId) {
        var element = document.getElementById(firstErrorId);
        if (element) {
          // smooth scroll to element and align it at the bottom
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
    return result;
  }

  submitForm(saveAndQuit = false) {
    const {
      title,
      description,
      content,
      id,
      status,
      gallery,
      questionnaire,
      tags,
    } = this.state;
    const { history, match, userRole } = this.props;
    let gal = gallery ? [...gallery] : [];
    if (!this.isFormValid()) {
      return;
    }
    const payload = {
      status: status === 'NEW' ? 'DRAFT' : status,
    };
    if (title !== null) {
      payload.title = title;
    }
    if (description !== null) {
      payload.description = description;
    }
    if (questionnaire !== null) {
      payload.questionnaire = questionnaire;
    }
    if (tags !== null) {
      payload.tags = tags;
    }
    if (content !== null) {
      if (content) {
        const images = content.match(/;;[0-9]+;;/gm);
        if (images && gallery) {
          images.forEach((imageSrc) => {
            gal.forEach((image, index) => {
              if (imageSrc === `;;${image.imageId};;`) {
                gal.splice(index, 1);
              }
            });
          });
        }
      }
      if (questionnaire.findIndex((el) => el.type === 'upload_file') === -1) {
        gal.forEach((image) => {
          controlledFetch(
            `${process.env.REACT_APP_API_URL}/admin/challenges/${id}/gallery/${image.imageId}`,
            {
              method: 'DELETE',
            },
          );
        });
      }
      payload.content = content;
    }
    controlledFetch(`${process.env.REACT_APP_API_URL}/admin/challenges/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    }).then((data) => {
      if (data.status && data.status === 'OK') {
        if (status === 'PUBLISHED') {
          toast.success(
            id
              ? `Challenge updated`
              : `👏 You added a new challenge! We‘ve sent the content invitation.`,
            notificationOptions,
          );
        }
        if (this.step >= 4 || saveAndQuit) {
          this.props.fetchChallengesList(userRole);
          helpers.goBack(history, '/challenges');
        } else {
          history.push(
            match.path
              .replace(':id', this.state.id)
              .replace(':step?', ++this.step),
          );
        }
      } else {
        this.setState({ error: data });
      }
    });
  }

  addNewField(index = this.state.questionnaire.length) {
    const questionnaire = [...this.state.questionnaire];
    questionnaire.splice(index + 1, 0, {
      id: helpers.getUniqId(),
      type: '',
    });
    this.setState({
      questionnaire:
        questionnaire.length > 0
          ? questionnaire
          : [{ id: helpers.getUniqId() }],
    });
  }

  removeField(id) {
    const questionnaire = this.state.questionnaire.filter((q) => q.id !== id);
    this.setState({
      questionnaire,
    });
  }

  destructuringSwap(list, iA, iB) {
    [list[iA], list[iB]] = [list[iB], list[iA]];
    return list;
  }

  goUp(index) {
    if (index > 0) {
      this.setState({
        questionnaire: this.destructuringSwap(
          [...this.state.questionnaire],
          index,
          index - 1,
        ),
      });
    }
  }

  goDown(index) {
    if (index < this.state.questionnaire.length) {
      this.setState({
        questionnaire: this.destructuringSwap(
          [...this.state.questionnaire],
          index,
          index + 1,
        ),
      });
    }
  }

  render() {
    const {
      error,
      focus,
      content,
      title,
      description,
      thumbnailUrl,
      id,
      gallery,
    } = this.state;
    if (
      this.props.error &&
      this.props.error.content === 'Challenge id not found.'
    ) {
      return <NoMatch />;
    }
    switch (this.step) {
      case 1:
        return (
          <div className={styles.wrapper}>
            <h1>Alright, let’s create a challenge!</h1>
            <p>Please complete the following fields.</p>
            <Form error={!!error}>
              <div className={styles.inputWrapper}>
                <div className={styles.helperText}>
                  <Counter
                    limit={TITLE_MAX_LENGTH}
                    length={title?.length || 0}
                    error={title?.length === TITLE_MAX_LENGTH}
                  />
                </div>
                <Form.Input
                  fluid
                  label="Title"
                  size="large"
                  placeholder="Title"
                  className={styles.relative}
                  error={
                    error && error.errorFields && error.errorFields.title
                      ? error.content || error.errorFields.title
                      : null
                  }
                  value={title || ''}
                  onChange={(classObj, data) => {
                    if (data.value.length > TITLE_MAX_LENGTH) {
                      return;
                    }

                    const newState = { title: data.value };
                    if (error && error.errorFields && error.errorFields.title) {
                      newState.error = null;
                    }
                    this.setState(newState);
                  }}
                />
              </div>
              <Form.Field>
                <label>Featured photo</label>
                <InputUpload
                  endpoint={`/admin/challenges/${id}/thumbnail`}
                  className={styles.upload}
                  imagePreviewUrl={thumbnailUrl}
                  fieldName="thumbnailFile"
                  callback={() => {
                    this.props.fetchChallenge({
                      id: this.state.id,
                      userRole: this.props.userRole,
                    });
                  }}
                />
              </Form.Field>
              <div className={styles.inputWrapper}>
                <Form.TextArea
                  label="Brief description"
                  size="large"
                  placeholder="Type in text"
                  className={styles.relative}
                  defaultValue={description}
                  error={
                    error && error.errorFields && error.errorFields.description
                      ? error.content || error.errorFields.description
                      : null
                  }
                  onChange={(classObj, data) => {
                    const newState = { description: data.value };
                    if (
                      error &&
                      error.errorFields &&
                      error.errorFields.description
                    ) {
                      newState.error = null;
                    }
                    this.setState(newState);
                  }}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Form.Field
                  className={focus === 'content' ? styles.focus : ''}
                  onClick={() => {
                    this.setState({ focus: 'content' });
                  }}
                >
                  <label>Challenge categories</label>
                  <InputTag
                    placeholder="Challenge categories"
                    callback={this.callback}
                    value={this.state.tags}
                    className={styles.input}
                    error={
                      error && error.errorFields && error.errorFields.tags
                        ? error
                        : null
                    }
                  />
                </Form.Field>
              </div>
            </Form>
          </div>
        );
      case 2:
        return (
          <div className={[styles.wrapper, styles.editor].join(', ')}>
            <Editor
              setContent={this.setContent}
              content={content}
              gallery={gallery}
              id={id}
              setError={(error) => {
                toast.error(error, notificationOptions);
              }}
              callback={this.getGallery}
            />
          </div>
        );
      case 3:
        return (
          <div className={styles.wrapper}>
            <QuestionStatic
              id="solution-title"
              question="Solution title"
              answerInfo="Short answer text specified by the user"
            />
            {this.state.questionnaire.map((questionnaire, index) => (
              <QuestionSelect
                callback={(data) => {
                  const questions = [...this.state.questionnaire];
                  questions[index] = {
                    ...questionnaire,
                    ...data,
                  };
                  this.setState({
                    questionnaire: questions,
                  });
                }}
                id={questionnaire.id}
                error={error}
                key={
                  questionnaire.id ||
                  `form${Math.random() * new Date().getTime()}`
                }
                type={questionnaire.type}
                defaultValue={questionnaire}
                addNewField={() => {
                  this.addNewField(index - 1);
                }}
                goUp={
                  index === 0
                    ? null
                    : () => {
                        this.goUp(index);
                      }
                }
                goDown={
                  index === this.state.questionnaire.length - 1
                    ? null
                    : () => {
                        this.goDown(index);
                      }
                }
                remove={
                  this.state.questionnaire.length > 1
                    ? () => {
                        this.removeField(questionnaire.id);
                      }
                    : null
                }
              />
            ))}
            <DividerWithActions
              addNewField={() => {
                this.addNewField();
              }}
              className="fullWidth"
            />
          </div>
        );
      case 4:
        return <ChallengeView />;
      default:
        return null;
    }
  }
}

ChallengeForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  error: PropTypes.object,
  current: PropTypes.object,
  userRole: PropTypes.string.isRequired,
  fetchChallengesList: PropTypes.func.isRequired,
};

const mapStateToProps = ({ challenges, userProfile }) => {
  return {
    userRole: userProfile.role,
    error: challenges.error,
    current: challenges.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChallengesList: (userRole) => {
      dispatch(fetchChallengesList(userRole));
    },
    fetchChallenge: (props) => {
      return dispatch(fetchChallenge(props));
    },
    setCurrent: (props) => {
      dispatch(setCurrent(props));
    },
    setUploadFileDisabled: (props) => {
      dispatch(setUploadFileDisabled(props));
    },
  };
};

const ChallengeFormWithRouter = withRouter(ChallengeForm);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeFormWithRouter);
