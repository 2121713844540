import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionDropdown.module.css';
import { Dropdown } from 'semantic-ui-react';

const QuestionDropdown = ({
  anchor,
  answer = '',
  question = '',
  possibleAnswers = [],
  isEdit = true,
  saveAnswer,
}) => {
  const [localAnswer, setLocalAnswer] = useState(
    answer || possibleAnswers[0].value,
  );

  useEffect(() => {
    saveAnswer(localAnswer);
  }, []);

  return (
    <div id={anchor}>
      <p className={styles.question}>{question}</p>
      {isEdit ? (
        <Dropdown
          size="large"
          placeholder={question}
          value={localAnswer}
          selection
          options={possibleAnswers
            .filter((ans) => !!ans.value)
            .map((ans) => ({
              value: ans.value,
              text: ans.label,
              key: ans.id,
            }))}
          onChange={(e, { value }) => {
            setLocalAnswer(value);
            saveAnswer(value);
          }}
        />
      ) : (
        <p className={styles.answer}>{answer}</p>
      )}
    </div>
  );
};

QuestionDropdown.propTypes = {
  anchor: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.string,
  possibleAnswers: PropTypes.array,
  saveAnswer: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default QuestionDropdown;
