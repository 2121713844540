import { configureStore } from '@reduxjs/toolkit'
import usersReducer from '../views/usersList/usersSlice'
import superAdminsReducer from '../views/superAdminsList/superAdminsSlice'
import contextMenuReducer from '../features/contextMenu/contextMenuSlice'
import companiesReducer from '../views/companiesList/companiesSlice'
import challengesReducer from '../views/challengesList/challengesSlice'
import userProfileReducer from '../views/userProfile/userProfileSlice'
import settingsReducer from '../views/settingsView/settingsSlice'

export default configureStore({
  reducer: {
    users: usersReducer,
    superAdmins: superAdminsReducer,
    contextMenu: contextMenuReducer,
    companies: companiesReducer,
    challenges: challengesReducer,
    userProfile: userProfileReducer,
    settings: settingsReducer
  },
})
