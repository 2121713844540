import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../button/Button';
import InputTag from '../inputTag/InputTag';
import controlledFetch from '../../app/controlledFetch';
import { fetchSuperAdminsList } from '../../views/superAdminsList/superAdminsSlice';
import { Modal } from 'semantic-ui-react';
import styles from './SuperAdminAddContent.module.css';
import { toast } from 'react-toastify';
import { notificationOptions } from '../../app/config';
import { withRouter } from 'react-router-dom';
import helpers from '../../app/helpers';
class SuperAdminAddContent extends Component {
  constructor() {
    super();
    this.state = {
      email: [],
      error: null,
    };

    this.callback = this.callback.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  callback(e) {
    const val = JSON.parse(e.target.value || '[]');

    this.setState({
      error: null,
      email: val.map((tag) => tag.value),
    });
  }

  submitForm() {
    controlledFetch(`${process.env.REACT_APP_API_URL}/superadmin/superadmins`, {
      method: 'POST',
      body: JSON.stringify(
        this.state.email.map((email) => ({
          email,
          role: 'ROLE_SUPERADMIN',
        })),
      ),
    }).then((data) => {
      if (data.status && data.status === 'OK') {
        this.props.fetchSuperAdminsList();
        helpers.goBack(this.props.history);
        toast.success(
          `SuperAdmin${this.state.email.length > 1 ? 's' : ''} added`,
          notificationOptions,
        );
      } else {
        this.setState({
          error: data,
        });
      }
    });
  }

  render() {
    const { error } = this.state;
    return [
      <Modal.Header key="header">Add SuperAdmin</Modal.Header>,
      <Modal.Content key="content">
        <Modal.Description>
          <p>
            Enter new SuperAdmin email address. We’ll send the invites. Yes, you
            can enter multiple emails.
          </p>
          <InputTag
            placeholder="E-mail"
            callback={this.callback}
            pattern={
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }
            className={styles.input}
            error={error}
          ></InputTag>
        </Modal.Description>
      </Modal.Content>,
      <Modal.Actions key="actions">
        <Button
          text="Cancel"
          secondary={true}
          onClick={() => {
            helpers.goBack(this.props.history);
          }}
        />
        <Button
          text="Add SuperAdmin"
          primary={true}
          disabled={this.state.email.length === 0}
          onClick={this.submitForm}
        />
      </Modal.Actions>,
    ];
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSuperAdminsList: () => {
      dispatch(fetchSuperAdminsList());
    },
  };
};

SuperAdminAddContent.propTypes = {
  fetchSuperAdminsList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const SuperAdminAddContentWithRouter = withRouter(SuperAdminAddContent);
export default connect(
  null,
  mapDispatchToProps,
)(SuperAdminAddContentWithRouter);
