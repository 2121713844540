import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Board from '../../features/board/Board';
import styles from './SolutionsKanban.module.css';
import controlledFetch from '../../app/controlledFetch';
import { withRouter } from 'react-router-dom';
import { Form, Select } from 'semantic-ui-react';
import helpers from './../../app/helpers';

class SolutionsKanban extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      extended: [0],
      search: '',
      searchArray: [],
      data: [],
    };

    this.fetchSolutions();

    props.setShowSidebar(true);
    props.setHeaderContent(props.defaultHeader);
  }

  fetchSolutions() {
    controlledFetch(`${process.env.REACT_APP_API_URL}/user/challenges`, {
      method: 'GET',
    }).then((challengesData) => {
      const challengesTitles = {};
      challengesData.data.forEach((challenge) => {
        challengesTitles[challenge.id] = challenge.title;
      });

      controlledFetch(`${process.env.REACT_APP_API_URL}/user/solutions`, {
        method: 'GET',
      }).then((solutions) => {
        if (solutions && solutions.data) {
          const grouped = Object.values(
            solutions.data.reduce(function (r, a) {
              r[a.challengeId] = r[a.challengeId] || [];
              r[a.challengeId].push(a);
              return r;
            }, Object.create(null)),
          ).reverse();

          const data = Object.values(
            grouped.map((solutions) => {
              const challengeId = solutions[0].challengeId;

              if (!challengesTitles[challengeId]) {
                return null;
              }
              return {
                challengeTitle: challengesTitles[challengeId],
                challengeId,
                columns: this.props.columns.map((column, index) => ({
                  id: column.id,
                  title: column.title,
                  color: column.color,
                  quotes: solutions
                    .filter((sol) => {
                      if (
                        index === 0 &&
                        (!sol.column ||
                          this.props.columns.filter(
                            (col) => col.id === sol.column,
                          ).length === 0)
                      ) {
                        return true;
                      }
                      return sol.column === column.id;
                    })
                    .map((sol, i) => ({
                      ...sol,
                      id: String(sol.id),
                      url: `/challenge/view/${challengeId}/solution/${sol.id}`,
                    })),
                })),
              };
            }),
          ).filter((obj) => !!obj);

          challengesData.data.forEach((challenge) => {
            if (
              typeof data.find(function (d) {
                return d.challengeId === challenge.id;
              }) === 'undefined'
            ) {
              data.push({
                challengeTitle: challenge.title,
                challengeId: challenge.id,
                columns: null,
              });
            }
          });

          // data.sort((a, b) => {
          //   return a.challengeId < b.challengeId ? -1 : 1;
          // })

          let extended = this.state.extended;
          if (this.props.match.params.challengeId) {
            data.forEach((solution, index) => {
              if (
                solution &&
                solution.challengeId === this.props.match.params.challengeId
              ) {
                extended = [index];
              }
            });
          }
          this.setState({
            data,
            extended,
          });
        }
      });
    });
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div>
          {/* <div>
              <Select
                defaultValue="all"
                options={[
                  {
                    text: 'All solutions',
                    value: 'all',
                  },
                ]}
              />
            </div> */}
          {/* <div> */}
          <Form.Input
            size="large"
            placeholder="Search"
            value={this.state.search}
            onChange={(e) => {
              this.setState({
                search: e.target.value,
                searchArray: e.target.value.split(' ').filter((e) => e),
              });
            }}
          />
          {/* </div> */}
        </div>
        <div>
          <p className={styles.textMuted} style={{ marginBottom: 20 }}>
            You can move solutions by drag-and-drop between columns
          </p>
        </div>
        {this.state.data.length > 0 &&
          this.state.data.map((challenge, i) => {
            if (!challenge) {
              return null;
            }

            let hasSearchingResult = false;
            let hasQuotes = false;
            if (challenge.columns) {
              challenge.columns.forEach((column) => {
                hasQuotes = hasQuotes || column.quotes?.length > 0;
                if (hasQuotes) {
                  column.quotes.forEach((quote) => {
                    if (
                      quote.title &&
                      helpers.includesOneOf(
                        quote.title.toLowerCase(),
                        this.state.searchArray,
                      )
                    ) {
                      hasSearchingResult = true;
                    }
                  });
                }
              });
            }

            const toggleAccordion = this.state.extended.includes(i);
            if (!hasQuotes) {
              return null;
            } else if (!toggleAccordion && this.state.search !== '') {
              const extended = [...this.state.extended];
              extended.push(i);
              this.setState({ extended });
            }

            return (
              <div key={challenge.challengeId}>
                <div
                  className={[
                    styles.accordion,
                    toggleAccordion ? '' : styles.collapsed,
                    !hasSearchingResult ? styles.collapsed : '',
                  ].join(' ')}
                >
                  <img src="/assets/arrow.svg" alt="arrow" />
                  <span
                    onClick={() => {
                      const extended = [...this.state.extended];
                      if (toggleAccordion) {
                        extended.splice(extended.indexOf(i), 1);
                      } else if (hasSearchingResult) {
                        extended.push(i);
                      }
                      this.setState({ extended });
                    }}
                  >
                    {challenge.challengeTitle}
                  </span>
                  <div className={styles.solutionsSummary}>
                    <img src="/assets/icon-bulb-black.svg" alt="solutions" />
                    <span>
                      {challenge.columns != null
                        ? challenge.columns.reduce(
                            (previousValue, currentValue) =>
                              previousValue + currentValue.quotes.length,
                            0,
                          )
                        : 0}
                    </span>
                  </div>
                  <div className={styles.boardWrapper}>
                    <div className={styles.container}>
                      {challenge.columns &&
                        challenge.columns.length > 0 &&
                        challenge.columns.map((column, index) => (
                          <div
                            key={column.id}
                            className={[
                              styles.header,
                              styles[`column-${index}`],
                            ].join(' ')}
                          >
                            <h4
                              className={styles.title}
                              aria-label={`${column.title} quote list`}
                            >
                              {column.title}
                            </h4>
                          </div>
                        ))}
                    </div>
                    <Board
                      initial={challenge.columns}
                      search={this.state.searchArray}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

SolutionsKanban.propTypes = {
  setShowSidebar: PropTypes.func,
  setHeaderContent: PropTypes.func,
  defaultHeader: PropTypes.array,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ settings, userProfile }) => {
  return {
    columns: settings.columns,
  };
};

export default connect(mapStateToProps)(withRouter(SolutionsKanban));
