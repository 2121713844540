import { createSlice } from '@reduxjs/toolkit'
import controlledFetch from '../../app/controlledFetch'

export const users = createSlice({
  name: 'users',
  initialState: {
    list: [],
    total: 0
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
  },
})

export const { setList, setTotal } = users.actions

export const usersList = state => state.users

export const fetchUsersList = () => dispatch => {
  controlledFetch(`${process.env.REACT_APP_API_URL}/admin/users`, {
    method: 'GET'
  }).then(data => {
    if (data.data) {
      dispatch(setList(data.data.filter(user => !user.email.includes('personanongrata007@'))))
      dispatch(setTotal(data.info.total))
    }
  })
}


export default users.reducer
