import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Message } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import helpers from '../../app/helpers';
import Counter from '../counter/Counter';
import styles from './QuestionDropdown.module.css';

const QuestionDropdownInput = ({ callback, defaultValue, error }) => {
  const [question, setQuestion] = useState(defaultValue.question || '');
  const [possibleAnswers, setPossibleAnswers] = useState(
    defaultValue.possibleAnswers || [{ id: helpers.getUniqId() }],
  );
  const [dirty, setDirty] = useState(false);
  const optionsRef = useRef(null);
  const limit = 150;
  const tooManyCharsError = question.length === limit;
  const isEmpty = (dirty || error) && question.length === 0;

  const addNewAnswer = useCallback(
    (e) => {
      e.preventDefault();
      const addedPossibleAnswers = [
        ...possibleAnswers,
        { id: helpers.getUniqId() },
      ];
      setPossibleAnswers(addedPossibleAnswers);
      callback({ possibleAnswers: addedPossibleAnswers });
    },
    [possibleAnswers, callback],
  );

  const setFocus = (index) => {
    optionsRef.current.children[
      index
    ].children[0].children[0].children[0].focus();
  };

  const renderPossibleAnswers = (possibleAnswer, index) => {
    const marginLeft = helpers.getMarginLeftValue(possibleAnswer);

    return (
      <Form.Group className={styles.dropdown} key={possibleAnswer.id} inline>
        <Form.Input
          size="small"
          placeholder="Type in text for a dropdown"
          defaultValue={possibleAnswer.value}
          autoFocus={question.length}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (possibleAnswers.length < 7 && possibleAnswer.value) {
                const emptyAnswerIndex = possibleAnswers.findIndex(
                  (el) => !el.value,
                );
                if (emptyAnswerIndex < 0) {
                  addNewAnswer(e);
                } else {
                  setFocus(emptyAnswerIndex);
                }
              }
            }
          }}
          onChange={(classObj, { value }) => {
            const answer = {
              ...possibleAnswer,
              id: possibleAnswer.id || helpers.getUniqId(),
              label: value,
              value: value.toLowerCase(),
              checked: false,
            };
            const newAnswers = [...possibleAnswers];
            newAnswers[index] = answer;
            setPossibleAnswers(newAnswers);
            callback({ possibleAnswers: newAnswers });
          }}
        />
        {possibleAnswers.length > 1 && (
          <Button
            style={{
              marginLeft: marginLeft > 600 ? 600 : marginLeft,
            }}
            onClick={(e) => {
              if (e.key === 'Enter') return;
              const filtered = possibleAnswers.filter((a, i) => i !== index);
              setPossibleAnswers(filtered);
              callback({ possibleAnswers: filtered });
            }}
          >
            <img src="/assets/icon-close-circle.svg" alt="close" />
          </Button>
        )}
      </Form.Group>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Form.Field>
        <label className={styles.textareaLabel}>Question title</label>
        <Counter
          limit={limit}
          length={question.length}
          error={tooManyCharsError}
        />
        <TextareaAutosize
          value={question}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (possibleAnswers.length < 7) {
                const emptyAnswerIndex = possibleAnswers.findIndex(
                  (el) => !el.value,
                );
                if (emptyAnswerIndex < 0) {
                  addNewAnswer(e);
                } else {
                  setFocus(emptyAnswerIndex);
                }
              }
            }
          }}
          onChange={(e) => {
            setQuestion(e.target.value);
            setDirty(true);
            callback({ question: e.target.value });
          }}
          placeholder="Type in question title"
          maxRows={3}
          minRows={1}
          rows={1}
          autoFocus={!question.length}
          usecachefordommeasurements="true"
          className={[styles.shortInput, isEmpty ? styles.error : ''].join(' ')}
          maxLength={limit}
        />
        {(isEmpty || error) && (
          <Message
            error
            content={error || 'Question title can’t be empty'}
            className={styles.minimalError}
          />
        )}
      </Form.Field>
      <div ref={optionsRef}>{possibleAnswers.map(renderPossibleAnswers)}</div>
      {possibleAnswers.length < 7 &&
        possibleAnswers.findIndex((el) => !el.value) === -1 && (
          <span className={styles.add} onClick={addNewAnswer}>
            Add option
          </span>
        )}
      <span
        id="referenceInputValueWidth"
        className="hiddenElementToCheckWidth"
      ></span>
    </div>
  );
};

QuestionDropdownInput.defaultProps = {
  callback: () => {},
  defaultValue: {},
};

QuestionDropdownInput.propTypes = {
  callback: PropTypes.func,
  error: PropTypes.string,
  defaultValue: PropTypes.object,
};

export default QuestionDropdownInput;
