import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { Form, Image, Message } from 'semantic-ui-react';
import Counter from '../counter/Counter';
import styles from './QuestionFile.module.css';

const QuestionFileInput = ({ callback, defaultValue, error }) => {
  const limit = 150;
  const [value, setValue] = useState(defaultValue || '');
  const [dirty, setDirty] = useState(false);
  const tooManyCharsError = value.length === limit;
  const isEmpty = (dirty || error) && value.length === 0;
  return (
    <div className={styles.wrapper}>
      <Form.Field>
        <label className={styles.textareaLabel}>Question title</label>
        <Counter
          limit={limit}
          length={value.length}
          error={tooManyCharsError}
        />
        <TextareaAutosize
          value={value}
          onChange={(e) => {
            if (e.target && e.target.value.replace(/\n/g, '').length > -1) {
              const val = e.target.value.replace(/\n/g, '');
              setValue(val);
              callback(val);
              setDirty(true);
            }
          }}
          placeholder="Type in question title"
          maxRows={3}
          minRows={1}
          rows={1}
          usecachefordommeasurements="true"
          className={[styles.fileInput, isEmpty ? styles.error : ''].join(' ')}
          maxLength={limit}
        />
      </Form.Field>
      {(isEmpty || error) && (
        <Message
          error
          content={error || 'Question title can’t be empty'}
          className={styles.minimalError}
        />
      )}
      <Image src="/assets/file-upload-placeholder.jpg" />
      <span>
        Maximum file size: 10MB. You can upload maximum 2 files in .jpg .png
        format.
      </span>
    </div>
  );
};

QuestionFileInput.defaultProps = {
  callback: () => {},
  defaultValue: '',
};

QuestionFileInput.propTypes = {
  callback: PropTypes.func,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default QuestionFileInput;
