import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteItem from './QuoteItem';
import styles from './QuoteList.module.css';
import helpers from './../../app/helpers';

const InnerQuoteList = React.memo(function InnerQuoteList(props) {
  return props.quotes.map((quote, index) => {
    if (props.search) {
      if (
        !(
          quote.title &&
          helpers.includesOneOf(quote.title.toLowerCase(), props.search)
        )
      ) {
        return null;
      }
    }

    return (
      <Draggable key={quote.id} draggableId={quote.id} index={index}>
        {(dragProvided, dragSnapshot) => (
          <QuoteItem
            key={quote.id}
            quote={quote}
            isDragging={dragSnapshot.isDragging || undefined}
            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
            provided={dragProvided}
            search={props.search}
          />
        )}
      </Draggable>
    );
  });
});

function InnerList(props) {
  const { quotes, dropProvided } = props;
  const title = props.title ? <h4>{props.title}</h4> : null;

  return (
    <div>
      {title}
      <div className={styles.dropZone} ref={dropProvided.innerRef}>
        <InnerQuoteList quotes={quotes} search={props.search} />
        {dropProvided.placeholder}
      </div>
    </div>
  );
}

export default function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    listId = 'LIST',
    listType,
    style,
    quotes,
    title,
    useClone,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <QuoteItem
                quote={quotes[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging || undefined}
                isClone
              />
            )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <div
          className={styles.wrapper}
          style={style}
          isdraggingover={
            typeof dropSnapshot !== 'undefined'
              ? dropSnapshot.isDraggingOver.toString()
              : null
          }
          isdropdisabled={
            typeof isDropDisabled !== 'undefined'
              ? isDropDisabled.toString()
              : null
          }
          isdraggingfrom={
            typeof dropSnapshot !== 'undefined'
              ? Boolean(dropSnapshot.draggingFromThisWith).toString()
              : null
          }
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <div
              className={styles.scrollContainer}
              style={scrollContainerStyle}
            >
              <InnerList
                quotes={quotes}
                title={title}
                dropProvided={dropProvided}
                search={props.search}
              />
            </div>
          ) : (
            <InnerList
              quotes={quotes}
              title={title}
              dropProvided={dropProvided}
              search={props.search}
            />
          )}
        </div>
      )}
    </Droppable>
  );
}
