import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './InputTag.module.css';
import Tags from '@yaireo/tagify/dist/react.tagify';

const InputTag = ({
  placeholder,
  callback,
  pattern,
  value,
  children,
  className,
  maxTags,
  error,
}) => {
  if (error && error.content) {
    const emails = error.content.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    );
    if (emails) {
      emails.forEach((email) => {
        const invalidEmail = document.querySelector(`tag[title="${email}"]`);
        if (invalidEmail) {
          invalidEmail.classList.add('tagify--error');
        }
      });
    }
  }
  return (
    <div
      className={`${styles.inputTag} ${error ? styles.error : ''}`}
      data-message={error ? error.content : null}
    >
      <Tags
        className={`ui input large ${error ? 'error' : ''} ${className}`}
        settings={{
          pattern,
          placeholder,
          maxTags,
          duplicates: false,
          dropdown: {
            enabled: false,
          },
          editTags: false,
        }}
        onChange={callback}
        value={JSON.stringify(value.map((v) => ({ value: v })))}
      />
      {children}
    </div>
  );
};

InputTag.defaultProps = {
  className: '',
  pattern: null,
  error: null,
  maxTags: Infinity,
};

InputTag.propTypes = {
  children: PropTypes.node,
  value: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  error: PropTypes.object,
  pattern: PropTypes.instanceOf(RegExp),
};

export default InputTag;
