import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import IconUser from '../iconUser/IconUser';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate-html';

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function locations(substring, string) {
  const a = [];
  let lastIndex = 0;

  while (lastIndex < string.length) {
    let start = string
      .toLowerCase()
      .indexOf(substring.toLowerCase(), lastIndex);
    if (start !== -1) {
      lastIndex = start + substring.length;
      a.push({ start, end: lastIndex });
    } else {
      lastIndex = string.length;
    }
  }
  return a;
}

function reduceLocations(locations) {
  const a = [];
  const reducted = [];

  locations.forEach((l1, i1) => {
    if (!reducted.includes(i1)) {
      let reduce = [];

      locations.forEach((l2, i2) => {
        if (
          i1 !== i2 &&
          ((l1.start <= l2.start && l1.end >= l2.start) ||
            (l1.start <= l2.end && l1.end >= l2.end) ||
            l1.start === l2.start ||
            l1.end === l2.end)
        ) {
          reduce.push(i2);
        }
      });

      if (reduce.length) {
        reduce.forEach((index) => {
          const newLocation = {
            start:
              l1.start > locations[index].start
                ? locations[index].start
                : l1.start,
            end: l1.end > locations[index].end ? l1.end : locations[index].end,
          };
          let add = true;
          a.forEach((el) => {
            if (el.start === newLocation.start && el.end === newLocation.end) {
              add = false;
            }
          });
          if (add) {
            a.push(newLocation);
            reducted.push(index);
          }
        });
      } else {
        let add = true;
        a.forEach((el) => {
          if (el.start === l1.start && el.end === l1.end) {
            add = false;
          }
        });
        if (add) {
          a.push(l1);
        }
      }
    }
  });

  if (reducted.length) {
    return reduceLocations(a);
  } else {
    return a;
  }
}

function colorWords(text, words) {
  if (!text || !words?.length > 0) {
    return text;
  }

  let wordsLocations = [];

  let coloredText = text;
  words.forEach((word) => {
    wordsLocations = [...wordsLocations, ...locations(word, text)];
  });

  wordsLocations = reduceLocations(wordsLocations);
  wordsLocations.sort((obj1, obj2) => obj2.start - obj1.start);

  wordsLocations.forEach((location) => {
    const leftSide = coloredText.slice(0, location.start);
    const center = coloredText.slice(location.start, location.end);
    const rightSide = coloredText.slice(location.end);
    coloredText = `${leftSide}<em>${center}</em>${rightSide}`;
  });
  return coloredText;
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function QuoteItem(props) {
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    search,
  } = props;

  return (
    <Link
      to={quote.url}
      isdragging={
        typeof isDragging !== 'undefined' ? isDragging.toString() : null
      }
      isgroupedover={
        typeof isGroupedOver !== 'undefined' ? isGroupedOver.toString() : null
      }
      isclone={typeof isClone !== 'undefined' ? isClone.toString() : null}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging || false}
      data-testid={quote.id}
      data-index={index}
      aria-label={`${quote.user.firstName} ${quote.user.lastName} quote ${quote.content}`}
    >
      <Card>
        <Card.Content>
          <div>
            <Moment format="DD/MM/YYYY">{quote.createdAt}</Moment>
          </div>
          <div className="solutionTitle">
            <Truncate
              lines={4}
              dangerouslySetInnerHTML={{
                __html:
                  `${colorWords(quote.title, search)}` ||
                  `Solution #${quote.id}`,
              }}
            ></Truncate>
          </div>
        </Card.Content>
        <Card.Content extra>
          <div>
            {quote.user.avatarUrl ? (
              <Image className="smallerAvatar" src={quote.user.avatarUrl} />
            ) : (
              <IconUser
                firstName={quote.user.firstName}
                lastName={quote.user.lastName}
              />
            )}
            {/* <span className="username">
              {quote.user.firstName} {quote.user.lastName}
            </span> */}
          </div>
          <div>
            <span>
              <Image src="/assets/icon-like.svg" />
              <span>{quote.countVotes || 0}</span>
            </span>
            <span>
              <Image src="/assets/icon-comment.svg" />
              <span>{quote.countComments || 0}</span>
            </span>
          </div>
        </Card.Content>
      </Card>
    </Link>
  );
}

export default React.memo(QuoteItem);
