import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Logo.module.css';
import helpers from '../../app/helpers';

const Logo = ({ login = false, inverted = false, size, className }) => {
  const superadmin = helpers.isSuperAdmin();
  const logoUrl = useSelector(({ settings }) => settings.logoUrl);
  const companyName = useSelector(({ settings }) => settings.name);
  const style = { opacity: superadmin || logoUrl ? 1 : 0 };
  if (size) {
    style.height = `${size}px`;
    style.width = `${size}px`;
  }
  return (
    <div
      className={`${styles.logo} ${login ? styles.loginScreen : ''} ${
        inverted ? styles.inverted : ''
      } ${className}`}
    >
      <NavLink to="/challenges">
        <img
          src={superadmin ? '/assets/newlab-logo.jpg' : logoUrl}
          style={style}
          alt="logo"
        />
      </NavLink>
      {superadmin && <span>Innovation Platform SuperAdmin</span>}
      {!superadmin && companyName && (
        <span>{`${companyName} Innovation Platform`}</span>
      )}
    </div>
  );
};

Logo.propTypes = {
  login: PropTypes.bool,
  inverted: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default Logo;
