import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button as SemanticButton,
  Icon as SemanticIcon,
  Dropdown,
} from 'semantic-ui-react';
import styles from './Button.module.css';

const Button = ({
  to,
  text,
  className = '',
  onClick,
  disabled,
  icon,
  primary,
  secondary,
  inverted,
  show,
  size = 'large',
  dropdown,
  options,
  loading,
}) => {
  const ButtonWithDropdown = (
    <SemanticButton.Group
      primary={primary}
      secondary={secondary}
      inverted={inverted}
      className={`${styles.default} ${styles.buttonGroup} ${
        styles[className] ? styles[className] : ''
      }`}
      size={size}
    >
      <SemanticButton onClick={onClick} loading={loading}>
        {text}
      </SemanticButton>
      <Dropdown
        className={`button icon ${styles.dropdown}`}
        options={options}
        trigger={<React.Fragment />}
      />
    </SemanticButton.Group>
  );
  const Icon = icon ? <SemanticIcon name={icon} /> : '';
  const ButtonComponent = (
    <SemanticButton
      size={size}
      onClick={onClick}
      className={`${styles.default} ${
        styles[className] ? styles[className] : ''
      } ${show === false ? styles.hide : ''} ${className}`}
      disabled={disabled}
      primary={primary}
      secondary={secondary}
      inverted={inverted}
      loading={loading}
    >
      {Icon}
      {text}
    </SemanticButton>
  );

  return dropdown ? (
    ButtonWithDropdown
  ) : to ? (
    <Link to={to} disabled={disabled}>
      {ButtonComponent}
    </Link>
  ) : (
    ButtonComponent
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  dropdown: PropTypes.bool,
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  to: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  options: PropTypes.array,
  loading: PropTypes.bool,
};

export default Button;
