import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Segment, Dimmer, Loader, Image } from 'semantic-ui-react'
import { fetchSuperAdminsList } from './superAdminsSlice'
import Table from '../../features/table/Table'
import ContextMenu from '../../features/contextMenu/ContextMenu'
import equal from 'fast-deep-equal'

class SuperAdminsList extends Component {
  constructor(props) {
    super(props)

    props.setShowSidebar(true)
    props.setHeaderContent(props.defaultHeader)
    this.state = {
      loading: props.list.length === 0
    }

    if (props.list.length === 0) {
      props.fetchSuperAdminsList()
    }
  }

  componentDidUpdate(prevProps) {
    const state = {}
    if (!equal(this.props.list, prevProps.list)) {
      state.loading = false
    }
    if (Object.keys(state).length > 0) {
      this.setState(state)
    }
  }

  render() {
    const { list, total } = this.props
    if (!list || total === 0) {
      return <Segment>
        <Dimmer active
          inverted>
          <Loader inverted
            size="large">Loading</Loader>
        </Dimmer>
        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
      </Segment>
    }
    return (
      <div className="container">
        <Table 
          editPath="/superadmin/edit/:id"
          rows={list} 
          loading={this.state.loading} 
          superadmin={true} 
          buttonToAction="/superadmin/add" 
          buttonAddUserText="Add SuperAdmin" 
          searchInput={true}
        />
        <ContextMenu callback={this.props.fetchSuperAdminsList}
          pathPrefix="superadmin" />
      </div>
    )
  }
}

const mapStateToProps = ({ superAdmins }) => {
  return {
    list: superAdmins.list,
    total: superAdmins.total,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSuperAdminsList: () => {
      dispatch(fetchSuperAdminsList())
    },
  }
}

SuperAdminsList.propTypes = {
  list: PropTypes.array.isRequired,
  total: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  defaultHeader: PropTypes.array,
  setShowSidebar: PropTypes.func.isRequired,
  setHeaderContent: PropTypes.func.isRequired,
  fetchSuperAdminsList: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminsList)