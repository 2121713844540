import React, { Component } from 'react';
import styles from './Board.module.css';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Column from './Column';
import controlledFetch from '../../app/controlledFetch';

class Board extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: props.initial,
    };

    this.reorderQuoteMap = this.reorderQuoteMap.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list.quotes);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  reorderQuoteMap({ quoteMap, source, destination }) {
    let currentIndex, nextIndex;
    const current = quoteMap.filter((quote, i) => {
      if (String(quote.id) === source.droppableId) {
        currentIndex = i;
        return true;
      }
      return false;
    })[0];
    const next = quoteMap.filter((quote, i) => {
      if (String(quote.id) === destination.droppableId) {
        nextIndex = i;
        return true;
      }
      return false;
    })[0];
    const target = current.quotes[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
      const reordered = this.reorder(current, source.index, destination.index);
      const result = {
        ...quoteMap,
      };
      result[currentIndex].quotes = reordered;

      return result;
    }

    // moving to different list

    // remove from original
    current.quotes.splice(source.index, 1);
    // insert into next
    next.quotes.splice(destination.index, 0, target);

    const result = {
      ...quoteMap,
      [currentIndex]: current,
      [nextIndex]: next,
    };

    return result;
  }

  onDragEnd(result) {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const data = this.reorderQuoteMap({
      quoteMap: this.state.columns,
      source,
      destination,
    });

    this.setState({
      columns: Object.keys(data).map((key) => data[key]),
    });

    controlledFetch(
      `${process.env.REACT_APP_API_URL}/admin/solutions/${result.draggableId}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          column: parseInt(destination.droppableId),
        }),
      },
    );
  }

  render() {
    const { columns } = this.state;
    const { containerHeight, useClone, withScrollableColumns } = this.props;

    const board = (
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        ignoreContainerClipping={Boolean(containerHeight)}
      >
        {(provided) => (
          <div
            className={styles.container}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {columns != null ? (
              columns.map((column, index) => {
                return (
                  <Column
                    key={column.id}
                    index={index}
                    title={column.title}
                    color={column.color}
                    id={column.id}
                    quotes={column.quotes}
                    isScrollable={withScrollableColumns}
                    useClone={useClone}
                    search={this.props.search}
                    width={100 / columns.length + '%'}
                  />
                );
              })
            ) : (
              <div className={styles.noSolutions}>
                <img alt="no-solutions" src="/assets/icon-no-solutions.svg" />
                <span>There are no solutions yet</span>
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );

    return (
      <React.Fragment>
        <DragDropContext onDragEnd={this.onDragEnd}>
          {containerHeight ? (
            <div className={styles.parentContainer} height={containerHeight}>
              {board}
            </div>
          ) : (
            board
          )}
        </DragDropContext>
      </React.Fragment>
    );
  }
}

Board.defaultProps = {};

export default Board;
