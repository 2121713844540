import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import styles from './Notification.module.css';
import DateField from '../dateField/DateField';
import IconUser from '../iconUser/IconUser';
import controlledFetch from '../../app/controlledFetch';
import { Cookies } from 'react-cookie';

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newNotification: false,
      showModal: false,
      left: 100,
      notifications: [],
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');

    if (accessToken) {
      controlledFetch(`${process.env.REACT_APP_API_URL}/user/notifications`, {
        method: 'GET',
      }).then((data) => {
        this.setState({
          notifications: data,
        });
      });
    }

    this.setState({
      newNotification: this.props.hasUnreadNotifications,
    });
  }

  render() {
    const { newNotification, showModal, left, notifications } = this.state;

    return (
      <div
        className={[
          styles.newNotification,
          newNotification ? styles.new : '',
        ].join(' ')}
        onClick={(event) => {
          if (!showModal) {
            this.setState({
              showModal: !showModal,
              left: event.pageX - 200,
              newNotification: false,
            });

            controlledFetch(
              `${process.env.REACT_APP_API_URL}/user/notifications/clear`,
              {
                method: 'POST',
              },
            );
          } else {
            this.setState({
              showModal: !showModal,
              newNotification: false,
            });
          }
        }}
      >
        <img src="/assets/bell.svg" alt="notification" />
        <ReactModal
          closeTimeoutMS={200}
          className={styles.modal}
          isOpen={showModal}
          contentLabel="Notifications"
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            this.setState({ showModal: false });
          }}
          style={{
            overlay: {
              zIndex: 101,
            },
            content: {
              left,
            },
          }}
        >
          <div className={styles.modalContainer}>
            {Array.isArray(notifications) &&
              notifications.map((notification) => (
                <div key={notification.id} className={styles.notificationGroup}>
                  <div className={styles.notificationWrap}>
                    <div className={styles.notificationItem}>
                      <div className={styles.leftSide}>
                        <IconUser
                          className={styles.smallerIcon}
                          firstName={notification.user.firstName}
                          lastName={notification.user.lastName}
                          avatarUrl={notification.user.avatarUrl}
                        />
                        <h4>
                          {notification.user.firstName}{' '}
                          {notification.user.lastName}
                        </h4>
                      </div>
                      <div className={styles.datetime}>
                        <DateField datetime={notification.createdAt} />
                      </div>
                    </div>
                    <p className={styles.details}>{notification.details}</p>
                    <p className={styles.description}>
                      {notification.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasUnreadNotifications: state.userProfile.hasUnreadNotifications,
  };
};

export default connect(mapStateToProps, null)(Notification);
