import React from 'react'
import { Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom"
import helpers from '../../app/helpers'
 
const FormPopup = ({ children, size = 'tiny' }) => {
  const history = useHistory()

  return <Modal size={size}
    defaultOpen={true}
    centered={true}
    onClose={() => {
      helpers.goBack(history)
    }}>
    {children}
  </Modal>
}

FormPopup.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string
}

export default FormPopup