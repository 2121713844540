import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Message, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import helpers from '../../app/helpers';
import Counter from '../counter/Counter';
import styles from './QuestionTime.module.css';

const QuestionTimeInput = ({ callback, defaultValue, error }) => {
  const [autoFocus, setAutoFocus] = useState(0);
  const [question, setQuestion] = useState(defaultValue.question || '');
  const [possibleAnswers, setPossibleAnswers] = useState(
    defaultValue.possibleAnswers || [{ id: helpers.getUniqId() }],
  );
  const addNewAnswer = useCallback(
    (e) => {
      e.preventDefault();
      const addedPossibleAnswers = [
        ...possibleAnswers,
        { id: helpers.getUniqId() },
      ];
      setPossibleAnswers(addedPossibleAnswers);
      setAutoFocus(addedPossibleAnswers.length - 1);
      callback({ possibleAnswers: addedPossibleAnswers });
    },
    [possibleAnswers, callback],
  );
  const onChange = useCallback(
    ({ value, label }, possibleAnswer, index) => {
      const answer = {
        ...possibleAnswer,
        id: possibleAnswer.id || helpers.getUniqId(),
        checked: false,
      };
      if (value || value === '') {
        answer.value = value;
      }
      if (label || label === '') {
        answer.label = label;
      }
      const newAnswers = [...possibleAnswers];
      newAnswers[index] = answer;
      setAutoFocus(index);
      setPossibleAnswers(newAnswers);
      callback({ possibleAnswers: newAnswers });
    },
    [possibleAnswers, callback],
  );
  const limit = 150;
  const [dirty, setDirty] = useState(false);
  const tooManyCharsError = question.length === limit;
  const isEmpty = (dirty || error) && question.length === 0;

  return (
    <div className={styles.wrapper}>
      <Form.Field>
        <label className={styles.textareaLabel}>Question title</label>
        <Counter
          limit={limit}
          length={question.length}
          error={tooManyCharsError}
        />
        <TextareaAutosize
          value={question}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              addNewAnswer(e);
            }
          }}
          onChange={(e) => {
            setQuestion(e.target.value);
            setDirty(true);
            callback({ question: e.target.value });
          }}
          placeholder="Type in question title"
          maxRows={3}
          minRows={1}
          rows={1}
          usecachefordommeasurements="true"
          className={[styles.shortInput, isEmpty ? styles.error : ''].join(' ')}
          maxLength={limit}
        />
        {(isEmpty || error) && (
          <Message
            error
            content={error || 'Question title can’t be empty'}
            className={styles.minimalError}
          />
        )}
      </Form.Field>
      {possibleAnswers.map((possibleAnswer, index) => {
        const marginLeft = helpers.getMarginLeftValue(possibleAnswer);
        return (
          <Form.Group
            className={styles.dropdown}
            key={possibleAnswer.id}
            inline
          >
            <DatePicker
              selected={
                moment(possibleAnswer.value).isValid()
                  ? moment(possibleAnswer.value).toDate()
                  : moment().toDate()
              }
              customInput={
                <Input iconPosition="right">
                  <input type="button" />
                  <img alt="clock" src="/assets/icon-clock.svg" />
                </Input>
              }
              onChange={(date) => {
                onChange({ value: date }, possibleAnswer, index);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            <Form.Input
              size="small"
              placeholder="Type in text for a label (optional)"
              defaultValue={possibleAnswer.label}
              autoFocus={index === autoFocus}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addNewAnswer(e);
                }
              }}
              onChange={(e, data) => {
                onChange({ label: data.value }, possibleAnswer, index);
              }}
            />
            <Button
              style={{
                marginLeft: marginLeft > 600 ? 600 : marginLeft,
              }}
              onClick={(e) => {
                if (e.key === 'Enter') return;
                const filtered = possibleAnswers.filter((a, i) => i !== index);
                setAutoFocus(filtered.length - 1);
                setPossibleAnswers(filtered);
                callback({ possibleAnswers: filtered });
              }}
            >
              <img src="/assets/icon-close-circle.svg" alt="close" />
            </Button>
          </Form.Group>
        );
      })}
      <span className={styles.add} onClick={addNewAnswer}>
        Add option
      </span>
      <span
        id="referenceInputValueWidth"
        className="hiddenElementToCheckWidth"
      ></span>
    </div>
  );
};

QuestionTimeInput.defaultProps = {
  callback: () => {},
  defaultValue: {},
};

QuestionTimeInput.propTypes = {
  callback: PropTypes.func,
  error: PropTypes.string,
  defaultValue: PropTypes.object,
};

export default QuestionTimeInput;
