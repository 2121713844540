import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Button as SemanticButton, Segment, Dimmer, Loader, Image } from 'semantic-ui-react'
import Button from '../../features/button/Button'
import styles from './SettingsView.module.css'
import InputUpload from '../../features/inputUpload/InputUpload'
import ColorPicker from '../../features/colorPicker/ColorPicker'
import { setName, setDomains, fetchSettings, setColumns, setPrimaryColor } from './settingsSlice'
import controlledFetch from '../../app/controlledFetch'
import { toast } from 'react-toastify'
import { notificationOptions } from '../../app/config'
import helpers from '../../app/helpers'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { withRouter } from 'react-router-dom'

class SettingsView extends Component {
  constructor(props) {
    super(props)

    props.setShowSidebar(true)
    props.setHeaderContent(props.defaultHeader)
    this.state = {
      error: null,
      newDomain: '',
      disableAdd: true,
      columns: props.columns
    }

    this.onAddClick = this.onAddClick.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.resetError = this.resetError.bind(this)
    this.addNewColumn = this.addNewColumn.bind(this)
    this.reorderQuoteMap = this.reorderQuoteMap.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    props.fetchSettings()
  }

  onAddClick() {
    const { newDomain } = this.state
    const { domains, setDomains } = this.props
    const domainRegExp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
    if (newDomain.match(domainRegExp)) {
      setDomains([...domains, newDomain.replace(/@/g, '')])
      this.setState({
        newDomain: '',
        error: null,
      })
    } else {
      this.setState({error: {
        content: 'Incorrect domain format. Try google.com'
      }})
    }
  }

  submitForm() {
    const { 
      domains,
      primaryColor,
      name
    } = this.props
    controlledFetch(`${process.env.REACT_APP_API_URL}/admin/company`, {
      method: 'PUT',
      body: JSON.stringify({
        domains,
        primaryColor,
        name,
        columns: this.state.columns
      }),
    }).then(data => {
      if (data && data.content) {
        this.setState({ error: data })
      } else {
        toast.success('🙌 Well done! All changes have been successfully updated.', notificationOptions)
      }
    })
  }

  onDeleteClick(index) {
    this.props.setDomains(this.props.domains.filter((domain, i) => i !== index))
  }

  onDisableClick(index) {
    this.props.setDomains(this.props.domains.filter((domain, i) => i !== index))
  }

  renderDomainList() {
    return this.props.domains.map((domain, index) => {
      const activeUsers = Number(this.props.stats.emailDomains[domain])
      return <li key={domain}
        className={styles.domain}>
        <div>
          <span className={styles.domainName}>@{domain}</span>
          <span className={styles.activeUsers}>{activeUsers > 0 ? activeUsers : 'no'} active users</span>
        </div>
        <div>
          {activeUsers > 0 
            ? <SemanticButton size="large"
              className="nobordered"
              onClick={() => { this.onDisableClick(index) }}
            >Disable</SemanticButton>
            : <SemanticButton size="large"
              className="nobordered delete"
              onClick={() => { this.onDeleteClick(index) }}
            >Delete</SemanticButton>
          }
        </div>
      </li>
    })
  }

  addNewColumn() {
    const columns = [
      ...this.state.columns,
      {
        id: helpers.getUniqId(),
        color: '',
        title: ''
      }
    ]
    this.setState({
      columns: columns.length > 0 ? columns : [{ id: helpers.getUniqId() }]
    })
    this.props.setColumns(columns)
  }

  resetError() {
    if (this.state.error) {
      this.setState({ error: null })
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    return result;
  }

  reorderQuoteMap({
    quoteMap,
    source,
    destination,
  }) {
    return this.reorder(
      quoteMap,
      source.index,
      destination.index,
    )
  };

  onDragEnd(result) {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const columns = this.reorderQuoteMap({
      quoteMap: this.state.columns,
      source,
      destination,
    });

    this.setState({
      columns
    });
    this.props.setColumns(columns)
  }s

  render() {
    const { error, newDomain, disableAdd } = this.state
    const { name, stats, logoUrl, fetchSettings, history } = this.props
    return <div className={styles.smallContainer}>
      <Form error={!!error} className={styles.form}>
        <h2 className={styles.text}>Branding</h2>
        <p>Upload your organization’s logo with the following resolution 96 x 96 (.png .jpeg)</p>
        <Form.Group>
          <InputUpload endpoint="/admin/company/logo"
            imagePreviewUrl={logoUrl} 
            fieldName="logoFile"
            callback={fetchSettings}
          />
          <div className={styles.fields}>
            <Form.Input
              label='Company name'
              size="large"
              placeholder='Company name'
              value={name}
              error={error && error.errorFields && error.errorFields.name ? error : null}
              onChange={event => { 
                this.props.setName(event.target.value) 
                this.setState({ error: null })
              }}
            />
            <ColorPicker error={error && error.errorFields && error.errorFields.primaryColor ? error : null}
              onChange={this.resetError} 
              callback={this.props.setPrimaryColor}
              color={this.props.primaryColor}
              label='Primary color'
            />
          </div>
        </Form.Group>
        <h2 className={styles.text}>Allowed domains</h2>
        <p>Enable specific email domains associated with your organization</p>
        <Form.Group>
          <Form.Input
            placeholder='Enter domain name'
            name="domainName"
            width={14}
            size="large"
            value={newDomain.length === 0 || newDomain.indexOf('@') === 0 ? newDomain : `@${newDomain}`}
            onKeyDown={e => {e.key === 'Enter' && this.onAddClick() }}
            error={error && !error.errorFields ? error : null}
            onChange={event => { 
              let val = String(event.target.value)
              let i = 0
              val = val.trim().replace(/@/g, m  => !i++ ? m : '')
              if (this.props.domains.includes(val.substring(1))) {
                this.setState({ 
                  newDomain: val, 
                  disableAdd: true,
                  error: { content: `Domain ${val} is already added.` }
                }) 
                return
              }
              this.setState({ 
                error: null,
                disableAdd: val === '',
                newDomain: val
              }) 
            }}
          />
          <SemanticButton size="large"
            primary
            inverted
            disabled={disableAdd}
            className={styles.addButton}
            onClick={this.onAddClick}>Add</SemanticButton>
        </Form.Group>
        {stats 
        ? <ul className={styles.domains}>
          {this.renderDomainList()}
          {/* <li key="linkedin"
            className={styles.domain}>
            <div>
              <span className={styles.domainName}>LinkedIn Logins</span>
              <span className={styles.activeUsers}>{stats.socialMedia[0].linkedin} active users</span>
            </div>
          </li> */}
        </ul> 
        : <Segment>
          <Dimmer active
            inverted>
            <Loader inverted
              size="large">Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      }
        <h2 className={[styles.text, styles.columns].join(' ')}>Challenge stages</h2>
        <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="columsSettings">
        {(
          dropProvided,
          dropSnapshot,
        ) => (
        <div
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          <div ref={dropProvided.innerRef}>
            {this.state.columns.map((column, index) => <Draggable key={column.id} draggableId={String(column.id)} index={index}>
              {(
                dragProvided,
                dragSnapshot,
              ) => (
                <div
                  isDragging={dragSnapshot.isDragging || false}
                  isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                  data-is-dragging={dragSnapshot.isDragging || false}
                  data-testid={column.id}
                  data-index={index}
                  aria-label={`${column.title} ${column.id}`}
                >
                  <Form.Group className={styles.column}>
                  <span className={styles.reorder}>
                    <img src="/assets/icon-drag-and-drop.svg"
                      alt="reorder" />
                  </span>
                  <Form.Input
                    placeholder='Enter column name'
                    name="columnName"
                    width={10}
                    size="large"
                    value={column.title}
                    onKeyDown={e => {e.key === 'Enter' && this.addNewColumn() }}
                    error={error && !error.errorFields ? error : null}
                    onChange={(e, { value }) => { 
                      const columns = this.state.columns.map((col, i) => {
                        if (index === i) {
                          return {
                            ...col,
                            title: value
                          }
                        }

                        return col
                      })
                      this.setState({ 
                        columns
                      }) 
                      this.props.setColumns(columns)
                    }}
                  />
                  <ColorPicker error={error && error.errorFields && error.errorFields.columns[index] ? error : null}
                    onChange={this.resetError} 
                    callback={value => { 
                      const columns = this.state.columns.map((col, i) => {
                        if (index === i) {
                          return {
                            ...col,
                            color: value
                          }
                        }

                        return col
                      })
                      this.setState({
                        columns 
                      })
                      this.props.setColumns(columns)
                    }}
                    color={column.color}
                  />
                  {this.state.columns.length > 3 && <span className={styles.remove}
                    onClick={() => {
                      const columns = this.state.columns.filter(col => col.id !== column.id)
                      this.setState({
                        columns 
                      })
                      this.props.setColumns(columns)
                    }}>
                    <img src="/assets/icon-close-circle.svg"
                      alt="close" />
                  </span>}
                </Form.Group>
                </div>
              )}
            </Draggable>)}
            {dropProvided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
        </DragDropContext>
        {this.state.columns.length < 5 && <span className={styles.add}
          onClick={this.addNewColumn}>Add stage</span>}
      </Form>
      
      <div className={styles.buttons}>
        <Button secondary={true}
          onClick={() => { helpers.goBack(history) }}
          text="Cancel" />
        <Button text="Update"
          primary={true}
          onClick={this.submitForm}/>
      </div>
    </div>
  }
}

const mapStateToProps = ({ settings }) => {
  return {
    name: settings.name,
    domains: settings.domains,
    stats: settings.stats,
    primaryColor: settings.primaryColor,
    columns: settings.columns,
    logoUrl: settings.logoUrl
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSettings: () => {
      dispatch(fetchSettings())
    },
    setName: name => {
      dispatch(setName(name))
    },
    setDomains: domains => {
      dispatch(setDomains(domains))
    },
    setColumns: color => {
      dispatch(setColumns(color))
    },
    setPrimaryColor: primaryColor => {
      dispatch(setPrimaryColor(primaryColor))
    },
  }
}

SettingsView.defaultProps = {
  logoUrl: '',
  name: '',
  defaultHeader: [],
  stats: null,
  domains: [],
  columns: [],
  primaryColor: '',
  setHeaderContent: () => {},
  setShowSidebar: () => {},
  fetchSettings: () => {},
  setName: () => {},
  setDomains: () => {},
}

SettingsView.propTypes = {
  history: PropTypes.object,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  defaultHeader: PropTypes.array,
  stats: PropTypes.object,
  domains: PropTypes.array,
  columns: PropTypes.array,
  primaryColor: PropTypes.string,
  fetchSettings: PropTypes.func.isRequired,
  setColumns: PropTypes.func,
  setPrimaryColor: PropTypes.func,
  setHeaderContent: PropTypes.func,
  setShowSidebar: PropTypes.func,
  setName: PropTypes.func,
  setDomains: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsView))